'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const compose = require('../../hoc/compose')
const template = require('./dock.rt')
const displayNames = require('../displayNames')
const DockDirection = require('./dockDirection')

const DIRECTIONS = {
    TOP_LEFT: 'TOP_LEFT',
    TOP: 'TOP',
    TOP_RIGHT: 'TOP_RIGHT',
    LEFT: 'LEFT',
    MIDDLE: 'MIDDLE',
    RIGHT: 'RIGHT',
    BOTTOM_LEFT: 'BOTTOM_LEFT',
    BOTTOM: 'BOTTOM',
    BOTTOM_RIGHT: 'BOTTOM_RIGHT'
}
const DOCK_DIRECTIONS_PROPS = [
    {
        className: 'top left',
        value: DIRECTIONS.TOP_LEFT
    },
    {
        className: 'top',
        value: DIRECTIONS.TOP
    },
    {
        className: 'top right',
        value: DIRECTIONS.TOP_RIGHT
    },
    {
        className: 'left',
        value: DIRECTIONS.LEFT
    },
    {
        className: 'middle',
        value: DIRECTIONS.MIDDLE
    },
    {
        className: 'right',
        value: DIRECTIONS.RIGHT
    },
    {
        className: 'bottom left',
        value: DIRECTIONS.BOTTOM_LEFT
    },
    {
        className: 'bottom',
        value: DIRECTIONS.BOTTOM
    },
    {
        className: 'bottom right',
        value: DIRECTIONS.BOTTOM_RIGHT
    }
]

const generateDockDirections = props =>
    _.map(DOCK_DIRECTIONS_PROPS, (itemProps, index) =>
        React.createElement(DockDirection, {
            className: itemProps.className,
            value: itemProps.value,
            disabled: props.disabled || _.includes(props.disabledDirections, itemProps.value),
            onMouseEnter: props.onDirectionMouseEnter,
            onMouseLeave: props.onDirectionMouseLeave,
            key: `dockDirection${index}`
        }))

class Dock extends React.Component {
    constructor(props) {
        super(props)
        this.generateDockDirections = () => generateDockDirections(props)
    }

    render() {
        return template.call(this)
    }
}

Dock.displayName = displayNames.DOCK
Dock.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOf(_.keys(DIRECTIONS)),
    onDirectionMouseEnter: PropTypes.func,
    onDirectionMouseLeave: PropTypes.func,
    disabledDirections: PropTypes.arrayOf(PropTypes.oneOf(_.keys(DIRECTIONS))),
    disabled: PropTypes.bool
}

Dock.defaultProps = {
    disabled: false
}

module.exports = compose(Dock)
