'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const compose = require('../hoc/compose')
const template = require('./checkbox.rt')
const textTypes = require('./textTypes')
const displayNames = require('./displayNames')

class Checkbox extends React.Component {
    constructor(props) {
        super(props)
        this.getToggleProps = () => _.assign({}, this.props, {
            name: 'checkbox',
            className: this.props.getClassName('control-checkbox')
        })

        this.getLabelClassList = () => ['label', 'label-checkbox', props.type.toLowerCase()].join(' ')
    }

    render() {
        return template.call(this)
    }
}

Checkbox.displayName = displayNames.CHECKBOX
Checkbox.propTypes = {
    label: PropTypes.string,
    type: PropTypes.oneOf(textTypes.TYPES),
    labelAfterSymbol: PropTypes.bool
}
Checkbox.defaultProps = {
    type: textTypes.CHECKBOX_LABEL_DEFAULT_TYPE,
    labelAfterSymbol: false
}

module.exports = compose(Checkbox)
