'use strict'
const PropTypes = require('prop-types')
const compose = require('../../hoc/compose')
const template = require('./dockDirection.rt')
const displayNames = require('../displayNames')

const DockItem = template

DockItem.displayName = displayNames.DOCK_DIRECTION
DockItem.propTypes = {
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func
}

DockItem.defaultProps = {
    disabled: false
}

module.exports = compose(DockItem)
