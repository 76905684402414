'use strict'
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const template = require('./helpButton.rt')
const displayNames = require('./displayNames')

const HelpButton = template

HelpButton.displayName = displayNames.HELP_BUTTON
HelpButton.propTypes = {
    onClick: PropTypes.func
}

module.exports = compose(HelpButton)
