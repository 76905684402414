'use strict';
var React = require('react');
var _ = require('lodash');
var SortByDragListBase = require('./sortByDragListBase');
var ItemRow = require('./itemRow');
function onChange1(item, itemIndex, newVal) {
    this.changeItem(itemIndex, newVal);
}
function repeatItem2(item, itemIndex) {
    return React.createElement(ItemRow, {
        'key': 'sbdlItem-' + item.id,
        'disabled': this.props.disabled || item.disabled,
        'draggable': item.draggable && this.isListDraggable(),
        'withDragHandle': this.props.draggable,
        'isDragging': this.isDragging(itemIndex),
        'select': this.props.selectable ? this.selectItem.bind(this, itemIndex) : null,
        'isSelected': item.selected,
        'value': item,
        'labelPlaceholder': this.props.labelPlaceholder,
        'valuePlaceholder': this.props.valuePlaceholder,
        'labelValidators': this.getLabelValidators(),
        'valueValidators': this.getValueValidators(),
        'isContextMenuOpen': item.isContextMenuOpen,
        'onContextMenuToggle': item.onContextMenuToggle,
        'onChange': onChange1.bind(this, item, itemIndex),
        'onHover': this.props.onItemHover,
        'menuActions': this.getMenuActions(itemIndex),
        'editMode': this.getItemEditMode(itemIndex),
        'valuePrefix': this.props.itemValuePrefix,
        'automationId': item.automationId,
        'isDefault': item.isDefault,
        'contentBefore': item.contentBefore,
        'contentAfter': item.contentAfter,
        'removable': item.removable,
        'dragHandleEnabled': item.dragHandleEnabled,
        'contextMenuAlignment': item.contextMenuAlignment,
        'contextMenuDirection': item.contextMenuDirection,
        'dragHandleTooltipContent': item.dragHandleTooltipContent
    }, item.customActions);
}
module.exports = function () {
    return React.createElement('div', {
        'className': this.getListClasses(),
        'data-aid': this.props.automationId,
        'style': this.getStyle()
    }, React.createElement.apply(this, [
        SortByDragListBase,
        {
            'onItemMoved': this.replaceItems,
            'draggable': this.isListDraggable(),
            'beforeDragStart': this.startDragCallback,
            'afterDragEnd': this.endDragCallback,
            'dragPlaceholderCssClass': 'sbdl-drag-placeholder'
        },
        _.map(this.state.value, repeatItem2.bind(this))
    ]));
};