'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const ReactDOM = require('react-dom')
const _ = require('lodash')
const compose = require('../hoc/compose')
const template = require('./tooltipOnEllipsis.rt')
const displayNames = require('./displayNames')

const RELEVANT_STYLE_PROPERTIES = ['border', 'borderRightWidth', 'borderLeftWidth', 'borderRightStyle', 'borderLeftStyle', 'boxSizing', 'font', 'fontSize', 'fontFamily', 'fontStyle', 'fontWeight', 'fontVariant', 'fontKerning', 'fontFeatureSettings', 'fontVariantAlternates', 'fontVariantCaps', 'fontVariantEastAsian', 'fontVariantLigatures', 'fontVariantNumeric', 'fontVariantPosition', 'fontSizeAdjust', 'fontStretch', 'letterSpacing', 'padding', 'paddingRight', 'paddingLeft', 'textIndent', 'textTransform', 'transform', 'wordSpacing']

const MEASURE_STYLE = {
    visibility: 'hidden',
    height: 0,
    position: 'absolute',
    top: 0,
    whiteSpace: 'nowrap'
}

const isTextOverflown = (element, measureElement) => {
    const measureElementWidth = measureElement.getBoundingClientRect().width
    const elmWidth = element === measureElement || window.getComputedStyle(element).display === 'inline' ?
        element.parentElement.getBoundingClientRect().width :
        element.getBoundingClientRect().width

    return Number(measureElementWidth.toFixed(2)) > Number(elmWidth.toFixed(2))
}

const isEqual = (curStyle, newStyle) => _.every(RELEVANT_STYLE_PROPERTIES, property => curStyle[property] === newStyle[property])

const hasTextLabel = props => _.includes([displayNames.TEXT_LABEL, displayNames.TEXT_LABEL_BASE], _.invoke(props, 'children.type.getDisplayName'))

const getLabelContent = props =>
    _.get(props, 'children.props.shouldTranslate') ?
        props.translateIfNeeded(_.get(props, 'children.props.value')) :
        _.get(props, 'children.props.value')

const isAncestor = (child, parent) => {
    let curNode = child
    while (curNode.parentElement !== null) {
        if (curNode.parentElement === parent) {
            return true
        }
        curNode = curNode.parentElement
    }
    return false
}

const DEFAULT_STATE = {
    displayTooltip: false,
    style: MEASURE_STYLE
}

class TooltipOnEllipsis extends React.Component {
    constructor(props) {
        super(props)

        this.state = DEFAULT_STATE

        this.getContentContainerRef = ref => {
            this.contentContainerRef = ref
        }

        this.getMeasureContainerRef = ref => {
            this.measureContainerRef = ref
        }

        this.getContent = () => hasTextLabel(this.props) ?
            getLabelContent(this.props) :
            _.get(this, 'props.children.props', this.props).children

        this.getTooltipMessage = () => this.props.customTooltipMessage || this.getContent()

        this.getMeasureNode = () => this.measureContainerRef.firstElementChild || this.measureContainerRef

        this.isDisabled = () => this.props.disabled || !this.state.displayTooltip

        this.getClassName = () => this.props.getClassName('tooltip-on-ellipsis-content', 'contentClassName')

        this.onMouseEnter = () => {
            const newStyle = this.getStyle()
            if (isEqual(this.state.style, newStyle)) {
                this.calcEllipsis()
            } else {
                this.setState({style: newStyle}, this.calcEllipsis)
            }
        }

        this.calcEllipsis = () => {
            const displayTooltip = isTextOverflown(this.getTextNode(), this.getMeasureNode())
            if (this.state.displayTooltip !== displayTooltip) {
                this.setState({displayTooltip})
            }
        }

        this.getTextNode = () => _.get(this, 'contentContainerRef.firstElementChild', this.contentContainerRef)
        this.getStyle = () => {
            const textChild = this.getTextNode()
            if (!textChild) {
                return MEASURE_STYLE
            }

            return _(textChild)
                .thru(window.getComputedStyle)
                .pick(RELEVANT_STYLE_PROPERTIES)
                .pickBy(_.negate(_.isUndefined))
                .assign({}, MEASURE_STYLE)
                .value()

        }

    }

    componentDidMount() {
        if (this.props.customTrigger) {
            const thisNode = ReactDOM.findDOMNode(this)
            const customTriggerNode = ReactDOM.findDOMNode(this.props.customTrigger)

            if (!isAncestor(thisNode, customTriggerNode)) {
                throw new Error(`this.props.customTrigger (${customTriggerNode}) is not an ancestor of this (tooltipOnEllipsis)`)
            }
            customTriggerNode.addEventListener('mouseenter', this.onMouseEnter)
        }
    }

    componentWillUnmount() {
        if (this.props.customTrigger) {
            const customTriggerNode = ReactDOM.findDOMNode(this.props.customTrigger)
            customTriggerNode.removeEventListener('mouseenter', this.onMouseEnter)
        }

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.children !== this.props.children) {
            this.setState(DEFAULT_STATE)
        }
    }

    render() {
        return template.call(this)

    }
}
TooltipOnEllipsis.displayName = displayNames.TOOLTIP_ON_ELLIPSIS
TooltipOnEllipsis.propTypes = {
    alignment: PropTypes.string,
    animationDuration: PropTypes.number,
    arrowDistance: PropTypes.number,
    closeOnMouseClick: PropTypes.bool,
    closeOnMouseLeave: PropTypes.bool,
    contentClassName: PropTypes.string,
    customTrigger: PropTypes.object, // React ref
    customTooltipMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.element
    ]),
    direction: PropTypes.string,
    disabled: PropTypes.bool,
    displayCustomTrigger: PropTypes.bool,
    isOpen: PropTypes.bool,
    marginBottom: PropTypes.number,
    marginLeft: PropTypes.number,
    marginRight: PropTypes.number,
    marginTop: PropTypes.number,
    maxWidth: PropTypes.number,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    onOuterClick: PropTypes.func,
    onScroll: PropTypes.func,
    openOnMouseEnter: PropTypes.bool,
    zIndex: PropTypes.number
}
module.exports = compose(TooltipOnEllipsis)


