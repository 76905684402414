
'use strict'

const React = require('react')
const _ = require('lodash')

const shallowEqual = (a, b) => {
    if (a === b) {
        return true
    }

    if (!_.isObject(a) || a === null ||
        !_.isObject(b) || b === null) {
        return false
    }

    const keysA = Object.keys(a)
    const keysB = Object.keys(b)

    if (keysA.length !== keysB.length) {
        return false
    }

    const bHasOwnProperty = Object.prototype.hasOwnProperty.bind(b)
    for (let i = 0; i < keysA.length; i++) {
        if (!bHasOwnProperty(keysA[i]) || a[keysA[i]] !== b[keysA[i]]) {
            return false
        }
    }
    return true
}


const withPureRender = Component => {
    class PureRender extends React.Component {
        shouldComponentUpdate(nextProps) {
            return !shallowEqual(this.props, nextProps)
        }
        render() {
            return React.createElement(Component, this.props)
        }
    }
    return PureRender
}


module.exports = withPureRender
