'use strict'
const React = require('react')
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const template = require('./horizontalTabs.rt')
const displayNames = require('./displayNames')

class HorizontalTabs extends React.Component {
    constructor(props) {
        super(props)
        this.getRootCssClasses = () => {
            const classList = ['control-horizontal-tabs']

            if (this.props.arrowedBox) {
                classList.push('arrowed-box')
            } else {
                classList.push(this.props.arrowed ? 'arrowed' : 'tabbed')
            }

            return classList.join(' ')
        }
    }

    render() {
        return template.call(this)
    }
}

HorizontalTabs.displayName = displayNames.HORIZONTAL_TABS
HorizontalTabs.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    })),
    arrowed: PropTypes.bool,
    arrowedBox: PropTypes.bool
}

HorizontalTabs.defaultProps = {
    arrowed: false,
    arrowedBox: false
}

module.exports = compose(HorizontalTabs)
