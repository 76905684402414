'use strict';
var React = require('react');
var _ = require('lodash');
var emptyStateSymbol = require('./icons/empty_image_medium.svg.js');
module.exports = function () {
    return React.createElement('div', {
        'className': this.getClassName(),
        'data-aid': this.props.automationId,
        'onClick': this.props.onClick,
        'style': this.getStyle()
    }, !this.props.src && !this.props.children ? React.createElement(emptyStateSymbol, { 'key': 'emptyStateSymbol' }) : null, !this.props.src && this.props.children ? React.createElement('div', {
        'className': 'children-container',
        'key': 'children-container'
    }, this.props.children) : null);
};