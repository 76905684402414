'use strict';
var React = require('react');
var _ = require('lodash');
var TextLabel = require('./textLabel');
module.exports = function (props, context) {
    return React.createElement('div', {
        'className': props.getClassName('control-google-preview'),
        'data-aid': props.automationId
    }, React.createElement('div', { 'className': 'preview-content' }, React.createElement('div', { 'className': 'title' }, React.createElement(TextLabel, {
        'value': props.title,
        'shouldTranslate': props.shouldTranslate
    })), React.createElement('div', { 'className': 'url' }, React.createElement(TextLabel, {
        'value': props.url,
        'shouldTranslate': props.shouldTranslate
    })), React.createElement('div', { 'className': 'description' }, React.createElement(TextLabel, {
        'value': props.description,
        'shouldTranslate': props.shouldTranslate,
        'enableEllipsis': false
    }))));
};