'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const compose = require('../hoc/compose')
const displayNames = require('./displayNames')
const template = require('./contextMenuAction.rt')

class ContextMenuAction extends React.Component {
    render() {
        return template.call(this)
    }
}

ContextMenuAction.displayName = displayNames.CONTEXT_MENU_ACTION

ContextMenuAction.propTypes = {
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    active: PropTypes.bool,
    disabled: PropTypes.bool
}

module.exports = compose(ContextMenuAction)
