'use strict';
var React = require('react');
var _ = require('lodash');
module.exports = function () {
    return React.createElement('div', {
        'data-aid': this.props.automationId,
        'className': this.props.getClassName('control-dropdown-base') + (this.props.disabled ? ' disabled' : ''),
        'data-label': this.props.dataLabel
    }, React.createElement('div', {
        'className': [
            'dropdown-selected',
            this.isOpen() ? 'is-open' : ''
        ].join(' '),
        'ref': 'selected',
        'onClick': this.toggleOptions.bind(this, undefined)
    }, this.props.selected));
};