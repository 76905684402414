'use strict';
var React = require('react');
var _ = require('lodash');
var Toggle = require('./toggle');
var Tooltip = require('./tooltip');
var Symbol = require('./symbol');
module.exports = function (props, context) {
    return React.createElement(Tooltip, {
        'disabled': !props.tooltipContent,
        'content': props.tooltipContent,
        'shouldTranslate': props.shouldTranslate
    }, React.createElement(Toggle, {
        'className': props.getClassName(_({
            'text-formatting-toggle-button': true,
            disabled: props.disabled,
            selected: props.value
        }).pickBy().keys().join(' ')),
        'name': props.name,
        'value': props.value,
        'onChange': props.onChange,
        'disabled': props.disabled
    }, React.createElement(Symbol, {}, props.children)));
};