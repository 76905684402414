'use strict'
const _ = require('lodash')
const PropTypes = require('prop-types')
const React = require('react')
const dropDownHelpers = require('../util/dropDownHelpers')
const compose = require('../hoc/compose')
const template = require('./dynamicField.rt')
const displayNames = require('./displayNames')

const dynamicPartRegex = (prefix, suffix) => {
    const escapedPrefix = _.escapeRegExp(prefix)
    const escapedSuffix = _.escapeRegExp(suffix)
    return new RegExp(`${escapedPrefix}((?:(?!(?:${escapedPrefix}|${escapedSuffix})).)*)${escapedSuffix}`, 'g') // anything between prefix and suffix that does not contain them
}

class DynamicField extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showOverlay: true,
            valueCandidate: this.props.value
        }
        this.getRootCssClasses = () => [
            'dynamic-field-container',
            this.props.horizontalView ? 'horizontal' : 'vertical',
            !this.props.prefix && !this.props.horizontalView ? 'un-prefixed' : '',
            this.props.disabled ? 'disabled' : ''
        ].join(' ')

        this.onInputBlur = () => this.setState({showOverlay: true}, this.props.onBlur || _.noop)
        this.onInputFocus = () => this.setState({showOverlay: false})
        this.getOverlay = inputValue => {
            const decoratedValue = inputValue
                .split(dynamicPartRegex(props.dynamicPartPrefix, props.dynamicPartSuffix))
                .map((v, i) => {
                    const isDynamicPart = i % 2 === 1
                    const isValueDynamicOption = _.find(props.options, option => option === v || option.value === v)
                    const valueToDisplayInOverlay = isDynamicPart && !isValueDynamicOption ? props.dynamicPartPrefix + v + props.dynamicPartSuffix : v
                    const partProps = isDynamicPart && isValueDynamicOption ? {
                        key: `text${i}`,
                        className: 'dynamic-text',
                        'data-prefix': props.dynamicPartPrefix,
                        'data-suffix': props.dynamicPartSuffix
                    } : {
                        key: `text${i}`,
                        className: 'static-text'
                    }
                    return React.createElement('pre', partProps, valueToDisplayInOverlay)
                })

            const className = ['overlay', this.state.showOverlay ? '' : 'hidden'].join(' ')
            return React.createElement('div', {className}, decoratedValue)
        }

        this.onValueStateChange = val => {
            this.setState({valueCandidate: val})
        }

        this.onValidationStatus = isValid => {
            if (isValid) {
                this.props.onChange(this.state.valueCandidate)
            }
        }

        this.onChange = this.props.validator || this.props.asyncValidator ? this.onValueStateChange : this.props.onChange
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.state.valueCandidate && nextProps.value !== this.props.value) {
            this.setState({valueCandidate: nextProps.value})
        }
    }

    render() {
        return template.call(this)
    }
}

// TODO: share with withValidation
const validatorType = PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.func),
    PropTypes.func
])

DynamicField.displayName = displayNames.DYNAMIC_FIELD
DynamicField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onFieldAdd: PropTypes.func.isRequired,
    dynamicPartPrefix: PropTypes.string,
    dynamicPartSuffix: PropTypes.string,
    addDynamicPartButtonLabel: PropTypes.string.isRequired,
    options: dropDownHelpers.PropTypes.options,
    onBlur: PropTypes.func,
    onToggle: PropTypes.func,
    horizontalView: PropTypes.bool,
    prefix: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    isValid: PropTypes.bool,
    invalidMessage: PropTypes.string,
    validator: validatorType,
    asyncValidator: validatorType
}

DynamicField.defaultProps = {
    prefix: '',
    placeholder: '',
    horizontalView: false,
    disabled: false,
    dynamicPartPrefix: '{',
    dynamicPartSuffix: '}'
}

module.exports = compose(DynamicField)
