'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const displayNames = require('../controls/displayNames')

const wrapOption = (compClass, extraProps, opt) => {

    const isValidElement = React.isValidElement(opt)
    const shouldNotWrap = isValidElement && _.isUndefined(opt.props.value)
    const isStickyFooter = isValidElement && _.get(opt, 'type.getDisplayName', _.noop)() === displayNames.DROP_DOWN_STICKY_FOOTER

    if (isStickyFooter) {
        return React.cloneElement(opt, _.defaults({
            registerStickyFooter: extraProps.registerStickyFooter,
            toggleOptions: extraProps.toggleOptions,
            key: 'stickyFooter'
        }, opt.props))
    }

    if (shouldNotWrap) {
        return opt
    }

    const isStringValue = _.isString(opt)
    const {val, children, disabled, automationId} = isValidElement ? {
        val: opt.props.value,
        disabled: opt.props.disabled,
        children: opt,
        automationId: opt.props.automationId || null
    } : {
        val: isStringValue ? opt : opt.value,
        children: isStringValue ? opt : opt.label || opt.val,
        disabled: isStringValue ? false : opt.disabled,
        automationId: isStringValue ? null : opt.automationId || null
    }

    const props = _.assign({}, {
        disabled,
        value: val,
        key: val,
        automationId
    }, _.omit(extraProps, ['registerStickyFooter', 'toggleOptions']))

    return React.createElement(compClass, props, children)
}

const optionsType = {
    options: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.element.isRequired,
        PropTypes.object.isRequired
    ])).isRequired
}

const validateOptionsOrChildren = (props, propName, componentName, locationName) => {
    const {options, children} = props
    if (!(!options ^ !children)) {
        return new Error(componentName + ' must have either options or children but not both.')
    }
    if (props[propName]) {
        PropTypes.checkPropTypes(optionsType, props, locationName, componentName)
    }
    if (options && !options.length || children && !_.castArray(children).length) {
        return new Error(
            'Invalid prop `options` or `children` with length `0`' +
            ' supplied to `' + componentName + '`.' +
            ' expected `' + componentName + '` to have at least 1 option or children.'
        )
    }
}

module.exports = {
    wrapOption,
    PropTypes: {
        options: validateOptionsOrChildren
    }
}
