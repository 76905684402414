'use strict'
const _ = require('lodash')
const React = require('react')
const ReactDOM = require('react-dom')

const listeners = {}
let initialized = false

const onClickOutside = e => {
    _.forEach(listeners, listener => {
        if (!listener) {
            return
        }
        const isClickInside = _.isElement(e.target) && listener.node.contains(e.target)
        if (!isClickInside) {
            listener.method(e)
        }
    })
}


const withClickOutside = Component => {

    class ClickOutside extends React.Component {
        constructor(props) {
            super(props)
            this.callbacks = []
            this.registerOnClickOutside = (method, node) => {
                const id = _.uniqueId()
                this.callbacks.push(_.assign({method, id}, node ? {node} : {}))
                return id
            }

            this.clearOnClickOutside = id => {
                this.callbacks = _.reject(this.callbacks, {id})
                delete listeners[id]
            }

            this.createListeners = () => {
                const rootNode = ReactDOM.findDOMNode(this)
                _.forEach(this.callbacks, callback => {
                    listeners[callback.id] = _.assign({node: rootNode}, callback)
                })
            }

            this.initialize = () => {
                if (!initialized) {
                    window.addEventListener('click', onClickOutside, true)
                    initialized = true
                }
            }
        }

        componentDidMount() {
            this.initialize()
            this.createListeners()
        }

        componentWillUnmount() {
            _.forEach(this.callbacks, callback => {
                delete listeners[callback.id]
            })
        }

        render() {
            const props = _.assign({}, this.props, {
                onClickOutside: this.registerOnClickOutside,
                clearOnClickOutside: this.clearOnClickOutside
            })
            return React.createElement(Component, props)
        }
    }
    return ClickOutside
}

module.exports = withClickOutside
