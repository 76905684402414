'use strict';

var React = require('react');
module.exports = function () {
  return React.createElement(
    'svg',
    { width: '24', height: '24', viewBox: '0 0 24 24', xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement(
      'title',
      null,
      'date_picker_icon_white'
    ),
    React.createElement('path', { d: 'M14 12h2v-2h-2v2zm-3 0h2v-2h-2v2zm7 4a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h2v1h1V8h4v1h1V8h2a1 1 0 0 1 1 1v7zm-3-9V6h-1v1h-4V6H9v1H7a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-2zm-1 8h2v-2h-2v2zm-6 0h2v-2H8v2zm0-3h2v-2H8v2zm3 3h2v-2h-2v2z', fill: '#FFF', fillRule: 'evenodd' })
  );
};