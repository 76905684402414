'use strict'
const _ = require('lodash')
const compose = require('../hoc/compose')
const displayNames = require('./displayNames')
const React = require('react')
const template = require('./buttonsGroup.rt')
const radioButtons = require('./radioButtons')


class ButtonsGroup extends React.Component {
    constructor(props) {
        super(props)
        this.pickProps = () => _.pick(this.props, ['value', 'onChange', 'options', 'disabled', 'shouldTranslate'])
    }
    render() {
        return template.call(this)
    }
}

ButtonsGroup.displayName = displayNames.BUTTONS_GROUP


ButtonsGroup.propTypes = _.pick(radioButtons, ['value', 'onChange', 'options', 'disabled'])

ButtonsGroup.defaultProps = {
    disabled: false
}

module.exports = compose(ButtonsGroup)
