'use strict';
var React = require('react');
var _ = require('lodash');
function onDragStart1(Transition, e) {
    e.preventDefault();
}
function repeatItem2(Transition, item, itemIndex) {
    return React.createElement('div', {
        'key': item.key,
        'onMouseDown': this.onMouseDown.bind(this, itemIndex)
    }, '\n                ', item, '\n            ');
}
function repeatItem3(Transition, item, itemIndex) {
    return React.createElement('div', {
        'key': item.key,
        'onMouseDown': this.onMouseDown.bind(this, itemIndex)
    }, '\n            ', item, '\n        ');
}
function scopeTransition4() {
    var Transition = this.getTransitionClass();
    return React.createElement('div', {
        'className': this.props.className,
        'data-aid': this.props.automationId,
        'onDragStart': onDragStart1.bind(this, Transition)
    }, React.createElement.apply(this, [
        'div',
        { 'style': { position: 'relative' } },
        this.props.childrenTransition ? React.createElement.apply(this, [
            Transition,
            _.assign({}, { 'key': 'da-transition' }, this.props.transitionProps),
            _.map(this.getItems(), repeatItem2.bind(this, Transition))
        ]) : null,
        !this.props.childrenTransition ? _.map(this.getItems(), repeatItem3.bind(this, Transition)) : null
    ]));
}
module.exports = function () {
    return scopeTransition4.apply(this, []);
};