'use strict'
const _ = require('lodash')
const {validationType} = require('./childValidation')

const buildValidationError = (compositeName, childrenTypes) => {
    if (childrenTypes.validation === validationType.ANY) {
        return
    }
    const types = _.map(childrenTypes, c => `${c.names.join('/')} (${c.validation})`)

    return new Error(`${compositeName} should have children of the following types in this order: ${types.join(', ')}.`)
}

module.exports = {
    buildValidationError
}
