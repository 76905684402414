'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const compose = require('../hoc/compose')
const template = require('./editableURL.rt')
const displayNames = require('./displayNames')
const KEYS = require('../constants/keyCodes')

const isButton = target => {
    if (target) {
        const predicate = className => _.includes(['editable-url-btn-cancel', 'editable-url-btn-apply'], className)
        return _.some(target.classList, predicate)
    }
}

class EditableURL extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isValid: true,
            isFocused: false,
            valueCandidate: props.value,
            isReadOnly: props.editButtons && !props.autoSelect || props.isReadOnly
        }

        this.setStateWithReadOnly = (nextIsReadOnly, nextState = {}, callback = _.noop) => {
            const useNextIsReadOnly = this.props.editButtons
            const func = ({isReadOnly, isFocused}) => {
                const isReadOnlyState = {
                    isReadOnly: useNextIsReadOnly ? nextIsReadOnly : isReadOnly,
                    isFocused: nextIsReadOnly === true ? false : isFocused
                }
                return _.assign(isReadOnlyState, nextState)
            }
            this.setState(func, callback)
        }
        this.onChange = () => { this.props.onChange(this.state.valueCandidate) }

        this.getClassNames = () => {
            const defaultName = 'control-editable-url'
            const focus = this.state.isFocused ? ' focus' : ''
            const editButtons = this.props.editButtons ? ' with-edit-buttons' : ''
            return this.props.getClassName(defaultName + focus + editButtons)
        }
        this.handleEdit = () => { this.setStateWithReadOnly(false) }
        this.handleCandidateChange = valueCandidate => {
            const callback = _.partial(this.props.onValueCandidateChange, valueCandidate)
            this.setState({valueCandidate}, callback)
        }
        this.handleValidationStatusChange = isValid => {
            const callback = _.partial(this.props.onValidationStatus, isValid)
            this.setState({isValid}, callback)
        }
        this.handleBlur = ({relatedTarget}) => {
            const validationOK = this.state.isValid || this.props.allowInvalidChange
            const alreadyHandled = this.alreadyHandled || isButton(relatedTarget)
            this.alreadyHandled = false
            this.props.onBlur()

            if (!validationOK || alreadyHandled) {
                return
            }

            if (this.props.confirmOnBlur) {
                this.handleConfirm()
            } else {
                this.setStateWithReadOnly(true)
            }
        }
        this.handleFocus = () => { this.setState({isFocused: !this.state.isReadOnly}) }
        this.handleCancel = blurInput => {
            const callback = () => {
                this.onChange()
                if (_.isFunction(blurInput)) {
                    this.alreadyHandled = true
                    blurInput()
                }
            }

            const newState = {valueCandidate: this.props.value, isValid: true}
            this.setStateWithReadOnly(true, newState, callback)
        }
        this.handleConfirm = blurInput => {
            const validationOK = this.state.isValid || this.props.allowInvalidChange
            if (!validationOK) {
                return
            }

            const callback = () => {
                this.onChange()
                if (_.isFunction(blurInput)) {
                    this.alreadyHandled = true
                    blurInput()
                }
            }
            this.setStateWithReadOnly(true, {}, callback)
        }
        this.handleKeyDown = ({keyCode}, blur) => {
            if (keyCode === KEYS.ENTER) {
                this.handleConfirm(blur)
            } else if (keyCode === KEYS.ESC) {
                this.handleCancel(blur)
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({valueCandidate: nextProps.value})
        }
    }
    render() {
        return template.call(this)
    }
}

const validatorType = PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.func),
    PropTypes.func
])

EditableURL.displayName = displayNames.EDITABLE_URL
EditableURL.propTypes = {
    domain: PropTypes.string.isRequired,
    isReadOnly: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onValueCandidateChange: PropTypes.func,
    editButtons: PropTypes.bool,
    editButtonText: (props, propName, componentName) => {
        if (!props[propName] && props.editButtons) {
            return new Error(`Required prop '${propName}' was not specified in '${componentName}'.`)
        }
    },
    autoSelect: PropTypes.bool,
    confirmOnBlur: PropTypes.bool,
    placeholder: PropTypes.string,
    validator: validatorType,
    invalidMessage: PropTypes.string,
    onValidationStatus: PropTypes.func,
    allowInvalidChange: PropTypes.bool,
    syncDelay: PropTypes.number,
    isValid: PropTypes.bool
}
EditableURL.defaultProps = {
    isReadOnly: false,
    value: '',
    onChange: _.noop,
    onBlur: _.noop,
    onValueCandidateChange: _.noop,
    editButtons: false,
    autoSelect: true,
    confirmOnBlur: true,
    onValidationStatus: _.noop,
    placeholder: ''
}

module.exports = compose(EditableURL)
