'use strict'
const _ = require('lodash')
const PropTypes = require('prop-types')
const React = require('react')
const compose = require('../hoc/compose')
const template = require('./sectionDivider.rt')
const displayNames = require('./displayNames')

const applyTextLabelDefaultProps = child => _.invoke(child, 'type.getDisplayName') === 'TextLabel' ? React.cloneElement(child, {
    ellipsisProps: _.assign({marginTop: -6}, child.props.ellipsisProps)
}) : child

class sectionDivider extends React.Component {
    constructor(props) {
        super(props)

        this.getChildren = () => React.Children.map(this.props.children, applyTextLabelDefaultProps)
    }

    render() {
        return template.call(this)
    }
}

sectionDivider.displayName = displayNames.SECTION_DIVIDER
sectionDivider.propTypes = {
    disabled: PropTypes.bool
}

sectionDivider.defaultProps = {
    disabled: false
}

module.exports = compose(sectionDivider)
