'use strict'
const _ = require('lodash')
const PropTypes = require('prop-types')
const React = require('react')
const template = require('./myColors.rt')
const compose = require('../../hoc/compose')
const colorUtil = require('../../util/color')
const displayNames = require('../displayNames')

const getDistanceToWhite = hexColor => _(hexColor)
    .thru(colorUtil.hexToHsb)
    .thru(colorUtil.getDistanceToWhite)
    .value()

class MyColors extends React.Component {
    constructor(props) {
        super(props)

        this.state = this.props.selectedIndex ? {} : {
            selectedIndex: _.indexOf(this.props.colors, this.props.value)
        }

        this.getColorIndex = () => _.isNumber(this.props.selectedIndex) ?
            this.props.selectedIndex : this.state.selectedIndex

        this.getOptionClassNames = (color, colorIndex) => ({
            light: getDistanceToWhite(color) < 3,
            'color-picker-my-colors-option': true,
            'selected-color': this.getColorIndex() === colorIndex
        })
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.selectedIndex) {
            this.setState({
                selectedIndex: _.indexOf(nextProps.colors, nextProps.value)
            })
        }
    }

    render() {
        return template.call(this)
    }
}

MyColors.displayName = displayNames.MY_COLORS
MyColors.propTypes = {
    value: PropTypes.string,
    selectedIndex: PropTypes.number,
    colors: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    onColorEnter: PropTypes.func,
    onColorLeave: PropTypes.func,
    addColor: PropTypes.func
}
MyColors.defaultProps = {
    onColorEnter: _.noop,
    onColorLeave: _.noop
}

module.exports = compose(MyColors)
