'use strict';
var React = require('react');
var _ = require('lodash');
var toggle = require('./toggle');
var symbol = require('./checkboxSymbol.svg.js');
module.exports = function () {
    return React.createElement(toggle, _.assign({}, { 'data-aid': this.props.automationId }, this.getToggleProps()), this.props.label && !this.props.labelAfterSymbol ? React.createElement('span', {
        'className': 'label label-checkbox',
        'key': 'label'
    }, this.props.translateIfNeeded(this.props.label)) : null, React.createElement('span', { 'className': 'symbol symbol-checkbox' }, React.createElement(symbol, {})), this.props.label && this.props.labelAfterSymbol ? React.createElement('span', {
        'className': this.getLabelClassList(),
        'key': 'label'
    }, this.props.translateIfNeeded(this.props.label)) : null, this.props.children);
};