'use strict'
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const displayNames = require('./displayNames')
const template = require('./addThumbnailButton.rt')
const textTypes = require('./textTypes')
const DEFAULT_SYMBOL_SIZE = 27

const AddThumbnailButton = template

AddThumbnailButton.displayName = displayNames.ADD_THUMBNAIL_BUTTON
AddThumbnailButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string,
    labelType: PropTypes.string,
    style: PropTypes.object,
    symbolSize: PropTypes.number,
    isFirst: PropTypes.bool
}

AddThumbnailButton.defaultProps = {
    labelType: textTypes.THUMBNAIL_LABEL_DEFAULT_TYPE,
    symbolSize: DEFAULT_SYMBOL_SIZE
}

module.exports = compose(AddThumbnailButton)


