'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const template = require('./textEdit.rt')
const displayNames = require('../displayNames')
const compose = require('../../hoc/compose')
const withClickOutside = require('../../hoc/withClickOutside')
const KEYS = require('../../constants/keyCodes')

// todo - kobiz - inputText: missing validations - https://jira.wixpress.com/browse/SE-17883

const validate = (validators, value) => {
    const invalid = _.find(validators, ({validator}) => !validator(value))

    return invalid && invalid.invalidMessage
}

class TextEdit extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value: props.value,
            invalidMessage: validate(props.validators, props.value)
        }

        this.onInputTextChange = newVal => {
            this.setState({value: newVal, invalidMessage: validate(props.validators, newVal)})
        }

        this.isInvalid = () => !_.isUndefined(this.state.invalidMessage)

        this.handleOnChange = () => {
            if (!this.isInvalid()) {
                this.applyChanges()
            }
        }

        this.handleKeyDown = e => {
            if (_.includes([KEYS.ENTER, KEYS.ESC], e.keyCode)) {
                e.stopPropagation()
                e.preventDefault()

                if (e.keyCode === KEYS.ESC) {
                    this.props.cancel()
                } else {
                    this.handleOnChange()
                }
            }
        }

        this.applyChanges = () => this.props.onChange(this.state.value)
    }

    componentWillMount() {
        this.props.onClickOutside(this.handleOnChange)
    }

    componentDidMount() {
        if (this.props.keyboardShortcuts) {
            window.addEventListener('keydown', this.handleKeyDown, true)
        }
    }

    componentWillUnmount() {
        if (this.props.keyboardShortcuts) {
            window.removeEventListener('keydown', this.handleKeyDown, true)
        }
    }

    render() {
        return template.call(this)
    }
}

TextEdit.displayName = displayNames.SORT_BY_DRAG_LIST_TEXT_EDIT
TextEdit.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    maxLength: PropTypes.number,
    validators: PropTypes.arrayOf(PropTypes.shape({
        validator: PropTypes.func.isRequired,
        invalidMessage: PropTypes.string.isRequired
    })),
    placeholder: PropTypes.string,
    shouldTranslateInvalidMessage: PropTypes.bool,
    autoFocus: PropTypes.bool,
    keyboardShortcuts: PropTypes.bool
}

TextEdit.defaultProps = {
    autoFocus: false,
    shouldTranslateInvalidMessage: true,
    keyboardShortcuts: false
}

module.exports = compose(TextEdit, [withClickOutside])

