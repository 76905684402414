'use strict';

var React = require('react');
module.exports = function () {
  return React.createElement(
    'svg',
    { width: '22', height: '18', viewBox: '0 0 22 18' },
    React.createElement(
      'g',
      { fill: 'none', fillRule: 'evenodd' },
      React.createElement('path', { d: 'M7.862 12.981l-1.646 1.67c-.799.81-2.1.81-2.899 0-.8-.81-.8-2.13-.001-2.94l3.317-3.363c.8-.81 2.101-.81 2.9 0a.85.85 0 0 0 1.216 0 .879.879 0 0 0-.001-1.231 3.737 3.737 0 0 0-5.329 0l-3.318 3.362a3.864 3.864 0 0 0 .001 5.404 3.734 3.734 0 0 0 5.329 0l1.646-1.67a.879.879 0 0 0 0-1.232.851.851 0 0 0-1.215 0z', fill: '#2B5672' }),
      React.createElement('path', { d: 'M15.897 2.085c-1.423-1.447-3.908-1.447-5.328 0L8.923 3.76a.884.884 0 0 0 0 1.235.846.846 0 0 0 1.213 0l1.647-1.673c.774-.789 2.123-.789 2.9 0 .386.393.6.917.6 1.473 0 .557-.214 1.081-.601 1.476l-3.316 3.373c-.8.814-2.101.813-2.9 0a.847.847 0 0 0-1.215 0 .885.885 0 0 0 0 1.237c.735.746 1.7 1.119 2.665 1.119.966 0 1.93-.373 2.665-1.12l3.315-3.374A3.84 3.84 0 0 0 17 4.796a3.842 3.842 0 0 0-1.103-2.71z', fill: '#2B5672' }),
      React.createElement('path', { d: 'M19.265 14l1.473-1.473a.895.895 0 0 0-1.265-1.264L18 12.736l-1.473-1.474a.893.893 0 1 0-1.265 1.265l1.473 1.474-1.473 1.472a.895.895 0 0 0 1.265 1.264L18 15.266l1.473 1.473a.89.89 0 0 0 1.265 0 .895.895 0 0 0 0-1.265L19.265 14z', fill: '#EE5951' })
    )
  );
};