
'use strict'

const _ = require('lodash')
const withPureRender = require('./withPureRender')
const withTranslation = require('./withTranslation')
const withUtils = require('./withUtils')
// const withProfiling = require('./withProfiling')

//TODO: remove eslint ignore when fixed
/* eslint lodash/prefer-times:0 */
module.exports = (Component, extraWrappers = []) => {
    const composed = _.flowRight(
        // withProfiling,
        withPureRender,
        withTranslation,
        withUtils,
        ...extraWrappers
    )(Component)
    composed._rawClass = Component
    composed.getDisplayName = () => Component.displayName
    return composed
}
