'use strict';
var React = require('react');
var _ = require('lodash');
var Button = require('../button');
var UploadStatusMessage = require('./uploadStatusMessage');
module.exports = function () {
    return React.createElement('div', {
        'className': this.props.getClassName('control-upload-button') + ' ' + (this.isStatusNone() ? 'no-bottom-message' : ''),
        'data-aid': this.props.automationId
    }, React.createElement(Button, {
        'className': 'btn-confirm-secondary',
        'onClick': this.isDisabled() ? null : this.props.onClick,
        'disabled': this.isDisabled()
    }, '\n        ', this.getButtonContent(), '\n    '), React.createElement(UploadStatusMessage, {
        'value': this.props.value,
        'status': this.props.status,
        'processMessage': this.props.processMessage,
        'errorMessage': this.props.errorMessage
    }));
};