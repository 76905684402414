'use strict'
const React = require('react')
const PropTypes = require('prop-types')
const template = require('./tag.rt')

class Tag extends React.Component {
    constructor(props) {
        super(props)
        this.onRemove = this.onRemove.bind(this)
    }

    onRemove() {
        if (this.props.onRemove) {
            this.props.onRemove(this.props.value.key)
        }
    }

    render() {
        return template.call(this)
    }
}

Tag.displayName = 'Tag'
Tag.propTypes = {
    value: PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string
    }).isRequired,
    onRemove: PropTypes.func
}

module.exports = Tag
