'use strict'
const _ = require('lodash')
const PropTypes = require('prop-types')
const React = require('react')

const getValueLinkProps = props => _.has(props, 'valueLink') ? {
    value: props.valueLink.value,
    onChange: props.valueLink.requestChange
} : {}

const createWithUtils = innerComp => props => { //eslint-disable-line react/display-name
    const getClassName = (defaultClassName, propName = 'className') => [defaultClassName, props[propName]].join(' ')
    const valueLinkProps = getValueLinkProps(props)
    const newProps = _({}).assign(props, {getClassName}, valueLinkProps).omit(['valueLink']).value()

    return React.createElement(innerComp, newProps)
}

createWithUtils.propTypes = {
    automationId: PropTypes.string
}

createWithUtils.defaultProps = {
    automationId: null
}

module.exports = createWithUtils
