'use strict'
const React = require('react')
const compose = require('../hoc/compose')

const Divider = props => {
    const newProps = {
        className: props.getClassName(props.long ? 'divider-long' : 'divider-short'),
        'data-aid': props.automationId}
    return React.createElement('hr', newProps)

}

module.exports = compose(Divider)
