'use strict'
const _ = require('lodash')
const PropTypes = require('prop-types')
const React = require('react')
const compose = require('../hoc/compose')
const template = require('./textLabel.rt')
const displayNames = require('./displayNames')
const textTypes = require('./textTypes')
const tooltipOnEllipsis = require('./tooltipOnEllipsis')

const ellipsisPropsNames = ['closeOnMouseClick', 'contentClassName', 'customTrigger', 'customTooltipMessage', 'displayCustomTrigger', 'marginTop', 'marginLeft']

class TextLabel extends React.Component {
    constructor(props) {
        super(props)
        this.getTextLabelBaseProps = () => _.pick(this.props, ['value', 'type', 'disabled', 'shouldTranslate'])
        this.getTooltipOnEllipsisProps = () => this.props.ellipsisProps ? _.pick(this.props.ellipsisProps, _.concat(ellipsisPropsNames, 'disabled', 'shouldTranslate')) : {}
    }

    render() {
        return template.call(this)
    }

}

TextLabel.displayName = displayNames.TEXT_LABEL
TextLabel.propTypes = {
    value: PropTypes.string.isRequired,
    type: PropTypes.oneOf(textTypes.TYPES),
    disabled: PropTypes.bool,
    enableEllipsis: PropTypes.bool,
    ellipsisProps: PropTypes.shape(_.pick(tooltipOnEllipsis, ellipsisPropsNames))

}

TextLabel.defaultProps = {
    disabled: false,
    enableEllipsis: true
}

module.exports = compose(TextLabel)
