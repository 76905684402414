'use strict';
var React = require('react');
var _ = require('lodash');
var dropDownBase = require('./dropDownBase');
module.exports = function () {
    return React.createElement(dropDownBase, {
        'automationId': this.props.automationId,
        'className': this.props.getClassName('control-context-menu'),
        'disabled': this.props.disabled,
        'blockScroll': this.props.blockScroll,
        'allowInnerScroll': true,
        'displayArrow': false,
        'selected': this.getButton(),
        'optionsStyle': this.state.optionsStyle,
        'optionsInnerStyle': this.state.optionsInnerStyle,
        'onLayoutChange': this.onOptionsLayoutChange,
        'onKeyDown': this.onKeyDown,
        'optionsContainerClassName': this.optionsContainerClassName(),
        'customCloseKeys': this.getCustomCloseKeys(),
        'customToggle': this.toggleOptions,
        'isOpen': this.isOpen(),
        'keepOptionsWidth': true,
        'blockClickOutside': this.props.blockClickOutside,
        'options': this.traceClicksIfNeeded(this.props.children)
    });
};