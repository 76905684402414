'use strict';
var React = require('react');
var _ = require('lodash');
var PlusSymbol = require('./plus.svg.js');
function onClick1(color, colorIndex) {
    {
        this.props.onChange(color, colorIndex);
    }
}
function onMouseEnter2(color, colorIndex) {
    this.props.onColorEnter(color, colorIndex);
}
function onMouseLeave3(color, colorIndex) {
    this.props.onColorLeave(color, colorIndex);
}
function repeatColor4(color, colorIndex) {
    return React.createElement('div', {
        'className': _(this.getOptionClassNames(color, colorIndex)).transform(function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' '),
        'onClick': onClick1.bind(this, color, colorIndex),
        'onMouseEnter': onMouseEnter2.bind(this, color, colorIndex),
        'onMouseLeave': onMouseLeave3.bind(this, color, colorIndex),
        'key': colorIndex
    }, React.createElement('div', {
        'className': 'color-picker-my-colors-option-inner',
        'style': { backgroundColor: color }
    }));
}
module.exports = function () {
    return React.createElement.apply(this, [
        'div',
        { 'className': 'color-picker-my-colors' },
        this.props.addColor ? React.createElement('div', {
            'key': 'addColor',
            'onClick': this.props.addColor,
            'className': 'color-picker-my-colors-option color-picker-my-colors-add'
        }, React.createElement('div', { 'className': 'color-picker-my-colors-add-inner' }, React.createElement(PlusSymbol, {}))) : null,
        _.map(this.props.colors, repeatColor4.bind(this))
    ]);
};