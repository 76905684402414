'use strict';

var React = require('react');
module.exports = function () {
  return React.createElement(
    'svg',
    { xmlns: 'http://www.w3.org/2000/svg', width: '60', height: '40', viewBox: '0 0 60 40' },
    React.createElement(
      'g',
      { fill: 'none', fillRule: 'evenodd' },
      React.createElement('path', { fill: '#A3E1FE', d: 'M48 13v17c0 2.21-1.79 4-4 4H16c-2.21 0-4-1.79-4-4V13c0-2.21 1.79-4 4-4h7l1.45-2.89A2 2 0 0 1 26.24 5h7.52a2 2 0 0 1 1.79 1.11L37 9h7c2.21 0 4 1.79 4 4' }),
      React.createElement('path', { fill: '#72D1FD', d: 'M12 27h36V15H12zM37 9H23l1.45-2.89A2 2 0 0 1 26.24 5h7.52a2 2 0 0 1 1.79 1.11L37 9z' }),
      React.createElement('path', { fill: '#D2F0FF', d: 'M39 21a9 9 0 0 1-9 9 9 9 0 0 1-9-9 9 9 0 0 1 9-9 9 9 0 0 1 9 9' }),
      React.createElement('path', { fill: '#A3E1FE', d: 'M36 21a6 6 0 0 1-12 0 6 6 0 0 1 12 0' })
    )
  );
};