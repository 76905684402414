'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const compose = require('../hoc/compose')
const textLabelBase = require('./textLabelBase')
const template = require('./bubble.rt')
const displayNames = require('./displayNames')

class Bubble extends React.Component {
    constructor(props) {
        super(props)

        this.getContentRef = ref => {this.content = ref}
        this.getContentStyle = () =>
            this.props.maxWidth ? _.assign({maxWidth: this.props.maxWidth}, this.props.contentStyle) :
                this.props.contentStyle

        this.getChildren = () => {
            if (_.isString(this.props.children)) {
                return React.createElement(textLabelBase, {
                    shouldTranslate: props.shouldTranslate,
                    value: this.props.children
                })
            }
            return this.props.children
        }
    }

    componentDidMount() {
        if (_.isFunction(this.props.onLayoutChange)) {
            this.props.onLayoutChange(this.content)
        }
    }

    componentDidUpdate(prevProps) {
        const didTargetContentChanged = !_.isEqual(this.props.targetContent, prevProps.targetContent)
        const shouldReLayout = didTargetContentChanged || !_.isEqual(this.props.contentStyle, prevProps.contentStyle)
        if (_.isFunction(this.props.onLayoutChange) && shouldReLayout) {
            this.props.onLayoutChange(this.content)
        }
    }

    render() {
        return template.call(this)
    }
}

Bubble.displayName = displayNames.BUBBLE

Bubble.propTypes = {
    onLayoutChange: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    arrowAlignment: PropTypes.string,
    arrowStyle: PropTypes.object,
    contentStyle: PropTypes.object,
    targetContent: PropTypes.object,
    maxWidth: PropTypes.number
}

Bubble.defaultProps = {
    maxWidth: 240
}

module.exports = compose(Bubble)
