'use strict';
var React = require('react');
var _ = require('lodash');
var Scroll = require('react-custom-scroll');
var PalettePicker = require('./palettePicker');
var TextLabel = require('../textLabel');
module.exports = function (props, context) {
    return React.createElement('div', { 'className': props.getClassName('color-picker-palette-picker-dialog') }, React.createElement(TextLabel, {
        'value': props.textKeys.colorPalettes || 'Color Palettes',
        'shouldTranslate': props.shouldTranslate,
        'className': 'palette-picker-dialog-header'
    }), React.createElement(Scroll, {}, React.createElement('div', { 'className': 'palette-picker-dialog-palettes' }, React.createElement(PalettePicker, {
        'value': props.value,
        'onChange': props.onChange,
        'palettePresets': props.palettePresets
    }))));
};