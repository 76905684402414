'use strict';
var React = require('react');
var _ = require('lodash');
var Scroll = require('react-custom-scroll');
var ColorSpace = require('./colorSpace');
var ResetColor = require('./resetColor');
var ColorFormat = require('./colorFormat');
var MyColors = require('./myColors');
var TextLabel = require('../textLabel');
var Divider = require('../divider');
var Button = require('../button');
var Composites = require('../../composites/composites');
function onColorLeave1(props) {
    (props.onPreview || _.noop)(null);
}
module.exports = function (props, context) {
    return React.createElement('div', { 'className': props.getClassName('color-picker-choose-color-dialog') }, React.createElement(ColorSpace, {
        'value': props.value,
        'onChange': props.onChange
    }), React.createElement(ResetColor, {
        'value': props.value,
        'onChange': props.onChange,
        'className': 'choose-color-dialog-reset'
    }), React.createElement(ColorFormat, {
        'value': props.value,
        'onChange': props.onChange
    }), props.myColors ? React.createElement(Divider, {
        'long': true,
        'key': 'divider'
    }) : null, props.myColors ? React.createElement(TextLabel, {
        'className': 'choose-color-dialog-my-colors-label',
        'key': 'myColorsLabel',
        'shouldTranslate': props.shouldTranslate,
        'value': props.textKeys.myColors || 'My Colors'
    }) : null, props.myColors ? React.createElement('div', {
        'className': 'choose-color-dialog-my-colors',
        'key': 'myColors'
    }, React.createElement(Scroll, {}, React.createElement('div', { 'className': 'choose-color-dialog-my-colors-content' }, React.createElement(MyColors, {
        'value': props.value,
        'colors': props.myColors,
        'onChange': props.onChange,
        'onColorEnter': props.onPreview,
        'onColorLeave': onColorLeave1.bind(this),
        'selectedIndex': props.colorSelectedIndex
    })))) : null, React.createElement(Composites.ActionSetHorizontal, { 'className': 'small flex choose-color-dialog-footer' }, React.createElement(Button, {
        'className': 'btn-sm btn-confirm-secondary',
        'onClick': props.onCancel
    }, props.textKeys.cancel && props.translateIfNeeded(props.textKeys.cancel) || 'Cancel'), React.createElement(Button, {
        'className': 'btn-sm btn-confirm-primary',
        'onClick': props.onDone
    }, props.textKeys.cancel && props.translateIfNeeded(props.textKeys.done) || 'Done')));
};