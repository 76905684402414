'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const compose = require('../hoc/compose')
const displayNames = require('./displayNames')

class DropDownStickyFooter extends React.Component {
    componentDidMount() {
        if (_.isFunction(this.props.registerStickyFooter)) {
            this.props.registerStickyFooter(this)
        }
    }

    componentWillUnmount() {
        if (_.isFunction(this.props.registerStickyFooter)) {
            this.props.registerStickyFooter(null)
        }
    }

    render() {
        return React.createElement('footer', {
            onClick: () => {
                if (this.props.closeOnClick && _.isFunction(this.props.toggleOptions)) {
                    this.props.toggleOptions()
                }
            },
            className: this.props.getClassName('control-dropdown-sticky-footer'),
            'data-aid': this.props.automationId
        }, this.props.children)
    }
}

DropDownStickyFooter.displayName = displayNames.DROP_DOWN_STICKY_FOOTER
DropDownStickyFooter.propTypes = {
    registerStickyFooter: PropTypes.func,
    closeOnClick: PropTypes.bool
}
DropDownStickyFooter.defaultProps = {}

module.exports = compose(DropDownStickyFooter)
