'use strict'
const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS'
const UPLOAD_FAILED = 'UPLOAD_FAILED'
const IN_PROCESS = 'IN_PROCESS'
const NONE = 'NONE'
const UPLOAD_STATUS = [NONE, UPLOAD_SUCCESS, UPLOAD_FAILED, IN_PROCESS]


module.exports = {
    UPLOAD_SUCCESS,
    UPLOAD_FAILED,
    IN_PROCESS,
    NONE,
    UPLOAD_STATUS
}
