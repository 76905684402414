'use strict'
// /* eslint-disable */
const React = require('react')
module.exports = function () {
    return React.createElement(
        'svg',
        {width: '50px', height: '28px', viewBox: '0 0 50 28', version: '1.1', xmlns: 'http://www.w3.org/2000/svg'},
        React.createElement(
            'defs',
            null,
            React.createElement(
                'filter',
                {
                    x: '-50%',
                    y: '-50%',
                    width: '200%',
                    height: '200%',
                    filterUnits: 'objectBoundingBox',
                    id: 'thumb-shadow'
                },
                React.createElement('feOffset', {dx: '0', dy: '2', in: 'SourceAlpha', result: 'shadowOffset'}),
                React.createElement('feGaussianBlur', {stdDeviation: '1', in: 'shadowOffset', result: 'shadowBlur'}),
                React.createElement('feColorMatrix', {
                    values: '0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.30 0',
                    in: 'shadowBlur',
                    type: 'matrix',
                    result: 'shadowOpacity'
                }),
                React.createElement(
                    'feMerge',
                    null,
                    React.createElement('feMergeNode', {in: 'shadowOpacity'}),
                    React.createElement('feMergeNode', {in: 'SourceGraphic'})
                )
            )
        ),
        React.createElement('path', {
            className: 'switch-thumb-bg',
            d: 'M14,24.5 C7.659,24.5 2.5,19.341 2.5,13 C2.5,6.659 7.659,1.5 14,1.5 L35,1.5 C41.341,1.5 46.5,6.659 46.5,13 C46.5,19.341 41.341,24.5 35,24.5 L14,24.5'
        }),
        React.createElement('path', {
            className: 'switch-thumb-border',
            d: 'M35,2 C41.065,2 46,6.935 46,13 C46,19.065 41.065,24 35,24 L14,24 C7.935,24 3,19.065 3,13 C3,6.935 7.935,2 14,2 L35,2 M35,1 L14,1 C7.373,1 2,6.373 2,13 C2,19.627 7.373,25 14,25 L35,25 C41.627,25 47,19.627 47,13 C47,6.373 41.627,1 35,1'
        }),
        React.createElement(
            'g',
            {className: 'switch-thumb-regular'},
            React.createElement('path', {
                className: 'switch-thumb-circle',
                d: 'M25,13.5 C25,19.299 20.075,24 14,24 C7.925,24 3,19.299 3,13.5 L3,12.5 C3,6.701 7.925,2 14,2 C20.075,2 25,6.701 25,12.5 L25,13.5'
            }),
            React.createElement('path', {
                className: 'switch-thumb-check',
                d: 'M10.0081965,13.3440654 C10.0391965,13.5490654 10.1531965,13.7440654 10.3421965,13.8780654 L13.1231965,15.8390654 C13.4991965,16.1050654 14.0301965,16.0340654 14.3141965,15.6810654 L17.8261965,11.2960654 C18.1111965,10.9410654 18.0381965,10.4340654 17.6621965,10.1650654 C17.2871965,9.89406543 16.7511965,9.96406543 16.4671965,10.3200654 L13.4671965,14.0640654 L11.3641965,12.5840654 C10.9871965,12.3170654 10.4521965,12.3900654 10.1701965,12.7470654 C10.0301965,12.9250654 9.97819652,13.1400654 10.0081965,13.3440654'
            }),
            React.createElement('path', {
                className: 'switch-thumb-minus',
                d: 'M19,13 C19,13.552 18.503,14 17.889,14 L10.111,14 C9.498,14 9,13.552 9,13 C9,12.448 9.498,12 10.111,12 L17.889,12 C18.503,12 19,12.448 19,13'
            })
        ),
        React.createElement(
            'g',
            {className: 'switch-thumb-selected'},
            React.createElement('path', {
                className: 'switch-thumb-circle',
                d: 'M46 13.5C46 19.299 41.075 24 35 24s-11-4.701-11-10.5v-1C24 6.701 28.925 2 35 2s11 4.701 11 10.5v1'
            }),
            React.createElement('path', {
                className: 'switch-thumb-check',
                d: 'M31.008 13.344c.031.205.145.4.334.534l2.781 1.961a.886.886 0 0 0 1.191-.158l3.512-4.385a.778.778 0 0 0-.164-1.13.883.883 0 0 0-1.195.154l-3 3.744-2.103-1.48a.885.885 0 0 0-1.194.163.778.778 0 0 0-.162.597'
            }),
            React.createElement('path', {
                className: 'switch-thumb-minus',
                d: 'M40 13c0 .552-.497 1-1.111 1h-7.778C30.498 14 30 13.552 30 13s.498-1 1.111-1h7.778c.614 0 1.111.448 1.111 1'
            })
        )
    )
}

