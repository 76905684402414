'use strict'
module.exports = {
    BACKSPACE: 8,
    DOWN: 40,
    ENTER: 13,
    ESC: 27,
    LEFT: 37,
    RIGHT: 39,
    SPACE: 32,
    UP: 38,
    Z: 90
}
