'use strict'

const React = require('react')

const compose = require('../hoc/compose')
const displayNames = require('./displayNames')

const Preloader = props => React.createElement('div', {className: props.getClassName('control-preloader')})

Preloader.displayName = displayNames.PRELOADER

module.exports = compose(Preloader)
