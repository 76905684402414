'use strict'
const validationType = {
    ONCE: 'Once',
    MULTIPLE: 'Multiple',
    OPTIONAL: 'Optional',
    ANY: 'Any'
}

const childType = {
    once: (...names) => ({validation: validationType.ONCE, names}),
    optional: (...names) => ({validation: validationType.OPTIONAL, names}),
    multiple: (...names) => ({validation: validationType.MULTIPLE, names}),
    any: () => ({validation: validationType.ANY})
}

module.exports = {
    childType,
    validationType
}
