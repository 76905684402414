'use strict'
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const displayNames = require('./displayNames')
const template = require('./textFormattingToggleButton.rt')

const textFormattingToggleButton = template

textFormattingToggleButton.displayName = displayNames.TEXT_FORMATTING_TOGGLE_BUTTON
textFormattingToggleButton.propTypes = {
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    tooltipContent: PropTypes.string
}
textFormattingToggleButton.defaultProps = {
    disabled: false
}

module.exports = compose(textFormattingToggleButton)
