'use strict';
var React = require('react');
var _ = require('lodash');
var NumericInput = require('./numericInput');
function onDragStart1(e) {
    e.preventDefault();
}
module.exports = function () {
    return React.createElement('div', {
        'data-aid': this.props.automationId,
        'className': this.props.getClassName('control-slider-input') + (this.props.disabled ? ' disabled' : '')
    }, React.createElement('div', {
        'className': 'slider' + ' ' + _({ 'with-numeric-input': !this.props.hideNumericInput }).transform(function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' '),
        'onDragStart': onDragStart1.bind(this)
    }, React.createElement('span', {
        'className': 'track',
        'onMouseDown': this.onSlideStart,
        'ref': function (element) {
            this.track = element;
        }.bind(this)
    }, React.createElement('span', {
        'className': 'colored-part',
        'style': this.getColoredPartStyle.call(this)
    })), React.createElement('div', { 'className': 'knob-container' }, React.createElement('span', {
        'className': 'knob',
        'onMouseDown': this.onSlideStart,
        'style': { left: this.getFilledPartInPercent() + '%' }
    }))), !this.props.hideNumericInput ? React.createElement(NumericInput, _.assign({}, {
        'unit': this.props.unit,
        'key': 'numeric'
    }, this.props)) : null);
};