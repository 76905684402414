'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const compose = require('../../hoc/compose')
const Slider = require('./../slider')
const displayNames = require('./../displayNames')

const OpacityPicker = props => React.createElement(Slider,
    _.defaults({
        className: props.getClassName('opacity-slider'),
        unit: 'percent'
    }, props))

OpacityPicker.displayName = displayNames.OPACITY_PICKER
OpacityPicker.propTypes = {
    value: PropTypes.number,
    opacityGradientColor: PropTypes.string,
    onChange: PropTypes.func,
    onSlideStart: PropTypes.func,
    onSlideEnd: PropTypes.func,
    disabled: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number
}
OpacityPicker.defaultProps = {
    value: 100,
    opacityGradientColor: 'white',
    step: 1,
    min: 0,
    max: 100
}

module.exports = compose(OpacityPicker)
