'use strict'
const PropTypes = require('prop-types')
const compose = require('../../hoc/compose')
const displayNames = require('../displayNames')

const CustomItemRow = {}

CustomItemRow.displayName = displayNames.SORT_BY_DRAG_LIST_CUSTOM_ITEM_ROW
CustomItemRow.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    contentBefore: PropTypes.element,
    contentAfter: PropTypes.element,
    disabled: PropTypes.bool,
    removable: PropTypes.bool,
    draggable: PropTypes.bool,
    dragHandleEnabled: PropTypes.bool,
    dragHandleTooltipContent: PropTypes.string,
    isSelected: PropTypes.bool,
    isContextMenuOpen: PropTypes.bool,
    onContextMenuToggle: PropTypes.func,
    contextMenuAlignment: PropTypes.string,
    contextMenuDirection: PropTypes.string
}

CustomItemRow.defaultProps = {
    disabled: false
}

module.exports = compose(CustomItemRow)
