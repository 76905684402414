'use strict'
const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const template = require('./multiLevelCheckbox.rt')
const compose = require('../../hoc/compose')
const withClickOutside = require('../../hoc/withClickOutside')

const expandedByDefault = false

const getAllItemsObject = (allItems, allItemChecked) => ({label: allItems, value: allItems, section: true, items: [
    {label: allItems, value: allItems, items: [], checked: allItemChecked}
]})

const areAllItemsSelected = item => item.checked && _.every(item.items, areAllItemsSelected)
const areAllSectionsSelected = sections => _.every(sections, section => areAllItemsSelected(section))

const getItemCheckState = item => {
    const firstState = _.get(item, 'items[0].checked')

    const indeterminate = {
        indeterminate: true,
        checked: false
    }
    const initial = {
        checked: firstState
    }

    if (item.items.length === 0) {
        return item.checked
    }

    return _.every(item.items, {checked: firstState}) ? initial : indeterminate
}

const getMatchingItems = (item, filterText) => {
    const matchingSubItems = _.flatMap(item.items, subItem => getMatchingItems(subItem, filterText))

    const isLabelMatches = _.includes(_.toUpper(item.label), _.toUpper(filterText))
    if (matchingSubItems.length === 0 && !isLabelMatches) {
        return []
    }

    return _.assign({}, item, {items: matchingSubItems}, getItemCheckState(item))
}

const extractItems = item => item.checked === true && areAllItemsSelected(item) ?
    {key: item.value, label: item.label} : _.flatMap(item.items, subItem => extractItems(subItem))


const extractItemsFromSection = section => {
    if (section.items) {
        return _.flatMap(section.items, item => extractItems(item))
    }
}

const getItemWithUpdatedCheckedState = (subItem, targetKey, value, allItems) => {
    const itemFound = subItem.value === targetKey || allItems === targetKey

    return _.assign({}, subItem, {
        items: subItem.items.map(item => getItemWithUpdatedCheckedState(item, itemFound ? item.value : targetKey, value, allItems)),
        checked: itemFound ? value : subItem.checked
    })
}

class MultiLevelCheckbox extends React.Component {
    constructor(props) {
        super(props)

        this.onFilterChanged = this.onFilterChanged.bind(this)
        this.showTreeView = this.showTreeView.bind(this)
        this.onItemCheckedStateChanged = this.onItemCheckedStateChanged.bind(this)
        this.isExpanded = this.isExpanded.bind(this)
        this.expandClicked = this.expandClicked.bind(this)
        this.removeLastTag = this.removeLastTag.bind(this)
        this.state = {treeViewOpen: false, filterText: '', isExpandedMapById: {}}
    }

    componentDidMount() {
        this.clickOutsideHandlerId = this.props.onClickOutside(() => this.showTreeView(false))
    }

    render() {
        return template.call(this)
    }

    removeLastTag() {
        const lastItem = _.last(this.getTagList())
        if (!_.isUndefined(lastItem)) {
            this.onItemCheckedStateChanged(lastItem.key, false)
        }
    }

    onFilterChanged(filter) {
        this.setState({filterText: filter})
    }

    showTreeView(show) {
        this.setState({treeViewOpen: show})
    }

    onItemCheckedStateChanged(key, value) {
        this.props.onChange(_.map(this.props.value, section => getItemWithUpdatedCheckedState(section, key, value, this.props.allItems)))
    }

    isExpanded(value) {
        return _.isUndefined(this.state.isExpandedMapById[value]) ?
            expandedByDefault :
            this.state.isExpandedMapById[value]
    }

    expandClicked(value) {
        const {isExpandedMapById} = this.state
        this.setState({isExpandedMapById: _.assign({}, isExpandedMapById, {
            [value]: _.isUndefined(isExpandedMapById[value]) ? !expandedByDefault : !isExpandedMapById[value]
        })})
    }

    getTagList() {
        const isCheckedItemsOtherThanAll = this.props.allItems && areAllSectionsSelected(this.props.value)
        return isCheckedItemsOtherThanAll ?
            [{key: this.props.allItems, label: this.props.allItems}] :
            _.flatMap(this.props.value, section => extractItemsFromSection(section))
    }

    getItemList() {
        const {filterText} = this.state

        const matchingItems = _.flatMap(this.props.value, section => getMatchingItems(section, filterText))

        const shouldAddAllItems = this.props.allItems && _.includes(_.toUpper(this.props.allItems), _.toUpper(filterText))
        if (shouldAddAllItems) {
            const areAllItemsInSectionSelected = section => _.every(section.items, areAllItemsSelected)

            const allItemChecked = _.every(matchingItems, areAllItemsInSectionSelected)

            return _.concat(getAllItemsObject(this.props.allItems, allItemChecked), matchingItems)
        }
        return matchingItems
    }

    areAllFiltered(itemList) {
        return _(itemList).map('items').every(_.isEmpty)
    }
}

MultiLevelCheckbox.displayName = 'MultiLevelCheckbox'
MultiLevelCheckbox.propTypes = {
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    allItems: PropTypes.string,
    maxHeight: PropTypes.number,
    noItemsFoundText: PropTypes.string
}

module.exports = compose(MultiLevelCheckbox, [withClickOutside])
