'use strict';
var React = require('react');
var _ = require('lodash');
var DropDownBase = require('./dropDownBase');
module.exports = function () {
    return React.createElement(DropDownBase, {
        'ref': 'dropDownBase',
        'automationId': this.props.automationId,
        'className': this.props.getClassName('control-dropdown' + (this.props.hiddenScroll && !this.props.searchBox ? ' hidden-scroll' : '')),
        'disabled': this.props.disabled,
        'blockScroll': true,
        'onToggle': this.props.onToggle,
        'selected': this.getSelectedElements(),
        'optionsStyle': this.state.optionsStyle,
        'optionsInnerStyle': this.state.optionsInnerStyle,
        'onLayoutChange': this.onOptionsLayoutChange,
        'onKeyDown': this.onKeyDown,
        'onOptionsScroll': this.onOptionsScroll,
        'customBlockScroll': this.customBlockScroll,
        'customToggle': this.toggleOptions,
        'optionsContainerClassName': this.getOptionsContainerClassName(),
        'inputToAllowEvents': this.state.inputToAllowEvents,
        'customCloseKeys': this.getCloseKeys(),
        'displayArrow': !this.props.searchBox,
        'isOpen': this.state.isOpen,
        'keepOptionsWidth': true,
        'options': this.getOptionsElements(),
        'dataLabel': this.props.dataLabel,
        'forceOpen': this.props.forceOpen
    });
};