'use strict'
const _ = require('lodash')
const PropTypes = require('prop-types')
const React = require('react')
const compose = require('../hoc/compose')
const dateUtil = require('../util/date')
const template = require('./datePicker.rt')
const displayNames = require('./displayNames')

const NUMBER_OF_WEEKS_FOR_FIXED_HEIGHT = 6

class DatePicker extends React.Component {

    constructor(props) {
        super(props)
        this.state = {value: dateUtil.isValid(props.value) ? props.value : new Date()}

        this.getDatesInMonth = () => {
            const days = []
            const firstDayIndex = this.props.startDay ? this.props.days.indexOf(this.props.startDay) : 0
            let day = new Date(this.state.value.getFullYear(), this.state.value.getMonth(), 1)

            while (day.getDay() !== firstDayIndex) {
                day = new Date(day.getFullYear(), day.getMonth(), day.getDate() - 1)
                days.unshift(day)
            }

            day = new Date(this.state.value.getFullYear(), this.state.value.getMonth(), 1)
            while (day.getMonth() === this.state.value.getMonth()) {
                days.push(day)
                day = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1)
            }

            while (day.getDay() !== firstDayIndex) {
                days.push(day)
                day = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1)
            }

            if (this.props.fixedNumberOfRows) {
                const daysLeft = NUMBER_OF_WEEKS_FOR_FIXED_HEIGHT * 7 - days.length
                _.times(daysLeft, function () {
                    days.push(day)
                    day = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1)
                })
            }

            return days
        }

        this.getDays = () => {
            const result = this.props.days
            const startDay = this.props.startDay ? this.props.startDay : result[0]
            while (result[0] !== startDay) {
                result.push(result.shift())
            }

            return result
        }

        this.getElementClasses = date => {
            const classes = [
                'dp-day',
                !date || date.getMonth() !== this.state.value.getMonth() && 'dp-day-not-in-current-month',
                this.isEquals(date, this.props.value) && 'dp-day-selected',
                this.isEquals(date, new Date()) && 'dp-today'
            ]

            return _(classes).compact().join(' ')
        }

        this.getMonthTitle = () => {
            const monthName = this.props.monthNames[this.state.value.getMonth()]

            return monthName + ' ' + this.state.value.getFullYear()
        }

        this.isEquals = (date1, date2) => {
            if (date1 && !date2 || date2 && !date1) {
                return false
            }

            return date1.getFullYear() === date2.getFullYear() &&
                date1.getMonth() === date2.getMonth() &&
                date1.getDate() === date2.getDate()
        }

        this.updateMonth = step => {
            const newDate = new Date(this.state.value.getFullYear(), this.state.value.getMonth() + step, this.state.value.getDate())
            this.setState({
                value: newDate
            })
        }
    }

    render() {
        return template.call(this)
    }
}

DatePicker.displayName = displayNames.DATE_PICKER
DatePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object,
    todayLabel: PropTypes.string,
    goToSelectedLabel: PropTypes.string,
    startDay: PropTypes.string,
    days: PropTypes.arrayOf(PropTypes.string),
    monthNames: PropTypes.arrayOf(PropTypes.string),
    showDelete: PropTypes.bool,
    fixedNumberOfRows: PropTypes.bool
}

DatePicker.defaultProps = {
    value: new Date(),
    todayLabel: 'Today',
    goToSelectedLabel: 'Go to selected',
    startDay: 'S',
    days: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    showDelete: true,
    fixedNumberOfRows: true
}

module.exports = compose(DatePicker)
