'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const mathUtil = require('../util/math')
const compose = require('../hoc/compose')
const template = require('./angleInput.rt')
const displayNames = require('./displayNames')

class AngleInput extends React.Component {
    constructor(props) {
        super(props)

        this.circleRefCallback = circle => {
            this.circle = circle
        }

        this.saveCircleCenter = () => {
            const boundingRectangle = this.circle.getBoundingClientRect()

            this.circleCenter = {
                x: boundingRectangle.left + boundingRectangle.width / 2,
                y: boundingRectangle.top + boundingRectangle.height / 2
            }
        }

        this.changeAngle = e =>
            this.props.onChange(mathUtil.getAngleFromOrigin(this.circleCenter, {x: e.pageX, y: e.pageY}))

        this.onClick = e => {
            e.preventDefault()

            this.saveCircleCenter()
            this.changeAngle(e)
        }

        this.onKnobMouseDown = () => {
            this.saveCircleCenter()
            window.document.addEventListener('mousemove', this.changeAngle)
            window.document.addEventListener('mouseup', this.onKnobMouseUp)
        }

        this.onKnobMouseUp = () => {
            window.document.removeEventListener('mousemove', this.changeAngle)
            window.document.removeEventListener('mouseup', this.onKnobMouseUp)
        }

        this.getCircleStyle = () => ({
            transform: `rotate(${this.props.value}deg)`,
            WebkitTransform: `rotate(${this.props.value}deg)`
        })
        this.getKnobStyle = () => ({
            transform: `rotate(-${this.props.value}deg)`,
            WebkitTransform: `rotate(-${this.props.value}deg)`
        })
    }

    componentWillUnmount() {
        window.document.removeEventListener('mousemove', this.changeAngle)
        window.document.removeEventListener('mouseup', this.onKnobMouseUp)
    }

    render() {
        return template.call(this)
    }
}

AngleInput.displayName = displayNames.ANGLE_INPUT

AngleInput.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}
AngleInput.defaultProps = {
    disabled: false
}

module.exports = compose(AngleInput)
