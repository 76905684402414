'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const compose = require('../hoc/compose')
const template = require('./slider.rt')
const displayNames = require('./displayNames')
const UNITS = require('../constants/numericInputUnits')

class Slider extends React.Component {
    constructor(props) {
        super(props)

        this.onSlideStart = e => {
            window.addEventListener('mousemove', this.onSlide, true)
            window.addEventListener('mouseup', this.onSlideEnd, true)

            this.boundingClientRect = this.track.getBoundingClientRect()

            if (_.isFunction(this.props.onSlideStart)) {
                this.props.onSlideStart(this.props.value)
            }

            this.onSlide(e)
        }
        this.onSlideEnd = e => {
            window.removeEventListener('mousemove', this.onSlide, true)
            window.removeEventListener('mouseup', this.onSlideEnd, true)
            this.onSlide(e)
            if (_.isFunction(this.props.onSlideEnd)) {
                this.props.onSlideEnd(this.props.value)
            }
        }

        this.onSlide = e => {
            if (!this.props.disabled) {
                const {step, min, max} = this.props
                const {left, width} = this.boundingClientRect

                const rangeRatio = (e.clientX - left) / width
                const normalizedRangeRation = Math.min(1, Math.max(0, rangeRatio))
                const newValue = min + (max - min) * normalizedRangeRation
                const mod = newValue % step
                const diffToNormalize = mod > step / 2 ? mod - step : mod
                const newValueStepNormalized = newValue - diffToNormalize

                this.handleChange(newValueStepNormalized)
            }
        }
    }

    getFilledPartInPercent() {
        const filledPartInPercent = (this.props.value - this.props.min) / (this.props.max - this.props.min) * 100
        return Math.min(100, Math.max(0, filledPartInPercent)) // normalize value to be between 0 to 100
    }

    getColoredPartStyle() {
        const gradientColor = this.props.disabled ? '#d5d5d5' : this.props.opacityGradientColor
        if (gradientColor) {
            return {
                background: `linear-gradient(90deg, transparent 0%, ${gradientColor} 100%)`,
                width: '100%'
            }
        }

        return {
            width: this.getFilledPartInPercent() + '%'
        }
    }

    handleChange(newValue) {
        if (_.isFunction(this.props.onChange)) {
            this.props.onChange(newValue)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.onSlide, true)
        window.removeEventListener('mouseup', this.onSlideEnd, true)
    }

    render() {
        return template.call(this)
    }
}

Slider.displayName = displayNames.SLIDER
Slider.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    opacityGradientColor: PropTypes.string,
    hideNumericInput: PropTypes.bool,
    onSlideStart: PropTypes.func,
    onSlideEnd: PropTypes.func,
    disabled: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    unit: PropTypes.oneOf(Object.keys(UNITS)),
    step: PropTypes.number
}
Slider.defaultProps = {
    value: 0,
    step: 1,
    min: 0,
    max: 100
}

module.exports = compose(Slider)
