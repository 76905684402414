'use strict'
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const template = require('./panelHeaderCenteredText.rt')
const textTypes = require('../controls/textTypes')

const PanelHeaderCenteredText = template

PanelHeaderCenteredText.displayName = 'PanelHeaderCenteredText'
PanelHeaderCenteredText.propTypes = {
    title: PropTypes.string.isRequired,
    titleType: PropTypes.oneOf(textTypes.PANEL_HEADER_CENTERED_TEXT_TITLE_TYPES),
    subtitle: PropTypes.string,
    onHelp: PropTypes.func,
    onClose: PropTypes.func
}
PanelHeaderCenteredText.defaultProps = {
    titleType: textTypes.PANEL_HEADER_CENTERED_TEXT_TITLE_DEFAULT_TYPE
}

module.exports = compose(PanelHeaderCenteredText)
