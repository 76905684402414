'use strict';
var React = require('react');
var _ = require('lodash');
var HorizontalTabs = require('../horizontalTabs');
var TextInput = require('../textInput');
var NumericInput = require('../numericInput');
function onChange1(format) {
    this.setState({ format: format });
}
function onChange2(v) {
    this.props.onChange('#' + v);
}
function onChange3(val, valIndex, v) {
    this.onRGBChange(v, valIndex);
}
function repeatVal4(val, valIndex) {
    return React.createElement(NumericInput, {
        'key': valIndex,
        'innerInputClass': 'color-picker-color-format-input',
        'value': val,
        'max': 255,
        'onChange': onChange3.bind(this, val, valIndex)
    });
}
function onChange5(val, valIndex, v) {
    this.onHSBChange(v, valIndex);
}
function repeatVal6(val, valIndex) {
    return React.createElement(NumericInput, {
        'key': valIndex,
        'innerInputClass': 'color-picker-color-format-input',
        'unit': valIndex === 'hue' ? 'angle' : 'percent',
        'value': this.state.hsb[valIndex],
        'max': valIndex === 'hue' ? 360 : 100,
        'onChange': onChange5.bind(this, val, valIndex)
    });
}
module.exports = function () {
    return React.createElement('div', { 'className': 'color-picker-color-format' }, React.createElement(HorizontalTabs, {
        'value': this.state.format,
        'arrowed': true,
        'onChange': onChange1.bind(this),
        'options': this.formats,
        'shouldTranslate': false
    }), React.createElement.apply(this, [
        'div',
        { 'className': 'color-picker-color-format-input-container' },
        this.state.format === 'HEX' ? React.createElement(TextInput, {
            'key': 'HEX',
            'validator': this.hexValidator,
            'invalidMessage': this.props.invalidHexMessage || 'Check the HEX value',
            'shouldTranslate': this.props.shouldTranslate,
            'syncDelay': 0,
            'className': 'color-picker-color-format-hex',
            'innerInputClass': 'color-picker-color-format-input',
            'maxLength': 6,
            'value': this.props.value.slice(-6),
            'onChange': onChange2.bind(this)
        }) : null,
        this.state.format === 'RGB' ? _.map(this.getRGB(), repeatVal4.bind(this)) : null,
        this.state.format === 'HSB' ? _.map(this.getHSB(), repeatVal6.bind(this)) : null
    ]));
};