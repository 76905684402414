'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const compose = require('../hoc/compose')
const template = require('./datePickerInput.rt')
const ActionWithSymbol = require('./actionWithSymbol')
const calendarSymbol = require('./icons/calendar.svg.js')
const dateUtil = require('../util/date')
const displayNames = require('./displayNames')

class DatePickerInput extends React.Component {
    constructor(props) {
        super(props)
        this.getCustomButton = () => {
            const isValidDate = dateUtil.isValid(this.props.value)
            const children = isValidDate ? this.props.value.toLocaleDateString() : this.props.translateIfNeeded(this.props.placeholder)

            return React.createElement(ActionWithSymbol, {
                symbol: React.createElement(calendarSymbol),
                initialized: isValidDate
            }, children)
        }
    }

    render() {
        return template.call(this)
    }
}

DatePickerInput.displayName = displayNames.DATE_PICKER_INPUT
DatePickerInput.propTypes = {
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object
}

module.exports = compose(DatePickerInput)
