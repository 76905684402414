'use strict';
var React = require('react');
var _ = require('lodash');
var TextLabel = require('../textLabel');
var InputValidationErrorSymbol = require('../inputValidationError.svg.js');
var InputValidationSuccessSymbol = require('../inputValidationSuccess.svg.js');
module.exports = function () {
    return !this.isStatusNone() ? React.createElement('div', {
        'className': 'upload-status-message',
        'key': 'showStatusMessage'
    }, React.createElement(TextLabel, {
        'value': this.getMessage(),
        'shouldTranslate': this.shouldTranslateMainMessage(),
        'type': 'T07',
        'ellipsisProps': this.getEllipsisProps()
    }), this.isStatusUploadSuccess() ? React.createElement(TextLabel, {
        'key': 'showFileExtension',
        'value': this.getFileExtension(),
        'shouldTranslate': false,
        'type': 'T07',
        'enableEllipsis': false
    }) : null, React.createElement('span', { 'className': 'status-icon' }, this.isStatusUploadSuccess() ? React.createElement(InputValidationSuccessSymbol, { 'key': 'showSuccessSymbol' }) : null, this.isStatusUploadFailed() ? React.createElement(InputValidationErrorSymbol, { 'key': 'showFailedSymbol' }) : null)) : null;
};