'use strict'
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const template = require('./radioButtonsWithIllustration.rt')
const displayNames = require('./displayNames')

const RadioButtonsWithIllustration = template

RadioButtonsWithIllustration.displayName = displayNames.RADIO_BUTTONS_WITH_ILLUSTRATION
RadioButtonsWithIllustration.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        image: PropTypes.object,
        title: PropTypes.string,
        text: PropTypes.string
    })).isRequired
}

module.exports = compose(RadioButtonsWithIllustration)
