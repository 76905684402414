'use strict'
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const displayNames = require('./displayNames')
const template = require('./tab.rt')

const Tab = template

Tab.displayName = displayNames.TAB
Tab.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
}

module.exports = compose(Tab)
