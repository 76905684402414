'use strict'
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const template = require('./panelHeaderIllustration.rt')

const PanelHeaderIllustration = template

PanelHeaderIllustration.displayName = 'PanelHeaderIllustration'
PanelHeaderIllustration.propTypes = {
    blueStripHeight: PropTypes.number
}
PanelHeaderIllustration.defaultProps = {
    blueStripHeight: 90
}

module.exports = compose(PanelHeaderIllustration)
