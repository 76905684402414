'use strict';
var React = require('react');
var _ = require('lodash');
module.exports = function (props, context) {
    return React.createElement('div', {
        'className': 'control-progress-bar',
        'data-aid': props.automationId
    }, React.createElement('div', {
        'className': 'progress-line-container',
        'style': { width: 'calc(100% - ' + (_.isNumber(props.progressMax) && props.progressMax > 99 ? 51 : 39) + 'px)' }
    }, React.createElement('div', {
        'className': 'progress-line',
        'style': { width: props.progress / (_.isNumber(props.progressMax) ? props.progressMax / 100 : 1) + '%' }
    })), React.createElement('div', { 'className': 'progress-text' }, props.progress, _.isNumber(props.progressMax) ? '/' + props.progressMax : '%'));
};