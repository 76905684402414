'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const EDIT_MODES = require('./editModes')
const _ = require('lodash')
const template = require('./sortByDragList.rt')
const CustomItemRow = require('./customItemRow.js')._rawClass
const compose = require('../../hoc/compose')

const replaceItems = (list, index1, index2) => {
    const newList = list.slice()
    const itemToMove = newList.splice(index1, 1)[0]
    newList.splice(index2, 0, itemToMove)

    return newList
}

const changeItem = (list, newVal, index) => {
    const newList = list.slice()
    newList[index] = newVal

    return newList
}

const setAttributeToItem = (list, itemIndex, attrName) => {
    const newList = _.clone(list)
    const currentItemWithAttr = _.findIndex(list, {[attrName]: true})

    if (currentItemWithAttr === itemIndex) {
        return
    }

    if (currentItemWithAttr >= 0) {
        newList[currentItemWithAttr] = _.omit(newList[currentItemWithAttr], attrName)
    }

    newList[itemIndex] = _.defaults({[attrName]: true}, newList[itemIndex])

    return newList
}

const removeAttributeToItem = (list, itemIndex, attrName) => {
    const newList = _.clone(list)
    newList[itemIndex] = _.omit(newList[itemIndex], attrName)

    return newList
}

const toObjs = children => _.map(children, child => ({
    id: child.props.id,
    selected: child.props.isSelected,
    label: child.props.label,
    value: child.props.value,
    automationId: child.props.automationId,
    customActions: child.props.children,
    contentBefore: child.props.contentBefore,
    contentAfter: child.props.contentAfter,
    disabled: child.props.disabled,
    removable: _.isUndefined(child.props.removable) || Boolean(child.props.removable),
    draggable: _.isUndefined(child.props.draggable) || Boolean(child.props.draggable),
    dragHandleEnabled: child.props.dragHandleEnabled,
    dragHandleTooltipContent: child.props.dragHandleTooltipContent,
    isContextMenuOpen: child.props.isContextMenuOpen,
    onContextMenuToggle: child.props.onContextMenuToggle,
    contextMenuAlignment: child.props.contextMenuAlignment,
    contextMenuDirection: child.props.contextMenuDirection
}))

const getValueFromProps = props => props.value ? props.value : toObjs(props.children)

const toOnChangeMetadata = (triggerId, changedItem) => ({triggerId, changedItem})

class SortByDragList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value: getValueFromProps(props),
            draggingItem: null,
            itemInEdit: null,
            editMode: EDIT_MODES.NONE
        }

        this.optimisticUpdate = (optimisticValue, updateFunc) => {
            this.setState({value: optimisticValue}, updateFunc)
        }

        this.replaceItems = (index1, index2) => {
            const newList = replaceItems(this.state.value, index1, index2)

            const updateFunc = this.props.itemMoved ?
                () => this.props.itemMoved(index1, index2) :
                () => this.props.onChange(newList, toOnChangeMetadata('replaceItems', newList[index2]))

            this.optimisticUpdate(newList, updateFunc)
        }

        this.changeItem = (index, newVal) => {
            const newList = changeItem(this.state.value, newVal, index)

            const updateFunc = this.props.itemChanged ?
                () => this.props.itemChanged(newVal, index) :
                () => this.props.onChange(newList, toOnChangeMetadata('changeItem', newVal))

            return this.optimisticUpdate(newList, updateFunc)
        }

        this.isDragging = itemIndex => this.state.draggingItem === itemIndex

        this.setAsDefault = itemIndex => this.props.onChange(setAttributeToItem(this.state.value, itemIndex, 'isDefault'), toOnChangeMetadata('setAsDefault'))

        this.removeAsDefault = itemIndex => this.props.onChange(removeAttributeToItem(this.state.value, itemIndex, 'isDefault'), toOnChangeMetadata('removeAsDefault'))

        this.toggleDefault = (item, itemIndex) => {
            if (this.props.toggleDefault) {
                return this.props.toggleDefault(item, itemIndex, this.state.value)
            }

            return item.isDefault ? this.removeAsDefault(itemIndex) : this.setAsDefault(itemIndex)
        }

        this.selectItem = newSelectedIndex => this.props.selectItem ?
            this.props.selectItem(this.state.value[newSelectedIndex], newSelectedIndex, this.state.value) :
            this.props.onChange(setAttributeToItem(this.state.value, newSelectedIndex, 'selected'), toOnChangeMetadata('selectItem'))

        this.duplicate = itemIndex => {
            if (this.props.duplicateItem) {
                return this.props.duplicateItem(this.state.value[itemIndex], itemIndex, this.state.value)
            }

            if (_.isFunction(this.props.cloneItem)) {
                const newList = this.state.value.slice()

                const newNode = _(newList[itemIndex])
                    .omit(['selected', 'isDefault'])
                    .thru(this.props.cloneItem)
                    .value()

                newList.splice(itemIndex + 1, 0, newNode)

                this.props.onChange(newList, toOnChangeMetadata('duplicate'))
            }
        }

        this.delete = itemIndex => {
            if (this.props.deleteItem) {
                return this.props.deleteItem(this.state.value[itemIndex], itemIndex, this.state.value)
            }

            const newList = this.state.value.slice()

            const deletedItems = newList.splice(itemIndex, 1)

            this.props.onChange(newList, toOnChangeMetadata('delete', deletedItems[0]))
        }

        this.getListClasses = () => this.props.getClassName('sort-by-drag-list') +
            (this.props.disabled ? ' disabled' : '') +
            (this.props.noBorderTop ? ' no-border-top' : '')


        this.hasEditingItem = () => this.state.itemInEdit !== null

        this.isListDraggable = () => this.props.draggable && !this.hasEditingItem()

        this.getItemEditMode = index => this.state.itemInEdit === index ? this.state.editMode : EDIT_MODES.NONE

        this.toggleEditLabelMode = index => {
            if (this.state.editMode === EDIT_MODES.EDIT_LABEL) {
                this.setState({itemInEdit: null, editMode: EDIT_MODES.NONE}, () => {
                    if (_.isFunction(this.props.onLabelEditEnd)) {
                        this.props.onLabelEditEnd(index)
                    }
                })
            } else {
                this.setState({itemInEdit: index, editMode: EDIT_MODES.EDIT_LABEL}, () => {
                    if (_.isFunction(this.props.onLabelEditStart)) {
                        this.props.onLabelEditStart(index)
                    }
                })
            }
        }

        this.toggleEditValueMode = index => {
            if (this.state.editMode === EDIT_MODES.EDIT_VALUE) {
                this.setState({itemInEdit: null, editMode: EDIT_MODES.NONE}, () => {
                    if (_.isFunction(this.props.onValueEditEnd)) {
                        this.props.onValueEditEnd(index)
                    }
                })
            } else {
                this.setState({itemInEdit: index, editMode: EDIT_MODES.EDIT_VALUE}, () => {
                    if (_.isFunction(this.props.onValueEditStart)) {
                        this.props.onValueEditStart(index)
                    }
                })
            }
        }

        this.getDefaultMenuActions = itemIndex => {
            const DEFAULT_MENU_ACTIONS = [
                {
                    key: 'toggleDefault',
                    enable: true,
                    label(item) {
                        return item.isDefault ? this.labelOff : this.labelOn
                    },
                    labelOn: 'Set as default',
                    labelOff: 'Remove as default',
                    action: () => this.toggleDefault(this.state.value[itemIndex], itemIndex)
                },
                {
                    key: 'duplicate',
                    enable: true,
                    label: 'Duplicate',
                    action: () => this.duplicate(itemIndex)
                },
                {
                    key: 'toggleEditLabel',
                    enable: true,
                    label: 'Rename',
                    action: () => this.toggleEditLabelMode(itemIndex)
                },
                {
                    key: 'toggleEditValue',
                    enable: true,
                    label: 'Edit value',
                    action: () => this.toggleEditValueMode(itemIndex)
                },
                {
                    key: 'delete',
                    enable: true,
                    label: 'Delete',
                    action: () => this.delete(itemIndex)
                }
            ]

            return _.reduce(DEFAULT_MENU_ACTIONS, (result, action, actionIndex) => {
                const actionToAdd = _.omit(action, 'key')
                actionToAdd.priority = (actionIndex + 1) * -1
                result[action.key] = actionToAdd

                return result
            }, {})
        }

        this.getMenuActions = itemIndex => {
            const menuActions = this.getDefaultMenuActions(itemIndex)

            if (this.props.menuActionsOverrides) {
                _.merge(menuActions, this.props.menuActionsOverrides)
            }

            return _.pickBy(menuActions, 'enable')
        }

        this.startDragCallback = draggingItemIndex => {
            this.setState({draggingItem: draggingItemIndex})
        }

        this.endDragCallback = () => {
            this.setState({draggingItem: null})
        }

        this.getLabelValidators = () => {
            if (_.isArray(this.props.labelValidator)) {
                return this.props.labelValidator
            }

            return _.isFunction(this.props.labelValidator) ?
                [{validator: this.props.labelValidator, invalidMessage: this.props.labelInvalidMessage}] :
                []
        }

        this.getValueValidators = () => {
            if (_.isArray(this.props.valueValidator)) {
                return this.props.valueValidator
            }

            return _.isFunction(this.props.valueValidator) ?
                [{validator: this.props.valueValidator, invalidMessage: this.props.valueInvalidMessage}] :
                []
        }

        this.getStyle = () => this.props.paddingBottom ? {paddingBottom: this.props.paddingBottom} : {}
    }

    componentWillReceiveProps(nextProps) {
        this.setState({value: getValueFromProps(nextProps)})
    }

    render() {
        return template.call(this)
    }

}

SortByDragList.displayName = 'SortByDragList'

SortByDragList.propTypes = {
    onChange: PropTypes.func,
    onItemHover: PropTypes.func,
    disabled: PropTypes.bool,
    selectable: PropTypes.bool,
    draggable: PropTypes.bool,
    selectItem: PropTypes.func, // (item, itemIndex, collection)
    itemMoved: PropTypes.func, // optimistic* - (sourceItemIndex, targetIndex)
    itemChanged: PropTypes.func, // optimistic* - (item, itemIndex)
    toggleDefault: PropTypes.func, // (item, itemIndex, collection)
    duplicateItem: PropTypes.func, // (item, itemIndex, collection)
    deleteItem: PropTypes.func, // (item, itemIndex, collection)
    labelValidator: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.shape({
            validator: PropTypes.func.isRequired,
            invalidMessage: PropTypes.string.isRequired
        }))
    ]),
    labelInvalidMessage: PropTypes.string,
    valueValidator: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.shape({
            validator: PropTypes.func.isRequired,
            invalidMessage: PropTypes.string.isRequired
        }))
    ]),
    valueInvalidMessage: PropTypes.string,
    itemValuePrefix: PropTypes.string,
    labelPlaceholder: PropTypes.string,
    valuePlaceholder: PropTypes.string,
    menuActionsOverrides: PropTypes.shape({
        toggleDefault: PropTypes.shape({
            enable: PropTypes.bool,
            labelOn: PropTypes.string,
            labelOff: PropTypes.string,
            automationId: PropTypes.string,
            priority: PropTypes.number
        }),
        duplicate: PropTypes.shape({
            enable: PropTypes.bool,
            label: PropTypes.string,
            automationId: PropTypes.string,
            priority: PropTypes.number
        }),
        toggleEditLabel: PropTypes.shape({
            enable: PropTypes.bool,
            label: PropTypes.string,
            labelMaxLength: PropTypes.number,
            automationId: PropTypes.string,
            priority: PropTypes.number
        }),
        toggleEditValue: PropTypes.shape({
            enable: PropTypes.bool,
            label: PropTypes.string,
            valueMaxLength: PropTypes.number,
            automationId: PropTypes.string,
            priority: PropTypes.number
        }),
        delete: PropTypes.shape({
            enable: PropTypes.bool,
            label: PropTypes.string,
            automationId: PropTypes.string,
            priority: PropTypes.number
        })
    }),
    onLabelEditStart: PropTypes.func, // (itemIndex)
    onLabelEditEnd: PropTypes.func, // (itemIndex)
    onValueEditStart: PropTypes.func, // (itemIndex)
    onValueEditEnd: PropTypes.func, // (itemIndex)
    noBorderTop: PropTypes.bool,
    paddingBottom: PropTypes.number,
    value: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string,
        automationId: PropTypes.string,
        selected: PropTypes.bool,
        isDefault: PropTypes.bool
    })),
    children: (props, propName, componentName) => {
        const VALUE_PROP = 'value'
        let children = props[propName]
        if (props[VALUE_PROP] && children) {
            return new Error(`Overlapping props '${propName}' and '${VALUE_PROP}' supplied to '${componentName}', expected only one of them.`)
        } else if (props[VALUE_PROP]) {
            return null
        } else if (children) {
            if (!_.isArray(children)) {
                children = [children]
            }

            if (_.some(children, child => _.get(child, 'type._rawClass') !== CustomItemRow)) {
                return new Error(`Invalid prop '${propName}' of type ${typeof child} supplied to '${componentName}', expected children of type '${CustomItemRow.displayName}'.`)
            }

            return null
        }
        return new Error(`Required props '${propName}' and '${VALUE_PROP}' were not specified in '${componentName}', one of them is required`)
    }
}

SortByDragList.defaultProps = {
    disabled: false,
    selectable: false,
    draggable: true
}

module.exports = compose(SortByDragList)
