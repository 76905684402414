'use strict';
var React = require('react');
var _ = require('lodash');
module.exports = function (props, context) {
    return React.createElement('div', { 'className': props.getClassName('image-and-text') }, props.image ? React.createElement('div', {
        'key': 'image',
        'className': 'image'
    }, props.image) : null, props.title || props.text ? React.createElement('div', {
        'key': 'content',
        'className': 'content'
    }, props.title ? React.createElement('h3', {
        'key': 'title',
        'className': 'title'
    }, props.translateIfNeeded(props.title)) : null, props.text ? React.createElement('div', {
        'key': 'text',
        'className': 'text'
    }, _.isString(props.text) ? props.translateIfNeeded(props.text) : props.text) : null) : null);
};