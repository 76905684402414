'use strict';
var React = require('react');
var _ = require('lodash');
var RadioButtons = require('./radioButtons');
module.exports = function () {
    return React.createElement('div', {
        'className': 'control-buttons-list-group',
        'data-aid': this.props.automationId
    }, React.createElement(RadioButtons, _.assign({}, {
        'orientation': 'horizontal',
        'showRadioSymbol': false
    }, this.pickProps())));
};