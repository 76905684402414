'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const ReactDOM = require('react-dom')
const _ = require('lodash')
const compose = require('../hoc/compose')
const displayNames = require('./displayNames')
const dropDownHelpers = require('../util/dropDownHelpers')
const dropDownPositionUtil = require('../util/dropDownPosition')
const {Option, Selected, SearchBox} = require('./dropDownChildren')
const KEYS = require('../constants/keyCodes')
const template = require('./dropDown.rt')
const {
    getRectByBoundingRect,
    getRectByReactElement,
    getStyleByRect
} = require('../util/rect')

const {
    wrapOption
} = dropDownHelpers

const isSelected = (val, curr) => !_.isUndefined(val) && (
    val === curr ||
    val === (curr && curr.value) ||
    val === _.get(curr, 'props.value')
)


const wrapOptionWithOptionComponent = wrapOption.bind(null, Option)

const getOptionsValue = (opt, key = 'value') => {
    if (React.isValidElement(opt)) {
        return opt.props[key]
    }
    return _.isObject(opt) ? opt[key] : opt
}

const getOptionWrapperClassName = opt => {
    if (!React.isValidElement(opt) && !_.isObject(opt)) {
        return null
    }
    return getOptionsValue(opt, 'optionWrapperClassName')
}

const getOptionsLabel = opt => getOptionsValue(opt, 'label')

const isOptionsDisabled = opt => {
    if (React.isValidElement(opt)) {
        return opt.props.disabled
    }
    return _.isObject(opt) ? opt.disabled : false
}

const isTermInOption = (term, opt) => {
    const searchable = getOptionsLabel(opt) || getOptionsValue(opt)
    return searchable && _.startsWith(searchable.toLowerCase(), term.toLowerCase())
}

const getHiddenScrollStyle = node => ({
    width: `calc(100% + ${node.offsetWidth - node.clientWidth}px)`
})

const getSelectedKeyAndChild = (selected, shouldRenderSearchBox) => {
    if (React.isValidElement(selected)) {
        return {
            key: selected.key,
            child: shouldRenderSearchBox ? selected.props.label || selected.props.value : selected
        }
    }
    const isStringOrFalsyValue = _.isString(selected) || !selected
    return {
        key: isStringOrFalsyValue ? selected : selected.key || selected.label || selected.value,
        child: isStringOrFalsyValue ? selected : selected.label || selected.value
    }
}

const getNodeByRefIfExist = node => node ? ReactDOM.findDOMNode(node) : null

class DropDown extends React.Component {
    constructor(props) {
        super(props)
        const onOptionClick = val => {
            if (!isSelected(val, this.props.value) || this.props.callOnChangeAnyway) {
                this.props.onChange(val)
            }
            this.setState({
                isOpen: false,
                candidateIndex: _.indexOf(this.getOptions(), this.getSelected())
            })
        }

        this.getOptions = () => {
            const flatten = this.props.groupedOptions ? _.flattenDeep : _.flatten

            const children = this.props.children && (_.isArray(this.props.children) ? this.props.children : [this.props.children])

            return this.props.options || _.compact(flatten(children)) || []
        }

        this.findOption = val => _.find(this.getOptions(), isSelected.bind(null, val))

        this.hasChildren = () => React.isValidElement(this.getOptions())

        this.map = this.hasChildren() ? React.Children.map : _.map

        this.getSelected = () => this.findOption(this.props.value) || this.props.placeholder || this.getOptions()[0]

        this.onSelectedMount = ref => {
            this.wrappedSelectedOption = ref
        }

        this.onActiveUpdate = ref => {
            this.activeOption = ref
        }

        this.registerStickyFooter = ref => {
            this.stickyFooter = ref
        }

        this.optionsRefs = []
        this.optionsY = []
        this.onOptionMount = (index, ref) => {
            this.optionsRefs[index] = ref
            this.optionsY[index] = ReactDOM.findDOMNode(ref).offsetTop
        }

        this.getOptionsElements = () => this.map(this.getOptions(), (opt, index) => wrapOptionWithOptionComponent({
            onClick: onOptionClick,
            // TODO: Performance improvement: Use candidateIndex state only for keys (UP/DOWN) and use hover and click for mouse
            onMouseEnter: () => this.setState({candidateIndex: index}),
            shouldTranslate: this.props.shouldTranslate,
            selected: this.getSelected() === opt,
            active: index === this.state.candidateIndex,
            onSelectedMount: this.onSelectedMount,
            onActive: this.onActiveUpdate,
            key: opt.key || getOptionsValue(opt),
            onMount: this.props.shouldPassIsVisibleToOptions ? ref => {this.onOptionMount(index, ref)} : _.noop,
            isVisible: this.props.shouldPassIsVisibleToOptions ? _.includes(this.state.visibleOptionsIndexes, index) : null,
            wasVisible: this.props.shouldPassIsVisibleToOptions ? _.includes(this.state.wasVisibleOptionsIndexes, index) : null,
            registerStickyFooter: this.registerStickyFooter,
            toggleOptions: this.toggleOptions,
            optionWrapperClassName: getOptionWrapperClassName(opt)
        }, opt))

        this.getCloseKeys = () => this.props.searchBox ? [KEYS.ENTER, KEYS.ESC] : null

        this.searchOption = term => _.find(this.getOptions(), opt =>
            isTermInOption(term, opt) && this.getIsSelectableByIndex(this.getOptions().indexOf(opt)))

        this.onSearch = (searchTerm, callback) => {
            const foundOption = searchTerm && this.searchOption(searchTerm)
            const candidateIndex = foundOption ? this.getOptions().indexOf(foundOption) : this.state.candidateIndex
            this.setState({
                candidateIndex,
                foundOption,
                searchTerm
            }, () => {
                const enforce = true
                this.scrollToActive(enforce)
                if (_.isFunction(callback)) {
                    callback()
                }
            })
        }

        this.registerInputToAllowEvents = input => {
            this.setState({inputToAllowEvents: input})
        }

        this.getSelectedElements = () => {
            const selected = this.getSelected()
            const {hasArrowIcon, shouldTranslate} = this.props
            const shouldRenderSearchBox = this.props.searchBox && this.state.isOpen
            const {key, child} = getSelectedKeyAndChild(selected, shouldRenderSearchBox)

            if (shouldRenderSearchBox) {
                return React.createElement(SearchBox, {
                    key,
                    hasArrowIcon,
                    shouldTranslate,
                    value: getOptionsLabel(this.state.foundOption) || this.state.searchTerm,
                    searchTerm: this.state.searchTerm,
                    selectedValue: child,
                    onChange: this.onSearch,
                    registerInputToAllowEvents: this.registerInputToAllowEvents
                })
            }

            return React.createElement(Selected, {
                key: key || 'selected',
                isPlaceholder: this.props.placeholder && selected === this.props.placeholder,
                hasArrowIcon,
                shouldTranslate
            }, child)
        }

        this.toggleOptions = isOpen => this.setState({isOpen: _.isUndefined(isOpen) ? !this.state.isOpen : isOpen})
        this.state = {
            isOpen: false,
            optionsStyle: {},
            optionsInnerStyle: {},
            visibleOptionsIndexes: [],
            wasVisibleOptionsIndexes: [],
            candidateIndex: _.indexOf(this.getOptions(), this.getSelected()),
            searchTerm: ''
        }

        this.getValueByIndex = index => getOptionsValue(this.getOptions()[index])
        this.getIsDisabledByIndex = index => isOptionsDisabled(this.getOptions()[index])
        this.getIsSelectableByIndex = index => !(_.isUndefined(this.getValueByIndex(index)) || this.getIsDisabledByIndex(index))

        this.getNewIndex = by => {
            let index = this.state.candidateIndex
            const originalIndex = index
            const min = 0
            const max = this.getOptions().length - 1

            do {
                index += by
            } while (!this.getIsSelectableByIndex(index) && index >= 0 && index <= max)

            return index === max + Math.abs(by) || index === min - Math.abs(by) ? originalIndex : index
        }

        this.scrollOptionsNode = (optionsNode, deltaY) => {
            if (!this.customBlockScroll(optionsNode, deltaY)) {
                optionsNode.scrollTop += deltaY
            }
        }

        this.scrollToActive = enforce => {
            if (this.activeOption) {
                const activeNode = ReactDOM.findDOMNode(this.activeOption)
                const optionsNode = activeNode.parentElement

                if (enforce) {
                    optionsNode.scrollTop = activeNode.offsetTop
                }

                const optionsScrollTop = optionsNode.scrollTop
                const activeNodeBottom = activeNode.offsetTop + activeNode.offsetHeight

                const visibleBottom = optionsScrollTop + optionsNode.offsetHeight
                if (activeNodeBottom > visibleBottom) {
                    this.scrollOptionsNode(optionsNode, activeNodeBottom - visibleBottom)
                } else if (activeNode.offsetTop < optionsScrollTop) {
                    this.scrollOptionsNode(optionsNode, activeNode.offsetTop - optionsScrollTop)
                }
            }
        }

        this.onKeyDown = (e, isOpen) => {
            if (isOpen) {
                if (e.keyCode === KEYS.UP || e.keyCode === KEYS.DOWN) {
                    const incrementBy = e.keyCode === KEYS.UP ? -1 : 1
                    this.setState({candidateIndex: this.getNewIndex(incrementBy)}, this.scrollToActive)
                } else if (e.keyCode === KEYS.ENTER || e.keyCode === KEYS.SPACE && !this.props.searchBox) {
                    _(this.state.candidateIndex)
                        .thru(this.getValueByIndex)
                        .thru(this.props.onChange)
                        .value()
                }
            }
        }

        const updateScrollCache = (optionsNode, deltaY) => {
            const remainingScroll = optionsNode.scrollHeight - optionsNode.offsetHeight
            this.scrollCache += Math.min(Math.abs(deltaY), remainingScroll)
            this.onOptionsLayoutChange(this.lastOptionsBoundingRect, optionsNode)
        }

        this.scrollCache = 0
        this.customBlockScroll = (optionsNode, deltaY) => { // TODO: Pick better name
            if (this.props.searchBox) {
                return false
            }

            const optionsRect = optionsNode.getBoundingClientRect()
            const remainingScroll = optionsNode.scrollHeight - optionsNode.offsetHeight
            const indentFromEdge = dropDownPositionUtil.CONSTS.INDENT_FROM_EDGE
            const maxAllowedHeight = dropDownPositionUtil.getViewportSize().height - indentFromEdge * 2

            if (optionsNode.offsetHeight < maxAllowedHeight && remainingScroll > 0) {
                if (deltaY > 0 && optionsRect.top > indentFromEdge) {
                    updateScrollCache(optionsNode, deltaY)
                    return true
                } else if (deltaY < 0 && optionsRect.bottom < maxAllowedHeight + indentFromEdge) {
                    updateScrollCache(optionsNode, deltaY)
                    return true
                }
            }
        }

        const updateOptionsVisibility = scrollTop => {
            const OFFSET = 30
            const height = this.state.optionsInnerStyle.height || this.state.optionsStyle.height
            const visibleOptionsIndexes = _(this.optionsY)
                .map((y, i) => scrollTop - OFFSET < y && y < scrollTop + height + OFFSET ? i : null)
                .filter(i => i !== null)
                .value()

            const wasVisibleOptionsIndexes = _.union(this.state.wasVisibleOptionsIndexes, visibleOptionsIndexes)

            this.setState({
                visibleOptionsIndexes,
                wasVisibleOptionsIndexes
            })
        }

        this.onOptionsScroll = e => {
            if (this.props.shouldPassIsVisibleToOptions) {
                updateOptionsVisibility(e.target.scrollTop)
            }
        }

        const getStickyFooterHeight = () => this.stickyFooter ? ReactDOM.findDOMNode(this.stickyFooter).offsetHeight : 0

        const getStickyFooterStyle = (optionsHeight, stickyFooterHeight) =>
            stickyFooterHeight ? {height: optionsHeight - stickyFooterHeight} : {}

        const getOptionsRect = (currentBoundingRect, stickyFooterHeight) => {
            const optionsRect = getRectByBoundingRect(currentBoundingRect)
            optionsRect.height += stickyFooterHeight
            return optionsRect
        }

        this.onOptionsLayoutChange = (optionsBoundingRect, optionsNode) => {
            if (!optionsBoundingRect) {
                this.scrollCache = 0
            }
            this.lastOptionsBoundingRect = optionsBoundingRect
            if (optionsBoundingRect) {
                const viewport = dropDownPositionUtil.getViewportSize()
                const selectedRect = getRectByReactElement(this)
                const stickyFooterHeight = getStickyFooterHeight()
                const optionsRect = getOptionsRect(optionsBoundingRect, stickyFooterHeight)
                const selectedOptionsNode = getNodeByRefIfExist(this.wrappedSelectedOption)
                const selectedOptionY = !this.props.searchBox && this.props.openOnSelected && selectedOptionsNode ? selectedOptionsNode.offsetTop || 0 : null
                const params = {longerByScroll: this.scrollCache, selectedOptionY}
                const optionsPosition = dropDownPositionUtil.getOptionsPosition(viewport, selectedRect, optionsRect, params)

                const stickyFooterStyle = getStickyFooterStyle(optionsPosition.height, stickyFooterHeight)
                const hiddenScrollStyle = this.props.hiddenScroll && !this.props.searchBox ? getHiddenScrollStyle(optionsNode) : {}

                this.setState({
                    optionsStyle: getStyleByRect(optionsPosition),
                    optionsInnerStyle: _.assign({}, hiddenScrollStyle, stickyFooterStyle)
                }, () => {
                    const selectedOptionsRect = selectedOptionsNode ?
                        getRectByBoundingRect(selectedOptionsNode.getBoundingClientRect()) : {}
                    const shouldAutoScroll = !this.scrollCache &&
                                             selectedOptionY > 0 &&
                                             optionsNode.scrollTop === 0 &&
                                             selectedRect.y !== selectedOptionsRect.y
                    if (shouldAutoScroll) {
                        optionsNode.scrollTop = selectedOptionsRect.y - selectedRect.y
                    } else if (this.props.shouldPassIsVisibleToOptions) {
                        updateOptionsVisibility(optionsNode.scrollTop)
                    }
                })
            }
        }

        this.getOptionsContainerClassName = () => _.compact([
            this.props.optionsContainerClassName,
            this.props.hiddenScroll && !this.props.searchBox ? 'hidden-scroll' : null,
            dropDownPositionUtil.getViewportSize().width <= dropDownPositionUtil.CONSTS.NARROW_VIEWPORT ? 'narrow-viewport' : null
        ]).join(' ')
    }

    render() {
        return template.call(this)
    }
}

DropDown.displayName = displayNames.DROP_DOWN

DropDown.propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.any,
    options: dropDownHelpers.PropTypes.options,
    onChange: PropTypes.func,
    onToggle: PropTypes.func,
    hasArrowIcon: PropTypes.bool,
    hiddenScroll: PropTypes.bool,
    openOnSelected: PropTypes.bool,
    searchBox: PropTypes.bool,
    shouldPassIsVisibleToOptions: PropTypes.bool,
    optionsContainerClassName: PropTypes.string,
    groupedOptions: PropTypes.bool,
    placeholder: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.element.isRequired
    ]),
    callOnChangeAnyway: PropTypes.bool,
    dataLabel: PropTypes.string,
    forceOpen: PropTypes.bool
}

DropDown.defaultProps = {
    disabled: false,
    hasArrowIcon: true,
    hiddenScroll: true,
    openOnSelected: true,
    searchBox: false,
    shouldPassIsVisibleToOptions: false,
    groupedOptions: false,
    optionsContainerClassName: '',
    callOnChangeAnyway: false
}

module.exports = compose(DropDown)
