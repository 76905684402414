'use strict';
var React = require('react');
var _ = require('lodash');
var Slider = require('../slider');
function onDragStart1(e) {
    e.preventDefault();
}
function onClick2(option, optionIndex) {
    this.setNewColorByHex(option);
}
function repeatOption3(option, optionIndex) {
    return React.createElement('div', {
        'key': 'brightnessOption-' + optionIndex,
        'className': 'brightness-option',
        'style': { backgroundColor: option },
        'onClick': onClick2.bind(this, option, optionIndex)
    });
}
module.exports = function () {
    return React.createElement('div', {
        'className': 'color-picker-color-space',
        'onDragStart': onDragStart1.bind(this)
    }, React.createElement('div', {
        'className': 'color-space-gradient-area',
        'onMouseDown': this.onMarkerDragStart,
        'ref': 'gradient'
    }, React.createElement('div', {
        'className': 'color-space-marker',
        'style': this.getMarkerPosition()
    }), React.createElement('div', {
        'className': 'color-gradient-layer',
        'style': { backgroundColor: 'hsl(' + this.state.hsb.hue + ', 100%, 50%)' }
    }), React.createElement('div', { 'className': 'color-gradient-layer saturation-layer' }), React.createElement('div', { 'className': 'color-gradient-layer brightness-layer' })), React.createElement.apply(this, [
        'div',
        { 'className': 'color-picker-brightness-options' },
        _.map(this.getBrightnessOptions(), repeatOption3.bind(this))
    ]), React.createElement(Slider, {
        'className': 'color-picker-hue-slider hue-slider',
        'value': this.state.hsb.hue,
        'min': 0,
        'max': 358,
        'hideNumericInput': true,
        'onChange': this.setNewColorByHue
    }));
};