'use strict';
var React = require('react');
var _ = require('lodash');
var RadioButtons = require('./radioButtons');
var imageAndTextTemplate = require('./imageAndTextTemplate');
module.exports = function (props, context) {
    return React.createElement(RadioButtons, {
        'className': props.getClassName('image-and-text-radio-buttons'),
        'automationId': props.automationId,
        'value': props.value,
        'onChange': props.onChange,
        'labelTemplate': imageAndTextTemplate,
        'options': props.options
    });
};