'use strict'
const _ = require('lodash')
const React = require('react')

const blockOuterScroll = e => {
    const contentNode = e.currentTarget
    const totalHeight = e.currentTarget.scrollHeight
    const maxScroll = totalHeight - e.currentTarget.offsetHeight
    const delta = e.deltaY % 3 ? e.deltaY : e.deltaY * 10

    if (contentNode.scrollTop + delta <= 0) {
        contentNode.scrollTop = 0
        e.preventDefault()
    } else if (contentNode.scrollTop + delta >= maxScroll) {
        contentNode.scrollTop = maxScroll
        e.preventDefault()
    }

    e.stopPropagation()
}

const withBlockOuterScroll = Component => props => React.createElement(Component, _.defaults({blockOuterScroll}, props))

module.exports = withBlockOuterScroll
