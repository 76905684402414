'use strict'
const _ = require('lodash')
const PropTypes = require('prop-types')
const React = require('react')


const withTranslation = Component => {

    class Translated extends React.Component {

        translateIfNeeded(key) {
            if (!key || !this.context || !this.context.translateFromContext) {
                return key
            }
            return this.props.shouldTranslate ? this.context.translateFromContext(key) : key
        }

        render() {
            const props = _.assign({}, this.props, {
                translateIfNeeded: this.translateIfNeeded.bind(this)
            })
            return React.createElement(Component, props)
        }
    }


    Translated.contextTypes = {
        translateFromContext: PropTypes.func
    }

    Translated.propTypes = {
        shouldTranslate: PropTypes.bool
    }

    Translated.defaultProps = {
        shouldTranslate: true
    }

    return Translated

}

module.exports = withTranslation
