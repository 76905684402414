'use strict'
const PropTypes = require('prop-types')
const controlNames = require('../controls/displayNames')
const compositeNames = require('../composites/displayNames')
const panelNames = require('../panels/displayNames')
const {childType} = require('./childValidation')

module.exports = {
    ActionSetHorizontal: {
        displayName: compositeNames.ACTION_SET_HORIZONTAL,
        className: 'composite-action-set-horizontal',
        childrenTypes: [
            childType.optional(controlNames.CHECKBOX, controlNames.RICH_TEXT),
            childType.once(controlNames.BUTTON),
            childType.optional(controlNames.BUTTON)
        ]
    },
    ActionSetHorizontalSpaced: {
        displayName: compositeNames.ACTION_SET_HORIZONTAL_SPACED,
        className: 'composite-action-set-horizontal-spaced',
        childrenTypes: [
            childType.once(controlNames.BUTTON),
            childType.once(controlNames.BUTTON)
        ]
    },
    ActionSetVertical: {
        displayName: compositeNames.ACTION_SET_VERTICAL,
        className: 'composite-action-set-vertical',
        childrenTypes: [
            childType.once(controlNames.BUTTON),
            childType.optional(controlNames.BUTTON)
        ]
    },
    ActionWithSymbol: {
        displayName: compositeNames.ACTION_WITH_SYMBOL,
        className: 'composite-action-with-symbol',
        childrenTypes: [
            childType.once(controlNames.ACTION_WITH_SYMBOL)
        ]
    },
    ActionWithSymbolLabeled: {
        displayName: compositeNames.ACTION_WITH_SYMBOL_LABELED,
        className: 'composite-action-with-symbol-labeled composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.ACTION_WITH_SYMBOL)
        ]
    },
    AngleInputLabeled: {
        displayName: compositeNames.ANGLE_INPUT_LABELED,
        className: 'composite-angle-input-labeled',
        childrenTypes: [
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.ANGLE_INPUT)
        ]
    },
    Banner: {
        displayName: compositeNames.BANNER,
        className: 'composite-banner composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.once(controlNames.SECTION_DIVIDER)
        ]
    },
    BigPanelHeader: {
        displayName: compositeNames.BIG_PANEL_HEADER,
        className: 'composite-big-panel-header',
        childrenTypes: [
            childType.once(panelNames.PANEL_HEADER_CENTERED_TEXT)
        ]
    },
    BigPanelHeaderWithIllustration: {
        displayName: compositeNames.BIG_PANEL_HEADER_WITH_ILLUSTRATION,
        className: 'composite-big-panel-header-with-illustration',
        childrenTypes: [
            childType.once(panelNames.PANEL_HEADER_CENTERED_TEXT),
            childType.once(panelNames.PANEL_HEADER_ILLUSTRATION)
        ]
    },
    ButtonMedium: {
        displayName: compositeNames.BUTTON_MEDIUM,
        className: 'composite-button-medium',
        childrenTypes: [childType.once(controlNames.BUTTON)]
    },
    ButtonLargeFixedBottom: {
        displayName: compositeNames.BUTTON_LARGE_FIXED_BOTTOM,
        className: 'composite-button-large-fixed-bottom',
        childrenTypes: [childType.multiple(controlNames.BUTTON)]
    },
    ButtonLargeLabeled: {
        displayName: compositeNames.BUTTON_LARGE_LABELED,
        className: 'composite-button-large-labeled composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.BUTTON)
        ]
    },
    ButtonLarge: {
        displayName: compositeNames.BUTTON_LARGE,
        className: 'composite-button-large composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.multiple(controlNames.BUTTON)
        ]
    },
    ButtonLeft: {
        displayName: compositeNames.BUTTON_LEFT,
        className: 'composite-button-left',
        childrenTypes: [childType.once(controlNames.BUTTON, controlNames.BACK_BUTTON)]
    },
    ButtonsGroup: {
        displayName: compositeNames.BUTTONS_GROUP,
        className: 'composite-buttons-group composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.once(controlNames.BUTTONS_GROUP)
        ]
    },
    ButtonsGroupLabeled: {
        displayName: compositeNames.BUTTONS_GROUP_LABELED,
        className: 'composite-buttons-group-labeled composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.BUTTONS_GROUP)
        ]
    },
    CornerRadiusInput: {
        displayName: compositeNames.CORNER_RADIUS_INPUT,
        className: 'composite-corner-radius-input',
        childrenTypes: [childType.once(controlNames.CORNER_RADIUS_INPUT)]
    },
    CornerRadiusInputLabeled: {
        displayName: compositeNames.CORNER_RADIUS_INPUT_LABELED,
        className: 'composite-corner-radius-input-labeled',
        childrenTypes: [
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.CORNER_RADIUS_INPUT)]
    },
    CheckboxWithInfoIcon: {
        displayName: compositeNames.CHECKBOX_WITH_INFO_ICON,
        className: 'composite-checkbox-with-info-icon composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.once(controlNames.CHECKBOX)
        ]
    },
    CheckboxesLabeled: {
        displayName: compositeNames.CHECKBOXES_LABELED,
        className: 'composite-checkboxes-labeled composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.once(controlNames.TEXT_LABEL),
            childType.multiple(compositeNames.CHECKBOX_WITH_INFO_ICON, controlNames.CHECKBOX)
        ]
    },
    Checkboxes: {
        displayName: compositeNames.CHECKBOXES,
        className: 'composite-checkboxes',
        childrenTypes: [
            childType.multiple(compositeNames.CHECKBOX_WITH_INFO_ICON, controlNames.CHECKBOX)
        ]
    },
    DatePickerLabeled: {
        displayName: compositeNames.DATE_PICKER_LABELED,
        className: 'composite-date-picker-labeled',
        childrenTypes: [
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.DATE_PICKER_INPUT)
        ]
    },
    DockLabeled: {
        displayName: compositeNames.DOCK_LABELED,
        className: 'composite-dock-labeled composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.DOCK)
        ]
    },
    DropDownLabeled: {
        displayName: compositeNames.DROP_DOWN_LABELED,
        className: 'composite-dropdown-labeled composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.once(controlNames.TEXT_LABEL, controlNames.DROP_DOWN),
            childType.once(controlNames.DROP_DOWN)
        ]
    },
    DropDown: {
        displayName: compositeNames.DROP_DOWN,
        className: 'composite-dropdown',
        childrenTypes: [childType.once(controlNames.DROP_DOWN)]
    },
    DropDownWithButton: {
        displayName: compositeNames.DROP_DOWN_WITH_BUTTON,
        className: 'composite-dropdown-with-button',
        childrenTypes: [
            childType.once(controlNames.DROP_DOWN),
            childType.once(controlNames.ACTION_WITH_SYMBOL)
        ]
    },
    DropDownWithButtonLabeled: {
        displayName: compositeNames.DROP_DOWN_WITH_BUTTON_LABELED,
        className: 'composite-dropdown-with-button-labeled composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.once(controlNames.TEXT_LABEL),
            childType.once(compositeNames.DROP_DOWN_WITH_BUTTON)
        ]
    },
    DropDownWithColorInput: {
        displayName: compositeNames.DROP_DOWN_WITH_COLOR_INPUT,
        className: 'composite-dropdown-with-color-input',
        childrenTypes: [
            childType.once(controlNames.FONT_FAMILY_PICKER, controlNames.DROP_DOWN),
            childType.once(controlNames.COLOR_PICKER_INPUT)
        ]
    },
    DropDownWithColorInputLabeled: {
        displayName: compositeNames.DROP_DOWN_WITH_COLOR_INPUT_LABELED,
        className: 'composite-dropdown-with-color-input-labeled',
        childrenTypes: [
            childType.once(controlNames.TEXT_LABEL),
            childType.once(compositeNames.DROP_DOWN_WITH_COLOR_INPUT)
        ]
    },
    LinkButtonWithColorInput: {
        displayName: compositeNames.LINK_BUTTON_WITH_COLOR_INPUT,
        className: 'composite-link-button-with-color-input',
        childrenTypes: [
            childType.once(controlNames.ACTION_WITH_SYMBOL),
            childType.once(controlNames.COLOR_PICKER_INPUT)
        ]
    },
    LinkButtonWithColorInputLabeled: {
        displayName: compositeNames.LINK_BUTTON_WITH_COLOR_INPUT_LABELED,
        className: 'composite-link-button-with-color-input-labeled',
        childrenTypes: [
            childType.once(controlNames.TEXT_LABEL),
            childType.once(compositeNames.LINK_BUTTON_WITH_COLOR_INPUT)
        ]
    },
    DynamicFieldLabeled: {
        displayName: compositeNames.DYNAMIC_FIELD_LABELED,
        className: 'composite-dynamic-field-labeled composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.DYNAMIC_FIELD)
        ]
    },
    DynamicFieldWithDescription: {
        displayName: compositeNames.DYNAMIC_FIELD_WITH_DESCRIPTION,
        className: 'composite-dynamic-field-with-description',
        childrenTypes: [
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.DYNAMIC_FIELD)
        ]
    },
    EditableURLLabeled: {
        displayName: compositeNames.EDITABLE_URL_LABELED,
        className: 'composite-editable-url-labeled',
        childrenTypes: [
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.EDITABLE_URL)
        ]
    },
    FontFamilyPickerLabeled: {
        displayName: compositeNames.FONT_FAMILY_PICKER_LABELED,
        className: 'composite-dropdown-labeled composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.FONT_FAMILY_PICKER)
        ]
    },
    GooglePreviewWithRichText: {
        displayName: compositeNames.GOOGLE_PREVIEW_WITH_RICH_TEXT,
        className: 'composite-google-preview-with-rich-text',
        childrenTypes: [childType.once(controlNames.RICH_TEXT), childType.once(controlNames.GOOGLE_PREVIEW)]
    },
    HorizontalTabsLabeled: {
        displayName: compositeNames.HORIZONTAL_TABS_LABELED,
        className: 'composite-horizontal-tabs-labeled',
        childrenTypes: [childType.once(controlNames.TEXT_LABEL), childType.once(controlNames.HORIZONTAL_TABS)]
    },
    VerticalTabsLabeled: {
        displayName: compositeNames.VERTICAL_TABS_LABELED,
        className: 'composite-vertical-tabs-labeled',
        childrenTypes: [childType.once(controlNames.TEXT_LABEL), childType.once(controlNames.VERTICAL_TABS)]
    },
    RadioButtonsWithIllustration: {
        displayName: compositeNames.RADIO_BUTTONS_WITH_ILLUSTRATION,
        className: 'composite-radio-buttons-with-illustration',
        childrenTypes: [childType.once(controlNames.RADIO_BUTTONS_WITH_ILLUSTRATION)]
    },
    PanelContent: {
        displayName: compositeNames.PANEL_CONTENT,
        className: 'composite-panel-content',
        childrenTypes: childType.any()
    },
    PanelContentAsymmetric: {
        displayName: compositeNames.PANEL_CONTENT_ASYMMETRIC,
        className: 'composite-panel-content-asymmetric',
        childrenTypes: childType.any()
    },
    PopupSmallSize: {
        displayName: compositeNames.POPUP_SMALL_SIZE,
        className: 'composite-popup composite-popup-small-size',
        childrenTypes: [
            childType.once(controlNames.PANEL_HEADER),
            childType.once(compositeNames.PANEL_CONTENT),
            childType.optional(
                compositeNames.ACTION_SET_HORIZONTAL,
                compositeNames.ACTION_SET_HORIZONTAL_SPACED,
                compositeNames.ACTION_SET_VERTICAL
            )
        ]
    },
    PopupMediumSize: {
        displayName: compositeNames.POPUP_MEDIUM_SIZE,
        className: 'composite-popup composite-popup-medium-size',
        childrenTypes: [
            childType.once(compositeNames.BIG_PANEL_HEADER, compositeNames.BIG_PANEL_HEADER_WITH_ILLUSTRATION),
            childType.once(compositeNames.PANEL_CONTENT),
            childType.optional(
                compositeNames.ACTION_SET_HORIZONTAL,
                compositeNames.ACTION_SET_HORIZONTAL_SPACED,
                compositeNames.ACTION_SET_VERTICAL
            )

        ]
    },
    PopupLargeSize: {
        displayName: compositeNames.POPUP_LARGE_SIZE,
        className: 'composite-popup composite-popup-large-size',
        childrenTypes: [
            childType.once(compositeNames.BIG_PANEL_HEADER, compositeNames.BIG_PANEL_HEADER_WITH_ILLUSTRATION),
            childType.once(compositeNames.PANEL_CONTENT),
            childType.optional(
                compositeNames.ACTION_SET_HORIZONTAL,
                compositeNames.ACTION_SET_HORIZONTAL_SPACED,
                compositeNames.ACTION_SET_VERTICAL
            )

        ]
    },
    Preloader: {
        displayName: compositeNames.PRELOADER,
        className: 'composite-preloader',
        childrenTypes: [
            childType.once(controlNames.PRELOADER),
            childType.optional(controlNames.TEXT_LABEL)
        ],
        extraStyleProps: {
            height: PropTypes.number
        }
    },
    ProgressBar: {
        displayName: compositeNames.PROGRESS_BAR,
        className: 'composite-progress-bar',
        childrenTypes: [
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.PROGRESS_BAR),
            childType.once(controlNames.TEXT_LABEL)
        ]
    },
    ProgressBarWithIllustration: {
        displayName: compositeNames.PROGRESS_BAR_WITH_ILLUSTRATION,
        className: 'composite-progress-bar-with-illustration',
        childrenTypes: [
            childType.once(controlNames.ILLUSTRATION),
            childType.once(compositeNames.PROGRESS_BAR)
        ]
    },
    ProgressBarWithIllustrationVertical: {
        displayName: compositeNames.PROGRESS_BAR_WITH_ILLUSTRATION_VERTICAL,
        className: 'composite-progress-bar-with-illustration-vertical',
        childrenTypes: [
            childType.once(controlNames.ILLUSTRATION),
            childType.once(compositeNames.PROGRESS_BAR)
        ]
    },
    PromotionalList: {
        displayName: compositeNames.PROMOTIONAL_LIST,
        className: 'composite-promotional-list',
        childrenTypes: [childType.once(controlNames.PROMOTIONAL_LIST)]
    },
    RichTextWithIllustration: {
        displayName: compositeNames.RICH_TEXT_WITH_ILLUSTRATION,
        className: 'composite-rich-text-with-illustration',
        childrenTypes: [
            childType.optional(controlNames.ILLUSTRATION),
            childType.once(compositeNames.RICH_TEXT_LABELED, compositeNames.RICH_TEXT)
        ]
    },
    RichTextWithIllustrationVertical: {
        displayName: compositeNames.RICH_TEXT_WITH_ILLUSTRATION_VERTICAL,
        className: 'composite-rich-text-with-illustration-vertical',
        childrenTypes: [
            childType.optional(controlNames.ILLUSTRATION),
            childType.once(compositeNames.RICH_TEXT_LABELED, compositeNames.RICH_TEXT)
        ]
    },
    RadioButtonsLabeled: {
        displayName: compositeNames.RADIO_BUTTONS_LABELED,
        className: 'composite-radio-buttons-labeled composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.RADIO_BUTTONS)
        ]
    },
    RadioButtons: {
        displayName: compositeNames.RADIO_BUTTONS,
        className: 'composite-radio-buttons',
        childrenTypes: [childType.once(controlNames.RADIO_BUTTONS)]
    },
    RichText: {
        displayName: compositeNames.RICH_TEXT,
        className: 'composite-rich-text',
        childrenTypes: [childType.once(controlNames.RICH_TEXT)]
    },
    RichTextLabeled: {
        displayName: compositeNames.RICH_TEXT_LABELED,
        className: 'composite-rich-text-labeled',
        childrenTypes: [
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.RICH_TEXT)
        ]
    },
    TextInput: {
        displayName: compositeNames.TEXT_INPUT,
        className: 'composite-text-input',
        childrenTypes: [
            childType.once(controlNames.TEXT_INPUT, controlNames.NUMERIC_INPUT)
        ]
    },
    TextInputLabeled: {
        displayName: compositeNames.TEXT_INPUT_LABELED,
        className: 'composite-text-input-labeled composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.once(controlNames.TEXT_LABEL, controlNames.DROP_DOWN),
            childType.once(controlNames.TEXT_INPUT, controlNames.NUMERIC_INPUT)
        ]
    },
    TextInputMultilineWithButton: {
        displayName: compositeNames.TEXT_INPUT_MULTI_LINE_WITH_BUTTON,
        className: 'composite-text-input-multiline-with-button',
        childrenTypes: [
            childType.once(controlNames.TEXT_INPUT_MULTI_LINE_WITH_BUTTON)
        ]
    },
    TextInputMultilineWithButtonLabeled: {
        displayName: compositeNames.TEXT_INPUT_MULTI_LINE_WITH_BUTTON_LABELED,
        className: 'composite-text-input-multiline-with-button-labeled composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.TEXT_INPUT_MULTI_LINE_WITH_BUTTON)
        ]
    },
    TextInputMultiline: {
        displayName: compositeNames.TEXT_INPUT_MULTI_LINE,
        className: 'composite-text-input-multiline',
        childrenTypes: [
            childType.once(controlNames.TEXT_INPUT_MULTI_LINE)
        ]
    },
    TextInputMultilineLabeled: {
        displayName: compositeNames.TEXT_INPUT_MULTI_LINE_LABELED,
        className: 'composite-text-input-multiline-labeled composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.TEXT_INPUT_MULTI_LINE)
        ]
    },
    ToggleSwitch: {
        displayName: compositeNames.TOGGLE_SWITCH,
        className: 'composite-toggle-switch',
        childrenTypes: [childType.once(controlNames.TOGGLE_SWITCH)]
    },
    ToggleSwitchWithSelectiveInput: {
        displayName: compositeNames.TOGGLE_SWITCH_WITH_SELECTIVE_INPUT,
        className: 'composite-toggle-switch-with-selective-input',
        childrenTypes: [
            childType.once(compositeNames.TOGGLE_SWITCH),
            childType.optional(compositeNames.TEXT_INPUT)
        ]
    },
    SecondaryMedia: {
        displayName: compositeNames.SECONDARY_MEDIA,
        className: 'composite-secondary-media',
        childrenTypes: [
            childType.once(controlNames.SECONDARY_MEDIA_IMAGE),
            childType.once(controlNames.BUTTON),
            childType.optional(controlNames.BUTTON, controlNames.CONTEXT_MENU)
        ]
    },
    SecondaryMediaLabeled: {
        displayName: compositeNames.SECONDARY_MEDIA_LABELED,
        className: 'composite-secondary-media-labeled composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.SECONDARY_MEDIA_IMAGE),
            childType.once(controlNames.BUTTON),
            childType.optional(controlNames.BUTTON, controlNames.CONTEXT_MENU)
        ]
    },
    SectionDividerWithInfoIcon: {
        displayName: compositeNames.SECTION_DIVIDER_WITH_INFO_ICON,
        className: 'composite-section-divider-with-info-icon composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.once(controlNames.SECTION_DIVIDER)
        ]
    },
    SliderLabeled: {
        displayName: compositeNames.SLIDER_LABELED,
        className: 'composite-slider-labeled composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.SLIDER)
        ]
    },
    Slider: {
        displayName: compositeNames.SLIDER,
        className: 'composite-slider',
        childrenTypes: [childType.once(controlNames.SLIDER)]
    },
    ColorPickerInput: {
        displayName: compositeNames.COLOR_PICKER_INPUT,
        className: 'composite-color-picker-input',
        childrenTypes: [
            childType.once(controlNames.OPACITY_PICKER, controlNames.SLIDER),
            childType.once(controlNames.COLOR_PICKER_INPUT)
        ]
    },
    ColorPickerInputLabeled: {
        displayName: compositeNames.COLOR_PICKER_INPUT_LABELED,
        className: 'composite-color-picker-input-labeled',
        childrenTypes: [
            childType.once(controlNames.TEXT_LABEL),
            childType.once(compositeNames.COLOR_PICKER_INPUT)
        ]
    },
    ColorSelectLabeled: {
        displayName: compositeNames.COLOR_SELECT_LABELED,
        className: 'composite-color-select-labeled',
        childrenTypes: [
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.COLOR_PICKER_INPUT)
        ]
    },
    Thumbnails: {
        displayName: compositeNames.THUMBNAILS,
        className: 'composite-thumbnails composite-with-info-icon',
        childrenTypes: [
            childType.optional(controlNames.INFO_ICON),
            childType.optional(controlNames.TEXT_LABEL),
            childType.once(controlNames.THUMBNAILS)
        ]
    },
    ThumbnailButtonGroup: {
        displayName: compositeNames.THUMBNAIL_BUTTON_GROUP,
        className: 'composite-thumbnail-button-group',
        childrenTypes: [
            childType.optional(controlNames.TEXT_LABEL),
            childType.once(controlNames.THUMBNAIL_BUTTON_GROUP, controlNames.THUMBNAIL_COLOR_INPUT)
        ]
    },
    TextFormatting: {
        displayName: compositeNames.TEXT_FORMATTING,
        className: 'composite-text-formatting',
        childrenTypes: [
            childType.optional(controlNames.TEXT_BOLD_BUTTON),
            childType.optional(controlNames.TEXT_ITALIC_BUTTON),
            childType.optional(controlNames.TEXT_UNDERLINE_BUTTON)
        ]
    },
    UploadButton: {
        displayName: compositeNames.UPLOAD_BUTTON,
        className: 'composite-upload-button',
        childrenTypes: [
            childType.once(controlNames.UPLOAD_BUTTON)
        ]
    },
    UploadButtonLabeled: {
        displayName: compositeNames.UPLOAD_BUTTON_LABELED,
        className: 'composite-upload-button-labeled',
        childrenTypes: [
            childType.once(controlNames.TEXT_LABEL),
            childType.once(controlNames.UPLOAD_BUTTON)
        ]
    },
    NumericInput: {
        displayName: compositeNames.NUMERIC_INPUT,
        className: 'composite-numeric-input',
        childrenTypes: [
            childType.once(controlNames.NUMERIC_INPUT)
        ]
    }
}
