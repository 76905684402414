'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const compose = require('../hoc/compose')
const template = require('./closeButton.rt')
const defaultSymbol = require('../panels/headerCloseButton.svg.js')
const displayNames = require('./displayNames')

const CloseButton = template

CloseButton.displayName = displayNames.CLOSE_BUTTON
CloseButton.propTypes = {
    onClick: PropTypes.func
}
CloseButton.defaultProps = {
    symbol: React.createElement(defaultSymbol)
}

module.exports = compose(CloseButton)
