'use strict';
var React = require('react');
var _ = require('lodash');
var textInput = require('../textInput');
var applyChangeButton = require('../button');
var tooltip = require('../tooltip');
module.exports = function () {
    return React.createElement('div', { 'className': this.props.getClassName('sbdl-text-edit') + (this.isInvalid() ? ' invalid' : '') }, React.createElement(tooltip, {
        'disabled': !this.isInvalid(),
        'key': 'invalidMessage',
        'isOpen': true,
        'content': this.state.invalidMessage,
        'shouldTranslate': this.props.shouldTranslateInvalidMessage
    }, React.createElement(textInput, {
        'value': this.state.value,
        'focus': this.props.autoFocus,
        'onChange': this.onInputTextChange,
        'maxLength': this.props.maxLength,
        'placeholder': this.props.placeholder,
        'shouldTranslate': false
    })), React.createElement(applyChangeButton, {
        'onClick': this.applyChanges,
        'disabled': this.isInvalid()
    }, 'Done'));
};