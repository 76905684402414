'use strict'
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const template = require('./imageAndTextTemplate.rt')
const displayNames = require('./displayNames')

const ImageAndTextTemplate = template

ImageAndTextTemplate.displayName = displayNames.IMAGE_AND_TEXT_TEMPLATE
ImageAndTextTemplate.propTypes = {
    image: PropTypes.element,
    title: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

module.exports = compose(ImageAndTextTemplate)
