'use strict'
const React = require('react')
const _ = require('lodash')
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const template = require('./cornerRadiusInput.rt')
const displayNames = require('./displayNames')
const numericInputHelper = require('../util/numericInputHelpers')

const CORNERS = {
    topLeft: 0,
    topRight: 1,
    bottomRight: 2,
    bottomLeft: 3
}
const getCornerIndex = corner => CORNERS[corner]

const getValuesArray = value => {
    const valuesArrayNoUnits = value.replace(/px/g, '').split(' ').map(Number)
    return valuesArrayNoUnits.length === 1 ? _.fill(Array(4), valuesArrayNoUnits[0]) : valuesArrayNoUnits
}

class CornerRadiusInput extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            locked: this.props.isInitiallyLocked
        }

        this.getClassName = () => `${this.props.getClassName('control-corner-radius-input')} ${this.state.locked ? 'locked' : 'unlocked'}`

        this.handleLinkClick = value => {
            this.setState({locked: value})
        }

        this.getCornerValue = corner => {
            const values = getValuesArray(this.props.value)
            return values[getCornerIndex(corner)]
        }

        this.getValidValue = (value, corner) => {
            const prevValue = this.getCornerValue(corner)
            const {min, max} = this.props
            return numericInputHelper.getValidNumericValue(value, prevValue, min, max)
        }

        this.handleCornerChange = (corner, e) => {
            const newCornerValue = e.target.value
            const diff = newCornerValue - this.getCornerValue(corner)
            const newValue = this.calcNewVal(diff, corner)
            this.props.onChange(newValue)
        }


        this.calcNewVal = (diff, corner) => {
            const value = this.props.value
            return this.state.locked ? this.calLockedVal(value, diff) : this.calcUnlockedVal(value, diff, corner)
        }

        this.calLockedVal = (value, diff) => {
            const cornerByIndex = _.invert(CORNERS)
            return getValuesArray(value).map((v, i) => {
                const corner = cornerByIndex[i]
                return this.getValidValue(this.getCornerValue(corner) + diff, corner) + 'px'
            }).join(' ')
        }

        this.calcUnlockedVal = (value, diff, corner) => _(value).thru(getValuesArray)
            .assign({[getCornerIndex(corner)]: this.getValidValue(this.getCornerValue(corner) + diff, corner)})
            .map(v => `${v}px`)
            .join(' ')
    }


    render() {
        return template.call(this)
    }
}

CornerRadiusInput.displayName = displayNames.CORNER_RADIUS_INPUT
CornerRadiusInput.propTypes = {
    isInitiallyLocked: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
}
CornerRadiusInput.defaultProps = {
    isInitiallyLocked: true,
    max: 999,
    min: 0
}
module.exports = compose(CornerRadiusInput)
