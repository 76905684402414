'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const compose = require('../../hoc/compose')
const colorUtil = require('../../util/color')
const template = require('./palette.rt')
const displayNames = require('../displayNames')

const getDistanceToWhite = hexColor => _(hexColor)
    .thru(colorUtil.hexToHsb)
    .thru(colorUtil.getDistanceToWhite)
    .value()

const DEFAULT_COLORS = _.unzip([
    ['#FFFFFF', '#D9F0FD', '#ADC6F8', '#C4AEDD', '#E5FAD1'],
    ['#E8E6E6', '#BEE5FB', '#83A8F0', '#9C7FBA', '#CAE5AF'],
    ['#C7C7C7', '#7FCCF7', '#155DE9', '#663898', '#9BCB6C'],
    ['#999997', '#40667C', '#0E3E9B', '#442565', '#4E6636'],
    ['#414141', '#213D4D', '#071F4E', '#221333', '#27331B']
])

class Palette extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            colorIndex: _.indexOf(this.getColorsByPalette(this.props.palette), this.props.value)
        }

        this.onChange = (color, colorIndex) => {
            this.setState({colorIndex}, () => {
                this.props.onChange(color, colorIndex)
            })
        }
    }

    getDistanceToWhite(hex) {
        return getDistanceToWhite(hex)
    }
    getColorsByPalette(palette) {
        return _(palette).unzip().flatten().value()
    }

    componentWillReceiveProps(nextProps) {
        const palette = this.getColorsByPalette(nextProps.palette)
        const currentColor = palette[this.state.colorIndex]
        if (nextProps.value !== currentColor) {
            this.setState({
                colorIndex: _.indexOf(palette, nextProps.value)
            })
        }
    }

    render() {
        return template.call(this)
    }
}

Palette.displayName = displayNames.PALETTE
Palette.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onMainColorChange: PropTypes.func,
    onColorEnter: PropTypes.func,
    onColorLeave: PropTypes.func,
    palette: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    mainColors: PropTypes.arrayOf(PropTypes.string)
}
Palette.defaultProps = {
    palette: DEFAULT_COLORS,
    onColorEnter: _.noop,
    onColorLeave: _.noop,
    onChange: _.noop,
    onMainColorChange: _.noop
}

module.exports = compose(Palette)
