'use strict';
var React = require('react');
var _ = require('lodash');
module.exports = function () {
    return React.createElement('label', {
        'className': this.props.getClassName('control-boolean') + (this.props.disabled ? ' disabled' : ''),
        'data-aid': this.props.automationId
    }, React.createElement('input', {
        'type': 'checkbox',
        'className': this.props.getClassName('input input-' + this.props.name, this.props.innerInputClass || ''),
        'checked': this.props.value,
        'disabled': this.props.disabled,
        'data-indeterminate': this.props.indeterminate,
        'onChange': this.handleChange
    }), this.props.children);
};