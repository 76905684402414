'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const compose = require('../../hoc/compose')
const template = require('./resetColor.rt')
const displayNames = require('../displayNames')

class ResetColor extends React.Component {
    constructor(props) {
        super(props)

        this.initialColor = this.props.value
        this.resetColor = () => { this.props.onChange(this.initialColor) }
    }
    render() {
        return template.call(this)
    }
}

ResetColor.displayName = displayNames.RESET_COLOR
ResetColor.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
}
ResetColor.defaultProps = {}

module.exports = compose(ResetColor)
