'use strict'

module.exports = {
    ACTION_WITH_SYMBOL: 'ActionWithSymbol',
    ANGLE_INPUT: 'AngleInput',
    ADD_THUMBNAIL_BUTTON: 'AddThumbnailButton',
    BACK_BUTTON: 'BackButton',
    BUBBLE: 'Bubble',
    BUTTON: 'Button',
    BUTTON_CONTENT: 'ButtonContent',
    BUTTONS_GROUP: 'ButtonsGroup',
    CLOSE_BUTTON: 'CloseButton',
    CHECKBOX: 'Checkbox',
    CHOOSE_COLOR_DIALOG: 'ChooseColorDialog',
    COLOR_FORMAT: 'ColorFormat',
    COLOR_SPACE: 'ColorSpace',
    COLOR_PICKER_INPUT: 'ColorPickerInput',
    CONTEXT_MENU: 'ContextMenu',
    CONTEXT_MENU_ACTION: 'ContextMenuAction',
    CONTEXT_MENU_CONTENT: 'ContextMenuContent',
    CONTEXTUAL_DIVIDER: 'ContextualDivider',
    CORNER: 'Corner',
    CORNER_RADIUS_INPUT: 'CornerRadiusInput',
    DATE_PICKER: 'DatePicker',
    DATE_PICKER_INPUT: 'DatePickerInput',
    DIVIDER: 'Divider',
    DOCK: 'Dock',
    DOCK_DIRECTION: 'DockDirection',
    DRILL_IN_LIST_ITEM: 'DrillInListItem',
    DROP_DOWN: 'DropDown',
    DROP_DOWN_BASE: 'DropDownBase',
    DROP_DOWN_BASE_OPTIONS: 'DropDownBaseOptions',
    DROP_DOWN_DETAILED_OPTION: 'DropDownDetailedOption',
    DROP_DOWN_OPTION: 'DropDownOption',
    DROP_DOWN_STICKY_FOOTER: 'DropDownStickyFooter',
    DROP_DOWN_THEME_OPTION: 'DropDownThemeOption',
    DYNAMIC_FIELD: 'DynamicField',
    EDITABLE_URL: 'EditableURL',
    FONT_FAMILY_PICKER: 'FontFamilyPicker',
    GOOGLE_PREVIEW: 'GooglePreview',
    HELP_BUTTON: 'HelpButton',
    HORIZONTAL_TABS: 'HorizontalTabs',
    ILLUSTRATION: 'Illustration',
    RADIO_BUTTONS_WITH_ILLUSTRATION: 'RadioButtonsWithIllustration',
    IMAGE_AND_TEXT_TEMPLATE: 'ImageAndTextTemplate',
    INFO_ICON: 'InfoIcon',
    MY_COLORS: 'MyColors',
    NUMERIC_INPUT: 'NumericInput',
    OPACITY_PICKER: 'OpacityPicker',
    PALETTE: 'Palette',
    PALETTE_DIALOG: 'PaletteDialog',
    PALETTE_PICKER: 'PalettePicker',
    PALETTE_PICKER_DIALOG: 'PalettePickerDialog',
    PANEL_HEADER: 'PanelHeader',
    PRELOADER: 'Preloader',
    PROGRESS_BAR: 'ProgressBar',
    PROMOTIONAL_LIST: 'PromotionalList',
    RADIO_BUTTON: 'RadioButton',
    RADIO_BUTTONS: 'RadioButtons',
    RESET_COLOR: 'ResetColor',
    RICH_TEXT: 'RichText',
    SECONDARY_MEDIA_IMAGE: 'SecondaryMediaImage',
    SECTION_DIVIDER: 'SectionDivider',
    SLIDER: 'Slider',
    SORT_BY_DRAG_LIST: 'SortByDragList',
    SORT_BY_DRAG_LIST_ITEM_ROW_BASE: 'SortByDragListItemRowBase',
    SORT_BY_DRAG_LIST_ITEM_ROW: 'SortByDragListItemRow',
    SORT_BY_DRAG_LIST_CUSTOM_ITEM_ROW: 'SortByDragListCustomItemRow',
    SORT_BY_DRAG_LIST_TEXT_EDIT: 'SortByDragListTextEdit',
    Symbol: 'Symbol',
    TAB: 'Tab',
    TEXT_FORMATTING_TOGGLE_BUTTON: 'textFormattingToggleButton',
    TEXT_BOLD_BUTTON: 'TextBoldButton',
    TEXT_COLOR_BUTTON: 'TextColorButton',
    TEXT_ITALIC_BUTTON: 'TextItalicButton',
    TEXT_UNDERLINE_BUTTON: 'TextUnderlineButton',
    TEXT_INPUT: 'TextInput',
    TEXT_INPUT_MULTI_LINE: 'TextInputMultiline',
    TEXT_INPUT_MULTI_LINE_WITH_BUTTON: 'TextInputMultilineWithButton',
    TEXT_LABEL: 'TextLabel',
    TEXT_LABEL_BASE: 'TextLabelBase',
    TEXT_LINK_BUTTON: 'TextLinkButton',
    THUMBNAIL: 'Thumbnail',
    THUMBNAIL_BUTTON_GROUP: 'thumbnailButtonGroup',
    THUMBNAIL_COLOR_INPUT: 'ThumbnailColorInput',
    THUMBNAILS: 'Thumbnails',
    TOGGLE: 'Toggle',
    TOGGLE_SWITCH: 'ToggleSwitch',
    TOOLTIP: 'Tooltip',
    TOOLTIP_ON_ELLIPSIS: 'TooltipOnEllipsis',
    UPLOAD_BUTTON: 'UploadButton',
    UPLOAD_BUTTON_STATUS_MESSAGE: 'UploadButtonStatusMessage',
    VERTICAL_TABS: 'VerticalTabs'
}
