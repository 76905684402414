'use strict';
var React = require('react');
var _ = require('lodash');
var symbol = require('./arrowDown.svg.js');
module.exports = function () {
    return React.createElement('div', {
        'data-aid': this.props.automationId ? this.props.automationId + '_options_container' : null,
        'className': 'dropdown-options-container ' + (this.props.optionsContainerClassName || '')
    }, React.createElement('div', {
        'className': 'dropdown-options',
        'ref': 'options',
        'style': this.props.optionsStyle,
        'onMouseLeave': this.props.registerLastWindowScrollPosition,
        'key': 'options'
    }, this.props.displayArrow && this.props.arrows.up ? React.createElement('div', {
        'className': 'dropdown-arrow-up',
        'onMouseEnter': this.props.scrollOptions.bind(this, -1),
        'onMouseLeave': this.props.cancelOptionsScroll,
        'key': 'arrowUp'
    }, React.createElement(symbol, {})) : null, React.createElement('div', {
        'className': 'dropdown-options-inner',
        'ref': 'optionsInner',
        'style': this.props.optionsInnerStyle,
        'onScroll': this.props.onScroll
    }, this.props.options), this.props.displayArrow && this.props.arrows.down ? React.createElement('div', {
        'className': 'dropdown-arrow-down',
        'onMouseEnter': this.props.scrollOptions.bind(this, 1),
        'onMouseLeave': this.props.cancelOptionsScroll,
        'key': 'arrowDown'
    }, React.createElement(symbol, {})) : null), this.props.blockClickOutside ? React.createElement('div', {
        'className': 'block-click-outside',
        'ref': 'blockClickOutside',
        'onClick': this.props.onClickOutside,
        'key': 'blockClick'
    }) : null);
};