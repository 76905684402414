'use strict'
/* eslint-disable */
const React = require('react')
module.exports = function () {
    return React.createElement(
        'svg',
        { width: '8', height: '15', viewBox: '0 0 8 15' },
        React.createElement(
            'defs',
            null,
            React.createElement('path', { id: 'a1', 'fillRule': 'evenodd', d: 'M.83 15L8 7.5.83 0 0 .87 6.34 7.5 0 14.13l.83.87z' })
        ),
        React.createElement('use', { xlinkHref: '#a1', fill: '#FFF' }),
        React.createElement('use', { xlinkHref: '#a1', fill: '#3799EB' })
    )
}
