'use strict';
var React = require('react');
var _ = require('lodash');
var textLabel = require('./textLabel');
module.exports = function (props, context) {
    return React.createElement('div', {
        'className': props.getClassName('add-thumbnail-button'),
        'onClick': props.onClick
    }, React.createElement('div', {
        'className': 'add-thumbnail-symbol-container',
        'style': props.style
    }, React.createElement('div', {
        'className': 'add-thumbnail-symbol',
        'style': {
            width: props.symbolSize,
            height: props.symbolSize
        }
    }, React.createElement('div', { 'className': 'horizontal-line' }), React.createElement('div', { 'className': 'vertical-line' }))), props.label ? React.createElement(textLabel, {
        'key': 'label',
        'className': 'add-thumbnail-label',
        'value': props.label,
        'type': props.labelType,
        'shouldTranslate': props.shouldTranslate
    }) : null);
};