'use strict';

var React = require('react');
module.exports = function () {
  return React.createElement(
    'svg',
    { width: '10', height: '10', viewBox: '0 0 10 10', className: 'plus-symbol' },
    React.createElement('path', { d: 'M4 .015h2V10H4V.014z' }),
    React.createElement('path', { d: 'M.015 4H10v2H.015V4z' })
  );
};