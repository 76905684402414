'use strict'
const _ = require('lodash')
const PropTypes = require('prop-types')
const React = require('react')
const compose = require('../hoc/compose')
const template = require('./textInputMultilineWithButton.rt')
const commonPropTypes = require('../common/propTypes')
const displayNames = require('./displayNames')

class TextInputMultilineWithButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {isInputFocused: false}
        this.onInputFocus = () => this.setState({isInputFocused: true})
        this.onInputBlur = () => this.setState({isInputFocused: false})
        this.getInputProps = () => _.assign({}, this.props, {
            onBlur: this.onInputBlur,
            onFocus: this.onInputFocus
        })
    }

    render() {
        return template.call(this)
    }
}

TextInputMultilineWithButton.displayName = displayNames.TEXT_INPUT_MULTI_LINE_WITH_BUTTON

TextInputMultilineWithButton.propTypes = _.defaults(
    {},
    commonPropTypes.INPUT_PROP_TYPES,
    commonPropTypes.VALIDATED_PROP_TYPES, {
        buttonLabel: PropTypes.string.isRequired
    }
)

module.exports = compose(TextInputMultilineWithButton)
