'use strict';
var React = require('react');
var _ = require('lodash');
var TagListInput = require('./tagListInput');
var SectionDivider = require('../sectionDivider');
var ItemCheckbox = require('./itemCheckbox');
function repeatItem1(itemList, section, sectionIndex, item, itemIndex) {
    return React.createElement(ItemCheckbox, {
        'key': item.value,
        'item': item,
        'onChange': this.onItemCheckedStateChanged,
        'isRoot': true,
        'filterText': this.state.filterText,
        'isExpanded': this.isExpanded,
        'expandClicked': this.expandClicked
    });
}
function repeatSection2(itemList, section, sectionIndex) {
    return React.createElement.apply(this, [
        'div',
        { 'key': section.value },
        section.label !== this.props.allItems && section.items && section.items.length > 0 ? React.createElement(SectionDivider, { 'key': section.value }, section.label) : null,
        _.map(section.items, repeatItem1.bind(this, itemList, section, sectionIndex))
    ]);
}
function scopeItemList3() {
    var itemList = this.getItemList();
    return React.createElement('div', { 'className': 'multi-level-checkbox' }, React.createElement(TagListInput, {
        'value': this.getTagList(itemList),
        'onItemCheckedStateChanged': this.onItemCheckedStateChanged,
        'maxHeight': this.props.maxHeight,
        'filterText': this.state.filterText,
        'onFilterChanged': this.onFilterChanged,
        'showTreeView': this.showTreeView,
        'removeLastTag': this.removeLastTag
    }), this.state.treeViewOpen ? React.createElement.apply(this, [
        'div',
        {
            'className': 'tree-view',
            'key': 'MultiPleCheckboxTreeView'
        },
        itemList.length > 0 ? _.map(itemList, repeatSection2.bind(this, itemList)) : null,
        this.areAllFiltered(itemList) ? React.createElement('div', {
            'className': 'not-found-wrapper',
            'key': 'NoItemFound'
        }, React.createElement('p', { 'className': 'not-found-text' }, this.props.noItemsFoundText)) : null
    ]) : null);
}
module.exports = function () {
    return scopeItemList3.apply(this, []);
};