'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const compose = require('../hoc/compose')
const displayNames = require('./displayNames')
const TooltipOnEllipsis = require('./tooltipOnEllipsis')

const getStyleFromProps = props => props.wasVisible ? props.wasVisibleStyle : {}

const DropDownOption = props => React.createElement('div', {
    className: props.getClassName('control-dropdown-option-content'),
    value: props.value,
    key: props.key || props.value,
    disabled: props.disabled,
    style: props.wasVisibleStyle ? getStyleFromProps(props) : null,
    'data-aid': props.automationId
}, props.children && !props.isSelectedElement ? props.children : React.createElement(TooltipOnEllipsis, {closeOnMouseClick: true}, props.translateIfNeeded(props.label)))

DropDownOption.displayName = displayNames.DROP_DOWN_OPTION
DropDownOption.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired
    ]).isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    isSelectedElement: PropTypes.bool,
    wasVisible: PropTypes.bool,
    wasVisibleStyle: PropTypes.object
}

DropDownOption.defaultProps = {
    disabled: false
}

module.exports = compose(DropDownOption)
