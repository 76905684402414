'use strict'
const _ = require('lodash')
const PropTypes = require('prop-types')
const React = require('react')
const compose = require('../hoc/compose')
const displayNames = require('./displayNames')
const textTypes = require('./textTypes')

const TextLabel = props => {
    const classList = ['control-label-base']
    if (props.type) {
        classList.push(_.toLower(props.type))
    }
    if (props.disabled) {
        classList.push('disabled')
    }

    return React.createElement('span',
        {
            className: props.getClassName(classList.join(' ')),
            'data-aid': props.automationId
        },
        props.translateIfNeeded(props.value))
}

TextLabel.displayName = displayNames.TEXT_LABEL_BASE
TextLabel.propTypes = {
    value: PropTypes.string.isRequired,
    type: PropTypes.oneOf(textTypes.TYPES),
    disabled: PropTypes.bool
}

TextLabel.defaultProps = {
    disabled: false
}

module.exports = compose(TextLabel)
