'use strict';
var React = require('react');
var _ = require('lodash');
var ThumbnailButtonGroup = require('./thumbnailButtonGroup');
module.exports = function () {
    return React.createElement(ThumbnailButtonGroup, {
        'className': 'control-thumbnail-color-input',
        'options': this.getOptions(),
        'maxThumbsPerRow': this.props.maxThumbsPerRow
    });
};