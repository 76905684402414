'use strict';

var React = require('react');
module.exports = function () {
  return React.createElement(
    'svg',
    { xmlns: 'http://www.w3.org/2000/svg', preserveAspectRatio: 'xMidYMid', viewBox: '1.5 1.5 18 18', width: '18', height: '18' },
    React.createElement('circle', { cx: '10.5', cy: '10.5', r: '8' }),
    React.createElement('path', { d: 'M10.5 19.5a9 9 0 0 1-9-9 9 9 0 0 1 9-9 9 9 0 0 1 9 9 9 9 0 0 1-9 9zm-8-9c0 4.411 3.589 8 8 8s8-3.589 8-8-3.589-8-8-8-8 3.589-8 8zm10 5h-4l1-2v-3h-1l1-2h2v5l1 2zm-3-10h2v2h-2v-2z', fillRule: 'evenodd' })
  );
};