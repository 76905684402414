'use strict';
var React = require('react');
var _ = require('lodash');
var TextInputMultiline = require('./textInputMultiline');
var Button = require('./button');
module.exports = function () {
    return React.createElement('div', {
        'className': this.props.getClassName('control-text-input-multiline-with-button'),
        'data-aid': this.props.automationId
    }, React.createElement(TextInputMultiline, this.getInputProps()), this.state.isInputFocused ? React.createElement(Button, {
        'key': 'actionButton',
        'className': 'btn-md' + ' ' + _({
            'btn-md': true,
            'disabled': !this.props.value
        }).transform(function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' '),
        'disabled': !this.props.value
    }, this.props.buttonLabel) : null);
};