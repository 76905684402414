'use strict';
var React = require('react');
var _ = require('lodash');
var addressBarMiniToolbar = require('./icons/addressBarMiniToolbar.svg.js');
var baseButton = require('./button.js');
var cancelSymbol = require('./x.svg.js');
var okSymbol = require('./check.svg.js');
var TextInput = require('./textInput');
module.exports = function () {
    return React.createElement('div', {
        'data-aid': this.props.automationId,
        'className': this.getClassNames()
    }, React.createElement('div', { 'className': 'address-bar-container' }, React.createElement(addressBarMiniToolbar, {}), React.createElement('div', { 'className': 'url-container' }, React.createElement('span', { 'className': 'domain' }, this.props.domain), React.createElement(TextInput, {
        'defaultInputClass': 'input-editable-url',
        'focus': false,
        'isReadOnly': this.state.isReadOnly,
        'value': this.state.valueCandidate,
        'onBlur': this.handleBlur,
        'onKeyDown': this.handleKeyDown,
        'blurOnEscKey': false,
        'blurOnEnterKey': false,
        'autoSelect': this.props.autoSelect,
        'onChange': this.handleCandidateChange,
        'onValidationStatus': this.handleValidationStatusChange,
        'onFocus': this.handleFocus,
        'selectOnFocus': false,
        'shouldTranslate': this.props.shouldTranslate,
        'allowInvalidChange': true,
        'validator': this.props.validator,
        'isValid': this.props.isValid,
        'invalidMessage': this.props.invalidMessage,
        'syncDelay': this.props.syncDelay,
        'preventBlur': !this.state.isValid && !this.props.allowInvalidChange,
        'placeholder': this.props.placeholder
    }))), this.props.editButtons ? React.createElement('div', {
        'className': 'buttons-container',
        'key': 'buttonsContainer'
    }, !this.state.isReadOnly ? React.createElement(baseButton, {
        'className': 'btn-light btn-md btn-round editable-url-btn-cancel',
        'onClick': this.handleCancel,
        'key': 'cancel'
    }, React.createElement(cancelSymbol, {})) : null, !this.state.isReadOnly ? React.createElement(baseButton, {
        'disabled': !this.state.isValid && !this.props.allowInvalidChange,
        'className': 'btn-light btn-md btn-round editable-url-btn-apply',
        'onClick': this.handleConfirm,
        'key': 'ok'
    }, React.createElement(okSymbol, {})) : null, this.state.isReadOnly ? React.createElement(baseButton, {
        'className': 'btn-light btn-edit',
        'onClick': this.handleEdit,
        'key': 'edit'
    }, this.props.translateIfNeeded(this.props.editButtonText)) : null) : null);
};