'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const compose = require('../hoc/compose')
const textInput = require('./textInput')
const displayNames = require('./displayNames')
const numericInputHelper = require('../util/numericInputHelpers')
const UNITS = require('../constants/numericInputUnits')

const UP = 38
const DOWN = 40
const MAXIMUM_FRACTION_DIGITS = 21
const ALLOWED_FRACTION_DIGIT_NUMBERS = _.range(MAXIMUM_FRACTION_DIGITS)

const getNumber = value => {
    const isNumber = /^-?\d*\.?\d*$/.test(value)
    return isNumber ? Number(value) : null
}

class NumericInput extends React.Component {
    constructor(props) {
        super(props)

        this.getValidValue = value => {
            const {value: prevValue, shouldRoundByStep, step, min, max, fractionDigits} = this.props
            return numericInputHelper.getValidNumericValue(value, prevValue, min, max, shouldRoundByStep, step, fractionDigits)

        }

        this.state = {
            rawValue: this.getValidValue(props.value),
            showTooltip: false
        }

        this.handleChange = newValueAsString => {
            if (newValueAsString === this.state.rawValue) {
                return
            }

            const newValue = getNumber(newValueAsString)
            if (_.isNull(newValue)) {
                this.setState({showTooltip: true})
                return
            }

            const isTempValue = isNaN(newValue) || newValue < this.props.min || this.props.max < newValue
            this.setState({rawValue: newValueAsString, showTooltip: false},
                isTempValue ? _.noop : _.partial(this.props.onChange, newValue))
        }

        this.handleBlur = (e, isCanceled) => {
            if (!isCanceled) {
                const valueNumber = Number(this.state.rawValue)
                const fixedValue = this.getValidValue(valueNumber)
                this.handleChange(fixedValue)
            }
            this.props.onBlur(e, isCanceled)
        }

        this.handleKeyDown = ({keyCode}) => {
            if (!_.includes([UP, DOWN], keyCode)) {
                return
            }

            const step = this.props.step * (keyCode === UP ? 1 : -1)
            const nextValue = this.getValidValue(Number(this.state.rawValue) + step)
            this.handleChange(nextValue)
        }

        this.getTooltipProps = () => ({
            isOpen: this.state.showTooltip,
            content: this.props.numbersOnlyMessage,
            openOnMouseEnter: false,
            closeOnMouseLeave: false,
            shouldTranslate: this.props.shouldTranslate,
            onScroll: () => {this.setState({showTooltip: false})}
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.handleChange(String(nextProps.value))
        }
    }

    render() {
        return React.createElement(textInput, _.assign({}, this.props, {
            showInfoTooltip: !_.isEmpty(this.props.numbersOnlyMessage),
            infoTooltipProps: this.getTooltipProps(),
            className: this.props.getClassName('control-numeric-input' + (this.state.showTooltip ? ' invalid' : '')),
            value: this.state.rawValue,
            onChange: this.handleChange,
            inputTextSuffix: UNITS[this.props.unit],
            onBlur: this.handleBlur,
            onKeyDown: this.handleKeyDown,
            shouldTranslate: false
        }))
    }
}

NumericInput.displayName = displayNames.NUMERIC_INPUT
NumericInput.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    prefix: PropTypes.string,
    innerInputClass: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    focus: PropTypes.bool,
    onClick: PropTypes.func,
    focusOnPrefixClick: PropTypes.bool,
    shouldRoundByStep: PropTypes.bool,
    fractionDigits: PropTypes.oneOf(ALLOWED_FRACTION_DIGIT_NUMBERS),
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    unit: PropTypes.oneOf(Object.keys(UNITS)),
    numbersOnlyMessage: PropTypes.string
}

NumericInput.defaultProps = {
    onChange: _.noop,
    onBlur: _.noop,
    focusOnPrefixClick: true,
    shouldRoundByStep: true,
    fractionDigits: 2,
    min: 0,
    max: 100,
    step: 1
}

module.exports = compose(NumericInput)
