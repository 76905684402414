'use strict'
const PropTypes = require('prop-types')
const template = require('./itemRowBase.rt')
const displayNames = require('../displayNames')
const compose = require('../../hoc/compose')

const ItemRowBase = template

ItemRowBase.displayName = displayNames.SORT_BY_DRAG_LIST_ITEM_ROW_BASE
ItemRowBase.propTypes = {
    dragHandle: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    dragHandleEnabled: PropTypes.bool,
    dragHandleTooltipContent: PropTypes.string,
    isDragging: PropTypes.bool
}

ItemRowBase.defaultProps = {
    dragHandle: true,
    dragHandleEnabled: true
}

module.exports = compose(ItemRowBase)
