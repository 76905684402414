'use strict'
const _ = require('lodash')
const positionUtil = require('./position')

const {
    getViewportSize,
    getMaxAllowedBottom,
    getMaxAllowedRight,
    getRectMiddle
} = positionUtil

const CONSTS = {
    INDENT_FROM_EDGE: 12,
    TOOLTIP_PADDING: 6,
    ALIGNMENT: {
        TOP: 'TOP',
        LEFT: 'LEFT',
        BOTTOM: 'BOTTOM',
        RIGHT: 'RIGHT'
    }
}

const isVerticalAlignment = params => params.alignment === CONSTS.ALIGNMENT.TOP ||
                                      params.alignment === CONSTS.ALIGNMENT.BOTTOM

function getVRange(viewport, triggerRect, contentRect, params = {}) {
    params.alignment = params.alignment || CONSTS.ALIGNMENT.TOP
    const triggerRectBottom = triggerRect.y + triggerRect.height

    if (isVerticalAlignment(params)) {
        const min = triggerRect.y - contentRect.height - CONSTS.TOOLTIP_PADDING
        const max = triggerRectBottom + contentRect.height + CONSTS.TOOLTIP_PADDING
        const canFit = {
            top: min > CONSTS.INDENT_FROM_EDGE,
            bottom: max < getMaxAllowedBottom(viewport)
        }

        const vRangeTop = {
            min,
            max: triggerRect.y - CONSTS.TOOLTIP_PADDING
        }

        const vRangeBottom = {
            min: triggerRectBottom + CONSTS.TOOLTIP_PADDING,
            max
        }

        if (params.alignment === CONSTS.ALIGNMENT.TOP) {
            return canFit.bottom && !canFit.top ? vRangeBottom : vRangeTop
        }
        return !canFit.bottom && canFit.top ? vRangeTop : vRangeBottom
    }

    // TODO: Adjust to viewport
    const anchor = triggerRect.y + triggerRect.height / 2
    const isAbsolute = params.arrowDistance && params.arrowDistance < contentRect.height
    const firstContentPart = isAbsolute ? params.arrowDistance : contentRect.height / 2
    const lastContentPart = contentRect.height - firstContentPart
    return {
        min: anchor - firstContentPart,
        max: anchor + lastContentPart
    }
}

function getHRange(viewport, triggerRect, contentRect, params = {}) {
    // TODO: Remove code duplication
    params.alignment = params.alignment || CONSTS.ALIGNMENT.TOP
    const triggerRectRight = triggerRect.x + triggerRect.width

    if (!isVerticalAlignment(params)) {
        const min = triggerRect.x - contentRect.width - CONSTS.TOOLTIP_PADDING
        const max = triggerRectRight + contentRect.width + CONSTS.TOOLTIP_PADDING
        const canFit = {
            left: min > CONSTS.INDENT_FROM_EDGE,
            right: max < getMaxAllowedRight(viewport)
        }

        const vRangeLeft = {
            min,
            max: triggerRect.x - CONSTS.TOOLTIP_PADDING
        }

        const vRangeRight = {
            min: triggerRectRight + CONSTS.TOOLTIP_PADDING,
            max
        }

        if (params.alignment === CONSTS.ALIGNMENT.LEFT) {
            return canFit.right && !canFit.left ? vRangeRight : vRangeLeft
        }
        return !canFit.right && canFit.left ? vRangeLeft : vRangeRight
    }

    // TODO: Adjust to viewport
    const anchor = triggerRect.x + triggerRect.width / 2
    const isAbsolute = params.arrowDistance && params.arrowDistance < contentRect.width
    const firstContentPart = isAbsolute ? params.arrowDistance : contentRect.width / 2
    const lastContentPart = contentRect.width - firstContentPart
    return {
        min: anchor - firstContentPart,
        max: anchor + lastContentPart
    }
}

function getContentPosition(viewport, triggerRect, contentRect, params = {}) { //eslint-disable-line no-unused-vars
    const hRange = getHRange(...arguments)
    const vRange = getVRange(...arguments)

    const optionsPosition = {
        x: hRange.min,
        width: hRange.max - hRange.min,
        y: vRange.min,
        height: vRange.max - vRange.min
    }

    return _.mapValues(optionsPosition, Math.ceil)
}

// TODO: Refactor + naming
const getFinalPositions = (viewport, triggerRect, contentPosition, params = {}) => {
    const contentBottom = contentPosition.y + contentPosition.height
    const contentRight = contentPosition.x + contentPosition.width
    params.alignment = params.alignment || CONSTS.ALIGNMENT.TOP

    let arrow

    if (isVerticalAlignment(params)) {
        arrow = {
            x: getRectMiddle(triggerRect).x,
            y: triggerRect.y > contentPosition.y ? contentBottom : contentPosition.y
        }

        const maxAllowedRight = getMaxAllowedRight(viewport)
        let x = contentPosition.x
        if (maxAllowedRight < contentRight) {
            x -= contentRight - maxAllowedRight
        } else if (CONSTS.INDENT_FROM_EDGE > contentPosition.x) {
            x += CONSTS.INDENT_FROM_EDGE - contentPosition.x
        }

        return {
            content: _.assign({}, contentPosition, {x}),
            arrow
        }
    }
    //TODO: Remove code duplication
    arrow = {
        x: triggerRect.x > contentPosition.x ? contentRight : contentPosition.x,
        y: getRectMiddle(triggerRect).y
    }

    const maxAllowedBottom = getMaxAllowedBottom(viewport)
    let y = contentPosition.y
    if (maxAllowedBottom < contentBottom) {
        y -= contentBottom - maxAllowedBottom
    } else if (CONSTS.INDENT_FROM_EDGE > contentPosition.y) {
        y += CONSTS.INDENT_FROM_EDGE - contentPosition.y
    }

    return {
        content: _.assign({}, contentPosition, {y}),
        arrow
    }

}


module.exports = {
    CONSTS,
    getViewportSize,
    getContentPosition,
    getFinalPositions,
    isVerticalAlignment
}
