'use strict';
var React = require('react');
var _ = require('lodash');
var tooltip = require('./tooltip');
var inputValidationErrorSymbol = require('./inputValidationError.svg.js');
var inputValidationSuccessSymbol = require('./inputValidationSuccess.svg.js');
module.exports = function () {
    return React.createElement('div', {
        'className': this.props.getClassName('input-container') + ' ' + _({
            'input-multiline': true,
            'is-disabled': this.isDisabled(),
            'success': this.props.success,
            'invalid': this.props.invalidMessage
        }).transform(function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' '),
        'data-aid': this.props.automationId,
        'onClick': this.props.onClick
    }, React.createElement('textarea', this.getInputProps()), this.props.invalidMessage ? React.createElement(tooltip, {
        'isOpen': this.props.tooltipIsOpen,
        'content': this.props.invalidMessage,
        'shouldTranslate': this.props.shouldTranslate,
        'key': 'validationErrorSymbol',
        'className': 'input-validation-icon'
    }, React.createElement('span', { 'className': 'input-validation-icon-inner' }, React.createElement(inputValidationErrorSymbol, {}))) : null, this.props.success ? React.createElement('span', {
        'className': 'input-validation-icon',
        'key': 'validationSuccessSymbol'
    }, React.createElement('span', { 'className': 'input-validation-icon-inner' }, React.createElement(inputValidationSuccessSymbol, {}))) : null);
};