'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const compose = require('../hoc/compose')
const displayNames = require('./displayNames')
const textLabel = require('./textLabel')
const symbol = require('./symbol')
const textTypes = require('../controls/textTypes')

const ButtonContent = props => React.createElement('div', {
    className: 'control-button-content'
}, props.symbol ? React.createElement(symbol, {}, props.symbol) : null, React.createElement(textLabel, {
    value: props.value,
    type: props.type,
    shouldTranslate: props.shouldTranslate
}))

ButtonContent.displayName = displayNames.BUTTON_CONTENT
ButtonContent.propTypes = {
    value: PropTypes.string.isRequired,
    symbol: PropTypes.element,
    type: PropTypes.oneOf(textTypes.TYPES)
}

ButtonContent.defaultProps = {
    type: 'T01'
}

module.exports = compose(ButtonContent)
