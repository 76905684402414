'use strict';
var React = require('react');
var _ = require('lodash');
module.exports = function () {
    return React.createElement('span', {
        'className': this.props.getClassName('bubble arrow-' + this.props.arrowAlignment),
        'onMouseEnter': this.props.onMouseEnter,
        'onMouseLeave': this.props.onMouseLeave
    }, React.createElement('div', {
        'className': 'bubble-content',
        'ref': this.getContentRef,
        'style': this.getContentStyle(),
        'key': 'content'
    }, this.getChildren()), this.props.arrowStyle ? React.createElement('div', {
        'className': 'bubble-arrow',
        'key': 'arrow',
        'style': this.props.arrowStyle
    }) : null);
};