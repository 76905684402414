'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const compose = require('../hoc/compose')
const template = require('./button.rt')
const displayNames = require('./displayNames')

class Button extends React.Component {
    render() {
        return template.call(this)
    }
}

Button.displayName = displayNames.BUTTON
Button.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func
}

module.exports = compose(Button)
