'use strict';
var React = require('react');
var _ = require('lodash');
var RadioButtons = require('./radioButtons');
module.exports = function () {
    return React.createElement('div', { 'className': this.props.getClassName('control-vertical-tabs') + (this.props.arrowedBox ? 'arrowed-box' : '') }, React.createElement(RadioButtons, {
        'value': this.props.value,
        'showRadioSymbol': false,
        'radioItemPredicate': this.radioItemPredicate,
        'onChange': this.props.onChange
    }, this.generateTabs()));
};