'use strict'
const _ = require('lodash')
const PropTypes = require('prop-types')
const React = require('react')
const compose = require('../../hoc/compose')
const displayNames = require('../displayNames')
const template = require('./uploadStatusMessage.rt')
const uploadStatuses = require('./uploadStatuses')

const getSplittedFileName = fileName => _.split(fileName, '.')
const getFileNamePrefix = fileName => {
    const splittedFileName = getSplittedFileName(fileName)
    return splittedFileName[0] + (splittedFileName[1] ? '.' : '')
}


class UploadStatusMessage extends React.Component {

    constructor(props) {
        super(props)
        this.getMessage = () => {
            if (this.props.status === uploadStatuses.UPLOAD_SUCCESS) {
                return getFileNamePrefix(this.props.value)
            }
            if (this.props.status === uploadStatuses.UPLOAD_FAILED) {
                return this.props.errorMessage
            }
            if (this.props.status === uploadStatuses.IN_PROCESS) {
                return this.props.processMessage
            }
        }
        this.getFileExtension = () => this.props.status === uploadStatuses.UPLOAD_SUCCESS ? getSplittedFileName(this.props.value)[1] || '' : ''
        this.isStatusNone = () => this.props.status === uploadStatuses.NONE
        this.isStatusUploadSuccess = () => this.props.status === uploadStatuses.UPLOAD_SUCCESS
        this.isStatusUploadFailed = () => this.props.status === uploadStatuses.UPLOAD_FAILED
        this.shouldTranslateMainMessage = () => this.props.status === uploadStatuses.UPLOAD_SUCCESS ? false : this.props.shouldTranslate

        this.getEllipsisProps = () => ({
            customTrigger: this,
            displayCustomTrigger: true,
            customTooltipMessage: this.getMessage() + this.getFileExtension()
        })
    }

    render() {
        return template.call(this)
    }
}

UploadStatusMessage.displayName = displayNames.UPLOAD_BUTTON_STATUS_MESSAGE

UploadStatusMessage.propTypes = {
    value: PropTypes.string,
    status: PropTypes.oneOf(uploadStatuses.UPLOAD_STATUS),
    errorMessage: PropTypes.string,
    processMessage: PropTypes.string
}

module.exports = compose(UploadStatusMessage)
