'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const compose = require('../../hoc/compose')
const template = require('./paletteDialog.rt')
const displayNames = require('../displayNames')

class PaletteDialog extends React.Component {
    constructor(props) {
        super(props)
        this.initialPalette = this.props.palette

        const revertToInitial = () => this.props.onPaletteChange(this.initialPalette)

        this.onBackToPreset = () => {
            revertToInitial()
            this.props.toggleChangeMode()
        }

        this.onCancel = () => {
            revertToInitial()
            if (_.isFunction(this.props.onClose)) {
                this.props.onClose()
            }
        }
    }
    render() {
        return template.call(this)
    }
}

PaletteDialog.displayName = displayNames.PALETTE_DIALOG
PaletteDialog.propTypes = {
    textKeys: PropTypes.objectOf(PropTypes.string),
    onHelp: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    addColor: PropTypes.func.isRequired,
    toggleChangeMode: PropTypes.func.isRequired,
    myColors: PropTypes.arrayOf(PropTypes.string).isRequired,
    changeMode: PropTypes.bool,
    chooseColor: PropTypes.func,
    onPreviewChange: PropTypes.func,
    onPaletteChange: PropTypes.func,
    colorSelectedIndex: PropTypes.number,
    palette: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    mainColors: PropTypes.arrayOf(PropTypes.string)

}
PaletteDialog.defaultProps = {
    textKeys: {}
}

module.exports = compose(PaletteDialog)
