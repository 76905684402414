'use strict'
const _ = require('lodash')
const positionUtil = require('./position')
const {getViewportSize, getMaxAllowedBottom, getMaxAllowedRight} = positionUtil

const CONSTS = {
    NARROW_VIEWPORT: 210,
    NARROW_VIEWPORT_PADDING: 6,
    INDENT_FROM_EDGE: 12,
    DEFAULT_SCROLL_SPEED: 5,
    MIN_LIST_HEIGHT: 180,
    SCROLL_KEYS: [37, 38, 39, 40]
}

const getVRangeForOpenOnSelected = (viewport, selectedRect, optionsRect, params) => {
    const maxAllowedBottom = getMaxAllowedBottom(viewport)
    const longerByScroll = params.longerByScroll || 0
    const initialY = selectedRect.y - params.selectedOptionY

    let min = Math.max(initialY, CONSTS.INDENT_FROM_EDGE)
    let max = Math.min(maxAllowedBottom, initialY + optionsRect.height)

    if (min === CONSTS.INDENT_FROM_EDGE) {
        max = Math.min(maxAllowedBottom, max + longerByScroll)
    } else {
        min = Math.max(CONSTS.INDENT_FROM_EDGE, min - longerByScroll)
    }

    return {min, max}
}

const getVRangeForOpenToBottom = (viewport, selectedRect, optionsRect, params = {}) => {
    const longerByScroll = params.longerByScroll || 0
    const selectedBottom = selectedRect.y + selectedRect.height
    const maxAllowedBottom = getMaxAllowedBottom(viewport)

    const vRange = {
        min: Math.max(CONSTS.INDENT_FROM_EDGE, selectedBottom - longerByScroll),
        max: Math.min(maxAllowedBottom, selectedBottom + optionsRect.height)
    }

    if (vRange.min > vRange.max) {
        vRange.min = Math.max(CONSTS.INDENT_FROM_EDGE, maxAllowedBottom - optionsRect.height)
    }

    return vRange
}

const fixVRangeTopOverlapIdNeeded = ({min, max}) => {
    const viewportTopOverlap = Math.max(0, CONSTS.INDENT_FROM_EDGE - min)

    return {
        min: min + viewportTopOverlap,
        max: max + viewportTopOverlap
    }
}

const getVRangeForOpenToTop = (viewport, selectedRect, optionsRect, params = {}) => {
    const longerByScroll = params.longerByScroll || 0
    const maxAllowedBottom = getMaxAllowedBottom(viewport)
    const selectedRectMax = selectedRect.y - selectedRect.height
    const defaultMin = selectedRect.y - optionsRect.height
    const maxAllowedMin = maxAllowedBottom - optionsRect.height

    return {
        min: Math.max(CONSTS.INDENT_FROM_EDGE, selectedRectMax < maxAllowedBottom ? defaultMin : maxAllowedMin),
        max: Math.min(selectedRect.y + longerByScroll, maxAllowedBottom)
    }
}

function getVRange(viewport, selectedRect, optionsRect, params = {}) {
    const shouldOpenOnSelected = _.isNumber(params.selectedOptionY)

    const maxAllowedBottom = getMaxAllowedBottom(viewport)
    const optionsMinHeight = Math.min(CONSTS.MIN_LIST_HEIGHT, optionsRect.height)
    const selectedRectRange = {
        min: selectedRect.y,
        max: selectedRect.y + selectedRect.height
    }

    const canFit = {
        top: optionsMinHeight <= selectedRectRange.min - CONSTS.INDENT_FROM_EDGE,
        bottom: optionsMinHeight <= maxAllowedBottom - selectedRectRange.max
    }
    const maxAllowedVRange = {
        min: CONSTS.INDENT_FROM_EDGE,
        max: maxAllowedBottom
    }

    const defaultFunc = maxAllowedBottom > selectedRectRange.max ? getVRangeForOpenToBottom : getVRangeForOpenToTop
    const vRange = shouldOpenOnSelected ?
        getVRangeForOpenOnSelected(...arguments) : defaultFunc(...arguments)

    if (optionsMinHeight > vRange.max - vRange.min && !canFit.top && !canFit.bottom) {
        return maxAllowedVRange
    }

    const longerByScroll = params.longerByScroll || 0
    if (vRange.max - vRange.min - longerByScroll >= optionsMinHeight) {
        return fixVRangeTopOverlapIdNeeded(vRange)
    }
    if (shouldOpenOnSelected && canFit.bottom) {
        return getVRangeForOpenToBottom(...arguments)
    }

    if (canFit.top) {
        return getVRangeForOpenToTop(...arguments)
    }

    return fixVRangeTopOverlapIdNeeded(vRange)
}

function getHRange(viewport, selectedRect, optionsRect) {
    let min = selectedRect.x
    let max = min + (optionsRect.width || selectedRect.width)

    if (viewport.width <= CONSTS.NARROW_VIEWPORT) {
        min = CONSTS.NARROW_VIEWPORT_PADDING
        max = min + viewport.width - CONSTS.NARROW_VIEWPORT_PADDING * 2
    } else if (max > getMaxAllowedRight(viewport)) {
        const selectedRight = selectedRect.x + selectedRect.width
        min = Math.max(selectedRight - optionsRect.width, CONSTS.INDENT_FROM_EDGE)
        max = selectedRight
    }

    return {min, max}
}

function getOptionsPosition(viewport, selectedRect, optionsRect, params = {}) { //eslint-disable-line no-unused-vars
    const vRange = getVRange(...arguments)
    const hRange = getHRange(...arguments)

    const optionsPosition = {
        x: hRange.min,
        width: hRange.max - hRange.min,
        y: vRange.min,
        height: Math.min(vRange.max - vRange.min, optionsRect.height)
    }

    return _.mapValues(optionsPosition, Math.ceil)
}

module.exports = {
    CONSTS,
    getViewportSize,
    getOptionsPosition
}
