'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const ReactDOM = require('react-dom')
const _ = require('lodash')
const compose = require('../hoc/compose')
const template = require('./dropDownBase.rt')
const dropDownBaseOptions = require('./dropDownBaseOptions')
const displayNames = require('./displayNames')
const KEYS = require('../constants/keyCodes')
const PREVENT_DEFAULT_KEYS = _.pick(KEYS, [
    'LEFT',
    'UP',
    'RIGHT',
    'DOWN',
    'ENTER',
    'SPACE',
    'ESC'
])

const doneScrolling = (node, deltaY) => node.scrollTop === 0 && deltaY < 0 ||
                                        node.scrollTop + node.offsetHeight === node.scrollHeight && deltaY > 0

const getChildrenHeight = node => _(node.children)
    .reject(child => _.includes(['fixed', 'absolute'], window.getComputedStyle(child).position))
    .map('offsetHeight')
    .sum()

class DropDownBase extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            arrows: {
                up: false,
                down: false
            }
        }

        this.isOpen = () => this.props.customToggle ? this.props.isOpen : this.state.isOpen

        this.toggleFunc = this.props.customToggle ? this.props.customToggle : isOpen => this.setState({
            isOpen: _.isUndefined(isOpen) ? !this.state.isOpen : isOpen
        }, this.onToggle)

        this.toggleOptions = isOpen => this.props.disabled ? _.noop() : this.toggleFunc(isOpen)

        this.onToggle = () => {
            const isOpen = this.isOpen()
            this.optionsNode = isOpen ? ReactDOM.findDOMNode(this.optionsPortal.refs.optionsInner) : null
            this.onLayoutChange()
            this.registerLastWindowScrollPosition()
            this.cancelOptionsScroll()

            if (_.isFunction(this.props.onToggle)) {
                this.props.onToggle(isOpen)
            }
        }

        this.updateArrow = () => this.isOpen() ? this.setState({arrows: this.getArrows()}) : _.noop()

        this.onLayoutChange = () => {
            if (this.props.onLayoutChange) {
                this.props.onLayoutChange(this.getOptionsRect(), this.optionsNode)
            }
            this.arrowsTimer = window.setTimeout(() => this.updateArrow(), 0)
        }

        this.createLayoutTimer = () => {
            window.clearTimeout(this.layoutTimer)
            this.layoutTimer = window.setTimeout(() => this.onLayoutChange(), 0)
        }

        this.getOptionsRect = () => {
            if (this.optionsNode) {
                const selectedRect = ReactDOM.findDOMNode(this.refs.selected).getBoundingClientRect()
                const optionsStyle = window.getComputedStyle(this.optionsNode)
                let height = this.optionsNode.scrollHeight
                let width = selectedRect.width
                if (this.pendingHeightChange) {
                    this.pendingHeightChange = false
                    const padding = parseInt(optionsStyle.paddingTop, 10) + parseInt(optionsStyle.paddingBottom, 10)
                    height = getChildrenHeight(this.optionsNode) + padding
                }

                if (this.pendingWidthChange) {
                    this.pendingWidthChange = false
                    // Let the browser measure the minimal required width, then re-layout and calculate position
                    width = NaN
                    this.createLayoutTimer()
                } else if (this.props.keepOptionsWidth) {
                    const isScrollHidden = _.get(this, 'props.optionsInnerStyle.width')
                    const widthWithoutScrollBar = isScrollHidden ? this.optionsNode.clientWidth : this.optionsNode.offsetWidth
                    const horizontalPadding = parseInt(optionsStyle.paddingLeft, 10) + parseInt(optionsStyle.paddingRight, 10)
                    width = Math.max(widthWithoutScrollBar - horizontalPadding, selectedRect.width)
                }
                return {
                    top: selectedRect.top + selectedRect.height,
                    left: selectedRect.left,
                    width,
                    height
                }
            }
        }

        this.onWindowResize = () => {
            this.pendingWidthChange = true
            this.onLayoutChange()
        }

        this.shouldBlockScroll = (node, deltaY) => doneScrolling(node, deltaY) ||
                                                   this.props.customBlockScroll && this.props.customBlockScroll(node, deltaY)

        this.handleArrowsScroll = deltaY => {
            const node = this.optionsNode
            const shouldScroll = this.isOpen() && !this.shouldBlockScroll(node, deltaY)
            if (shouldScroll) {
                node.scrollTop += deltaY
            }
            this.updateArrow()
        }

        this.handleScroll = e => {
            if (this.isOpen()) {
                if (this.props.blockScroll && !this.props.allowInnerScroll) {
                    const node = this.optionsNode
                    const shouldBlockScroll = this.shouldBlockScroll(node, e.deltaY) ||
                                              !node.contains(e.target)
                    if (shouldBlockScroll) {
                        e.preventDefault()
                        e.stopPropagation()
                    }
                    this.updateArrow()
                } else if (this.props.allowInnerScroll) {
                    this.onLayoutChange()
                }
            }
        }

        this.registerLastWindowScrollPosition = () => {
            this.lastWindowScrollPosition = {
                x: window.scrollX,
                y: window.scrollY
            }
        }

        this.blockScrollOutOfWindow = () => {
            if (this.isOpen()) {
                if (this.props.blockScroll) {
                    const {x, y} = this.lastWindowScrollPosition
                    window.scrollTo(x, y)
                } else {
                    this.onLayoutChange()
                }
            }
        }

        this.getArrows = () => {
            const inner = this.optionsNode
            const up = inner && inner.scrollTop > 0
            const down = inner && inner.scrollTop + inner.offsetHeight < inner.scrollHeight
            return {up, down}
        }

        this.handleKeyDown = e => {
            const isOpen = this.isOpen()
            if (this.props.onKeyDown) {
                this.props.onKeyDown(e, isOpen)
            }

            if (isOpen) {
                const inputElementToAllowEvents = ReactDOM.findDOMNode(this.props.inputToAllowEvents)
                const shouldAllowKeyDown = inputElementToAllowEvents === e.target
                const isTargetContainedInOptions = _.isElement(this.optionsNode) && _.isElement(e.target) && this.optionsNode.contains(e.target)

                if (_(PREVENT_DEFAULT_KEYS).values().includes(e.keyCode) && !shouldAllowKeyDown && !isTargetContainedInOptions) {
                    e.stopPropagation()
                    e.preventDefault()
                }
                if (_.includes(this.props.customCloseKeys || [KEYS.ENTER, KEYS.ESC, KEYS.SPACE], e.keyCode)) {
                    this.toggleOptions(false)
                }
                this.updateArrow()
            }

        }

        this.onScroll = e => {
            if (this.props.onOptionsScroll) {
                this.props.onOptionsScroll(e)
            }
            this.updateArrow()
        }

        this.scrollOptions = dir => {
            const {up, down} = this.state.arrows
            const SPEED = 5
            this.handleArrowsScroll(dir * SPEED)
            if (up && dir === -1 || down && dir === 1) {
                this.scrollAnimationFrame = window.requestAnimationFrame(() => this.scrollOptions(dir))
            }
        }

        this.cancelOptionsScroll = () => window.cancelAnimationFrame(this.scrollAnimationFrame)

        this.closeIfOpen = () => this.isOpen() ? this.toggleOptions(false) : _.noop()

        this.onClickOutside = () => {
            if (this.props.closeOnClickOutside) {
                this.closeIfOpen()
            }
        }
        this.getOptionsProps = () => _.assign({}, this.state, this.props,
            {optionsStyle: _.omitBy(this.props.optionsStyle, window.isNaN)},
            _.pick(this, [
                'scrollOptions',
                'onScroll',
                'cancelOptionsScroll',
                'onClickOutside',
                'registerLastWindowScrollPosition'
            ]))

        let optionsMountPoint = null
        this.addOptionsMountPoint = () => {
            if (!optionsMountPoint) {
                optionsMountPoint = window.document.createElement('div')
                window.document.body.appendChild(optionsMountPoint)
            }

            return optionsMountPoint
        }

        this.removeOptionsMountPoint = () => {
            if (optionsMountPoint) {
                ReactDOM.unmountComponentAtNode(optionsMountPoint)
                window.document.body.removeChild(optionsMountPoint)
                optionsMountPoint = null
            }
        }
    }

    componentDidMount() {
        this.node = ReactDOM.findDOMNode(this)
        if (this.props.onLayoutChange) {
            window.addEventListener('resize', this.onWindowResize)
        }
        if (this.props.closeOnWindowBlur) {
            window.addEventListener('blur', this.closeIfOpen)
        }
        window.addEventListener('scroll', this.blockScrollOutOfWindow)
        window.addEventListener('wheel', this.handleScroll)
        window.addEventListener('keydown', this.handleKeyDown, this.props.captureKeyboardEvents)
    }
    componentWillUnmount() {
        if (this.props.onLayoutChange) {
            window.removeEventListener('resize', this.onWindowResize)
        }
        if (this.props.closeOnWindowBlur) {
            window.removeEventListener('blur', this.closeIfOpen)
        }
        window.removeEventListener('scroll', this.blockScrollOutOfWindow)
        window.removeEventListener('wheel', this.handleScroll)
        window.removeEventListener('keydown', this.handleKeyDown, this.props.captureKeyboardEvents)
        window.clearTimeout(this.arrowsTimer)
        window.clearTimeout(this.layoutTimer)
        this.cancelOptionsScroll()
        this.removeOptionsMountPoint()
    }

    componentWillUpdate(nextProps, nextState) {
        const shouldCloseOptions = this.props.customToggle ? !nextProps.isOpen : !nextState.isOpen
        if (shouldCloseOptions) {
            this.removeOptionsMountPoint()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.isOpen()) {
            const optionsMountPoint = this.addOptionsMountPoint()
            const options = React.createElement(dropDownBaseOptions, this.getOptionsProps())
            this.optionsPortal = ReactDOM.unstable_renderSubtreeIntoContainer(this, options, optionsMountPoint)
        }
        if (this.props.customToggle && prevProps.isOpen !== this.props.isOpen) {
            this.onToggle()
        }
        const selectedChanged = prevProps.selected.key ?
            prevProps.selected.key !== this.props.selected.key : prevProps.selected !== this.props.selected
        if (selectedChanged) {
            this.toggleOptions(false)
        }
        if (this.props.forceOpen && !prevProps.forceOpen) {
            this.toggleOptions(true)
        }

        const contentKeyChanged = _.get(this, 'props.options.key') !== _.get(prevProps, 'options.key')

        if (this.props.options.length !== prevProps.options.length || contentKeyChanged) {
            this.pendingHeightChange = true
        }

        if (contentKeyChanged) {
            this.pendingWidthChange = true
            this.createLayoutTimer()
        }
    }

    render() {
        return template.call(this)
    }
}

DropDownBase.displayName = displayNames.DROP_DOWN_BASE

const nodeType = PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired,
    PropTypes.element.isRequired
])

DropDownBase.propTypes = {
    disabled: PropTypes.bool,
    optionsStyle: PropTypes.object,
    optionsInnerStyle: PropTypes.object,
    onLayoutChange: PropTypes.func,
    onOptionsScroll: PropTypes.func,
    onKeyDown: PropTypes.func,
    onToggle: PropTypes.func,
    customBlockScroll: PropTypes.func,
    blockScroll: PropTypes.bool,
    blockClickOutside: PropTypes.bool,
    closeOnClickOutside: PropTypes.bool,
    displayArrow: PropTypes.bool,
    captureKeyboardEvents: PropTypes.bool,
    closeOnWindowBlur: PropTypes.bool,
    customToggle: PropTypes.func,
    isOpen: PropTypes.bool,
    keepOptionsWidth: PropTypes.bool,
    inputToAllowEvents: PropTypes.object, // React Ref
    customCloseKeys: PropTypes.arrayOf(PropTypes.number),
    selected: nodeType,
    options: nodeType,
    dataLabel: PropTypes.string,
    forceOpen: PropTypes.bool,
    allowInnerScroll: PropTypes.bool
}

DropDownBase.defaultProps = {
    disabled: false,
    closeOnClickOutside: true,
    displayArrow: true,
    captureKeyboardEvents: true,
    blockClickOutside: true,
    closeOnWindowBlur: true,
    keepOptionsWidth: false,
    allowInnerScroll: false
}

module.exports = compose(DropDownBase)
