'use strict';
var React = require('react');
var _ = require('lodash');
module.exports = function (props, context) {
    return React.createElement('label', {
        'data-aid': props.automationId,
        'className': props.getClassName('radio-button-wrapper') + ' ' + _({
            'selected': props.selected,
            'disabled': props.disabled
        }).transform(function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ')
    }, React.createElement('input', {
        'className': 'input',
        'type': 'radio',
        'name': props.name,
        'value': props.value,
        'readOnly': 'true',
        'checked': props.selected,
        'disabled': props.disabled,
        'onClick': props.disabled ? _.noop : props.onChange
    }), props.showRadioSymbol ? React.createElement('div', {
        'key': 'radio-symbol',
        'className': 'radio-button-symbol'
    }, React.createElement('div', { 'className': 'inner-fill' })) : null, props.children);
};