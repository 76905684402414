
'use strict'
const React = require('react')
const compose = require('../hoc/compose')
const displayNames = require('./displayNames')
const {CONTEXT_MENU_WRAPPER_CLASS} = require('../constants/classNames')

const ContextMenuContent = props => React.createElement('div', {className: props.getClassName(CONTEXT_MENU_WRAPPER_CLASS)}, props.children)

ContextMenuContent.displayName = displayNames.CONTEXT_MENU_CONTENT
ContextMenuContent.propTypes = {}

module.exports = compose(ContextMenuContent)
