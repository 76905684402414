'use strict';
var React = require('react');
var _ = require('lodash');
var ArrowRight = require('./icons/arrowRight.svg.js');
var Symbol = require('./symbol');
module.exports = function (props, context) {
    return React.createElement('div', {
        'className': props.getClassName('control-drill-in-list-item') + (props.disabled ? ' disabled' : '') + (props.contentAfter ? ' has-content-after' : ''),
        'data-aid': props.automationId,
        'onClick': props.disabled ? null : props.onClick
    }, React.createElement('div', { 'className': 'content' }, React.createElement('div', { 'className': 'label' }, props.translateIfNeeded(props.label)), props.description ? React.createElement('div', {
        'key': 'description',
        'className': 'description'
    }, props.translateIfNeeded(props.description)) : null), props.contentAfter ? React.createElement('div', {
        'key': 'contentAfter',
        'className': 'content-after'
    }, props.contentAfter) : null, React.createElement(Symbol, { 'className': 'symbol-arrowRight' }, React.createElement(ArrowRight, {})));
};