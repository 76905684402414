'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const template = require('./dropDownBaseOptions.rt')
const displayNames = require('./displayNames')


class dropDownBaseOptions extends React.Component {
    render() {
        return template.call(this) // Cannot be stateless due to refs
    }
}

dropDownBaseOptions.displayName = displayNames.DROP_DOWN_BASE_OPTIONS

dropDownBaseOptions.propTypes = {
    scrollOptions: PropTypes.func,
    onScroll: PropTypes.func,
    cancelOptionsScroll: PropTypes.func,
    onClickOutside: PropTypes.func,
    registerLastWindowScrollPosition: PropTypes.func,
    optionsStyle: PropTypes.object,
    optionsInnerStyle: PropTypes.object,
    arrows: PropTypes.object,
    displayArrow: PropTypes.bool,
    blockClickOutside: PropTypes.bool
}


module.exports = dropDownBaseOptions // DO NOT COMPOSE! Refs must be kept
