'use strict'

module.exports = {
    ACTION_SET_HORIZONTAL: 'ActionSetHorizontal',
    ACTION_SET_HORIZONTAL_SPACED: 'ActionSetHorizontalSpaced',
    ACTION_SET_VERTICAL: 'ActionSetVertical',
    ACTION_WITH_SYMBOL: 'ActionWithSymbol',
    ACTION_WITH_SYMBOL_LABELED: 'ActionWithSymbolLabeled',
    ANGLE_INPUT_LABELED: 'AngleInputLabeled',
    BANNER: 'Banner',
    BIG_PANEL_HEADER: 'BigPanelHeader',
    BIG_PANEL_HEADER_WITH_ILLUSTRATION: 'BigPanelHeaderWithIllustration',
    BUTTON_LARGE_FIXED_BOTTOM: 'ButtonLargeFixedBottom',
    BUTTON_LARGE_LABELED: 'ButtonLargeLabeled',
    BUTTON_LARGE: 'ButtonLarge',
    BUTTON_LEFT: 'ButtonLeft',
    BUTTONS_GROUP: 'ButtonsGroup',
    BUTTONS_GROUP_LABELED: 'ButtonsGroupLabeled',
    BUTTON_MEDIUM: 'ButtonMedium',
    CORNER_RADIUS_INPUT: 'CornerRadiusInput',
    CORNER_RADIUS_INPUT_LABELED: 'CornerRadiusInputLabeled',
    CHECKBOX_WITH_INFO_ICON: 'CheckboxWithInfoIcon',
    CHECKBOXES: 'Checkboxes',
    CHECKBOXES_LABELED: 'CheckboxesLabeled',
    DATE_PICKER_LABELED: 'DatePickerLabeled',
    DROP_DOWN: 'DropDown',
    DROP_DOWN_LABELED: 'DropDownLabeled',
    DROP_DOWN_WITH_BUTTON: 'DropDownWithButton',
    DROP_DOWN_WITH_BUTTON_LABELED: 'DropDownWithButtonLabeled',
    DROP_DOWN_WITH_COLOR_INPUT: 'DropDownWithColorInput',
    DROP_DOWN_WITH_COLOR_INPUT_LABELED: 'DropDownWithColorInputLabeled',
    LINK_BUTTON_WITH_COLOR_INPUT: 'LinkButtonWithColorInput',
    LINK_BUTTON_WITH_COLOR_INPUT_LABELED: 'LinkButtonWithColorInputLabeled',
    COLOR_PICKER_INPUT: 'ColorPickerInput',
    COLOR_PICKER_INPUT_LABELED: 'ColorPickerInputLabeled',
    COLOR_SELECT_LABELED: 'ColorSelectLabeled',
    DOCK_LABELED: 'DockLabeled',
    DYNAMIC_FIELD_LABELED: 'DynamicFieldLabeled',
    DYNAMIC_FIELD_WITH_DESCRIPTION: 'DynamicFieldWithDescription',
    EDITABLE_URL_LABELED: 'EditableURLLabeled',
    FONT_FAMILY_PICKER_LABELED: 'FontFamilyPickerLabeled',
    GOOGLE_PREVIEW_WITH_RICH_TEXT: 'GooglePreviewWithRichText',
    HORIZONTAL_TABS_LABELED: 'HorizontalTabsLabeled',
    NUMERIC_INPUT: 'NumericInput',
    PANEL_CONTENT: 'PanelContent',
    PANEL_CONTENT_ASYMMETRIC: 'PanelContentAsymmetric',
    POPUP_SMALL_SIZE: 'PopupSmallSize',
    POPUP_MEDIUM_SIZE: 'PopupMediumSize',
    POPUP_LARGE_SIZE: 'PopupLargeSize',
    PRELOADER: 'Preloader',
    PROGRESS_BAR: 'ProgressBar',
    PROGRESS_BAR_WITH_ILLUSTRATION: 'ProgressBarWithIllustration',
    PROGRESS_BAR_WITH_ILLUSTRATION_VERTICAL: 'ProgressBarWithIllustrationVertical',
    PROMOTIONAL_LIST: 'PromotionalList',
    RADIO_BUTTONS: 'RadioButtons',
    RADIO_BUTTONS_LABELED: 'RadioButtonsLabeled',
    RADIO_BUTTONS_WITH_ILLUSTRATION: 'RadioButtonsWithIllustration',
    RICH_TEXT: 'RichText',
    RICH_TEXT_LABELED: 'RichTextLabeled',
    RICH_TEXT_WITH_ILLUSTRATION: 'RichTextWithIllustration',
    RICH_TEXT_WITH_ILLUSTRATION_VERTICAL: 'RichTextWithIllustrationVertical',
    TEXT_INPUT: 'TextInput',
    TEXT_INPUT_LABELED: 'TextInputLabeled',
    TEXT_INPUT_MULTI_LINE_WITH_BUTTON: 'TextInputMultilineWithButton',
    TEXT_INPUT_MULTI_LINE_WITH_BUTTON_LABELED: 'TextInputMultilineWithButtonLabeled',
    TEXT_INPUT_MULTI_LINE_LABELED: 'TextInputMultilineLabeled',
    TEXT_INPUT_MULTI_LINE: 'TextInputMultiline',
    TOGGLE_SWITCH: 'ToggleSwitch',
    TOGGLE_SWITCH_WITH_SELECTIVE_INPUT: 'ToggleSwitchWithSelectiveInput',
    SECONDARY_MEDIA: 'SecondaryMedia',
    SECONDARY_MEDIA_LABELED: 'SecondaryMediaLabeled',
    SECTION_DIVIDER_WITH_INFO_ICON: 'SectionDividerWithInfoIcon',
    SLIDER_LABELED: 'SliderLabeled',
    SLIDER: 'Slider',
    TEXT_FORMATTING: 'TextFormatting',
    THUMBNAILS: 'Thumbnails',
    THUMBNAIL_BUTTON_GROUP: 'ThumbnailButtonGroup',
    UPLOAD_BUTTON: 'UploadButton',
    UPLOAD_BUTTON_LABELED: 'UploadButtonLabeled',
    VERTICAL_TABS_LABELED: 'VerticalTabsLabeled'
}
