'use strict';
var React = require('react');
var _ = require('lodash');
var DatePicker = require('./datePicker');
var ContextMenu = require('./contextMenu');
module.exports = function () {
    return React.createElement('div', {
        'className': this.props.getClassName('control-date-picker-input'),
        'data-aid': this.props.automationId
    }, React.createElement(ContextMenu, {
        'className': 'context-menu-block',
        'alignment': 'BOTTOM',
        'direction': 'MIDDLE',
        'customButton': this.getCustomButton()
    }, React.createElement(DatePicker, {
        'value': this.props.value,
        'onChange': this.props.onChange
    })));
};