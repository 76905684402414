'use strict'
const PropTypes = require('prop-types')
const compose = require('../../hoc/compose')
const template = require('./chooseColorDialog.rt')
const displayNames = require('../displayNames')

const ChooseColorDialog = props => template(props)

ChooseColorDialog.displayName = displayNames.CHOOSE_COLOR_DIALOG
ChooseColorDialog.propTypes = {
    textKeys: PropTypes.objectOf(PropTypes.string),
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    myColors: PropTypes.arrayOf(PropTypes.string),
    onPreview: PropTypes.func,
    colorSelectedIndex: PropTypes.number,
    onCancel: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired
}
ChooseColorDialog.defaultProps = {
    textKeys: {}
}

module.exports = compose(ChooseColorDialog)
