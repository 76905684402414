'use strict';
var React = require('react');
var _ = require('lodash');
var Tooltip = require('./tooltip');
module.exports = function () {
    return React.createElement(Tooltip, {
        'alignment': this.props.alignment,
        'animationDuration': this.props.animationDuration,
        'arrowDistance': this.props.arrowDistance,
        'closeOnMouseLeave': this.props.closeOnMouseLeave,
        'closeOnMouseClick': this.props.closeOnMouseClick,
        'content': this.getTooltipMessage(),
        'customTrigger': this.props.customTrigger,
        'data-aid': this.props.automationId,
        'direction': this.props.direction,
        'disabled': this.isDisabled(),
        'displayCustomTrigger': this.props.displayCustomTrigger,
        'isOpen': this.props.isOpen,
        'maxWidth': this.props.maxWidth,
        'marginTop': this.props.marginTop,
        'marginRight': this.props.marginRight,
        'marginBottom': this.props.marginBottom,
        'marginLeft': this.props.marginLeft,
        'openOnMouseEnter': this.props.openOnMouseEnter,
        'onScroll': this.props.onScroll,
        'onOpen': this.props.onOpen,
        'onClose': this.props.onClose,
        'onOuterClick': this.props.onOuterClick,
        'shouldTranslate': false,
        'zIndex': this.props.zIndex
    }, React.createElement('div', {
        'ref': this.getContentContainerRef,
        'className': this.getClassName(),
        'onMouseEnter': this.props.customTrigger ? null : this.onMouseEnter
    }, this.props.children, React.createElement('span', {
        'ref': this.getMeasureContainerRef,
        'style': this.state.style,
        'className': 'tooltip-on-ellipsis-content-measure'
    }, this.getContent())));
};