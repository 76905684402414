'use strict'
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const displayNames = require('./displayNames')
const template = require('./textFormattingButtonWithDropDown.rt')

const TextFormattingToggleButtonWithDropDown = template

TextFormattingToggleButtonWithDropDown.displayName = displayNames.TEXT_FORMATTING_TOGGLE_BUTTON
TextFormattingToggleButtonWithDropDown.propTypes = {
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.any,
        content: PropTypes.any
    })).isRequired,
    hasBackgroundWhenSelected: PropTypes.bool,
    tooltipContent: PropTypes.string
}
TextFormattingToggleButtonWithDropDown.defaultProps = {
    disabled: false
}

module.exports = compose(TextFormattingToggleButtonWithDropDown)
