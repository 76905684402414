'use strict'
const _ = require('lodash')
const React = require('react')
const ReactDOM = require('react-dom')
const KEYS = require('../constants/keyCodes')
const commonPropTypes = require('../common/propTypes')

const preventNextMouseUp = e => {
    e.preventDefault()
    e.target.removeEventListener('mouseup', preventNextMouseUp)
}

const createInputClass = (displayName, template, defaultPropOverride) => {

    class Input extends React.Component {
        constructor(props) {
            super(props)
            this.timers = {}
            this.handleChange = e => {
                const {onChange} = this.props
                if (_.isFunction(onChange)) {
                    const isValid = true
                    onChange(e.target.value, isValid)
                }
            }
            this.isDisabled = () => this.props.disabled === true
            this.getInputElement = () => ReactDOM.findDOMNode(this.refs.input)
            this.focusFailed = false
            this.blurInput = () => {
                const element = this.getInputElement()
                if (element) {
                    element.blur()
                }
            }
            this.focusInput = (select = false) => {
                const element = this.getInputElement()
                if (element) {
                    element.focus()
                    if (select) {
                        this.selectContent()
                    }
                    this.focusFailed = element !== window.document.activeElement
                    if (this.focusFailed) {this.forceUpdate()}
                }
            }
            this.handleKeyDown = e => {
                const {keyCode} = e
                const isEsc = keyCode === KEYS.ESC
                const isEscAndBlur = isEsc && this.props.blurOnEscKey
                const isEnter = keyCode === KEYS.ENTER
                const isEnterAndBlur = isEnter && this.props.blurOnEnterKey

                if (isEscAndBlur || isEnterAndBlur) {
                    this.isCanceled = isEscAndBlur
                    this.blurInput()
                }

                this.props.onKeyDown(e, this.blurInput)
            }
            this.scrollInputToTopIfNeeded = () => {
                this.getInputElement().scrollTop = 0
            }
            this.selectContent = () => {
                const inputElement = this.getInputElement()
                if (!inputElement) {
                    return
                }

                if (_.isFinite(this.props.initialSelectionStart) && _.isFinite(this.props.initialSelectionEnd)) {
                    inputElement.setSelectionRange(this.props.initialSelectionStart, this.props.initialSelectionEnd)
                } else {
                    inputElement.select()
                }
            }
            this.onFocusCallback = this.props.onFocus || _.noop
            this.onFocus = e => {
                e.target.addEventListener('mouseup', preventNextMouseUp) // Safari deselect on mouse up fix
                this.isCanceled = false
                this.valueOnFocus = e.target.value
                if (this.props.selectOnFocus) {
                    this.selectContent()
                }
                this.onFocusCallback(e)
            }
            this.onBlurCallback = this.props.onBlur || _.noop
            this.onBlur = e => {
                this.scrollInputToTopIfNeeded()
                this.timers.reFocus = window.setTimeout(() => {
                    if (!window.document.hasFocus()) {
                        window.addEventListener('focus', this.reFocus)
                    }
                }, 0)
                if (this.isCanceled && this.props.revertValueOnCancel) {
                    const isValid = true
                    this.props.onChange(this.props.successValue || this.valueOnFocus, isValid)
                }
                this.onBlurCallback(e, this.isCanceled)

                if (this.props.preventBlur) {
                    this.reFocus()
                }
            }
            this.reFocus = () => {
                this.focusInput()
                window.removeEventListener('focus', this.reFocus)
            }
            this.getInputClassName = () => {
                const additionalClass = (this.props.innerInputClass || '') + (this.props.inputTextSuffix ? ' has-text-suffix' : '')
                return this.props.getClassName(`${this.props.defaultInputClass} ${additionalClass}`)
            }
            this.getInputProps = () => ({
                ref: 'input',
                type: this.props.type || 'text',
                className: this.getInputClassName(),
                value: this.props.value,
                onChange: this.handleChange,
                disabled: this.isDisabled(),
                readOnly: this.props.isReadOnly,
                placeholder: this.props.translateIfNeeded(this.props.placeholder) || '',
                maxLength: this.props.maxLength,
                spellCheck: false,
                onKeyDown: this.handleKeyDown,
                onFocus: this.onFocus,
                onBlur: this.onBlur,
                onWheel: this.props.shouldBlockOuterScroll ? this.props.blockOuterScroll : _.noop,
                dir: this.props.dir,
                name: this.uniqueId,
                id: this.uniqueId
            })

            this.getRootCssClasses = () => [
                'input-container',
                this.isDisabled() ? 'is-disabled' : '',
                this.props.success ? 'success' : '',
                this.props.invalidMessage ? 'invalid' : '',
                this.props.unit ? `${this.props.unit}-unit` : ''
            ].join(' ')
        }

        componentWillMount() {
            this.uniqueId = this.props.focusOnPrefixClick ? _.uniqueId('baseuilib-input-') : undefined
        }

        componentDidMount() {
            if (!this.props.isReadOnly && this.props.autoSelect) {
                this.timers.initialFocus = window.setTimeout(this.focusInput.bind(this, true), 0)
            } else if (this.props.focus) {
                this.timers.initialFocus = window.setTimeout(this.focusInput.bind(this), 0)
            }
        }
        componentDidUpdate(prevProps) {
            if (!this.props.isReadOnly && prevProps.isReadOnly) {
                this.timers.writeableFocus = window.setTimeout(this.focusInput.bind(this, true), 0)
            }
            if (this.props.selectionActionCounter !== prevProps.selectionActionCounter) {
                this.selectContent()
            }
        }
        componentWillUpdate(nextProps) {
            if (nextProps.focus && (!this.props.focus || this.focusFailed)) {
                window.clearTimeout(this.timers.blurUpdate)
                this.timers.focusUpdate = window.setTimeout(this.focusInput.bind(this), 0)
            } else if (!nextProps.focus && this.props.focus) {
                window.clearTimeout(this.timers.focusUpdate)
                this.timers.blurUpdate = window.setTimeout(this.blurInput.bind(this), 0)
            }
        }

        componentWillUnmount() {
            _.forEach(this.timers, timer => window.clearTimeout(timer))
        }

        render() {
            return template.call(this)
        }
    }

    Input.displayName = displayName

    Input.propTypes = commonPropTypes.INPUT_PROP_TYPES
    Input.defaultProps = _.assign({}, {
        disabled: false,
        blurOnEnterKey: true,
        blurOnEscKey: true,
        shouldBlockOuterScroll: false,
        placeholder: '',
        dir: 'ltr',
        onKeyDown: _.noop,
        focusOnPrefixClick: false,
        showInfoTooltip: false,
        infoTooltipProps: {},
        isReadOnly: false,
        autoSelect: false,
        selectOnFocus: true,
        revertValueOnCancel: true,
        defaultInputClass: 'input',
        preventBlur: false,
        selectionActionCounter: 0
    }, defaultPropOverride)

    return Input
}
module.exports = createInputClass
