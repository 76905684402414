'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const compose = require('../../hoc/compose')
const displayNames = require('../displayNames')
const template = require('./uploadButton.rt')
const uploadStatuses = require('./uploadStatuses')

class UploadButton extends React.Component {
    constructor(props) {
        super(props)
        this.getButtonContent = () => this.props.status === uploadStatuses.NONE ? this.props.buttonContent.beforeUpload : this.props.buttonContent.afterUpload
        this.isStatusNone = () => this.props.status === uploadStatuses.NONE
        this.isDisabled = () => this.props.disabled || this.props.status === uploadStatuses.IN_PROCESS
    }
    render() {
        return template.call(this)
    }
}

UploadButton.displayName = displayNames.UPLOAD_BUTTON

UploadButton.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    buttonContent: PropTypes.shape({
        beforeUpload: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
        afterUpload: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
    }),
    status: PropTypes.oneOf(uploadStatuses.UPLOAD_STATUS),
    errorMessage: PropTypes.string,
    processMessage: PropTypes.string
}

UploadButton.defaultProps = {
    disabled: false,
    status: uploadStatuses.NONE
}

module.exports = compose(UploadButton)
