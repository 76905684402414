'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const compose = require('../hoc/compose')
const template = require('./radioButtons.rt')
const displayNames = require('./displayNames')

const LabelWrapper = props => React.createElement('div', {className: 'radio-button-label'}, props.label)

const validateOptions = (props, propName, compName, locationName) => {
    if (_.isEmpty(props[propName]) === _.isEmpty(props.children)) {
        return new Error(`Exactly one of ${propName} property and children can be specified in ${compName}.`)
    }
    const optionsType = {
        options: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.arrayOf(PropTypes.shape({
                value: PropTypes.string.isRequired,
                label: PropTypes.string,
                disabled: PropTypes.bool
            }))
        ])
    }
    PropTypes.checkPropTypes(optionsType, props, locationName, compName)
}

const generateChildrenFromOptions = props =>
    _.map(props.options, option => {
        if (props.labelTemplate) {
            return React.createElement(props.labelTemplate, option)
        }
        const itemProps = _.isObject(option) ? option : {value: props.translateIfNeeded(option)}
        const updatedProps = _({})
            .assign(itemProps, {label: props.translateIfNeeded(itemProps.label || itemProps.value)})
            .defaults({disabled: props.disabled})
            .value()
        return React.createElement(LabelWrapper, updatedProps)
    })

class RadioButtons extends React.Component {
    constructor(props) {
        super(props)
        this.groupId = _.uniqueId('radioGroup')
        this.generateChildren = () => this.props.options ? generateChildrenFromOptions(this.props) : _.castArray(this.props.children)
    }
    render() {
        return template.call(this)
    }
}

RadioButtons.displayName = displayNames.RADIO_BUTTONS
RadioButtons.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    labelTemplate: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), // React class / functional comp
    options: validateOptions,
    disabled: PropTypes.bool,
    radioItemPredicate: PropTypes.func,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    showRadioSymbol: PropTypes.bool
}

RadioButtons.defaultProps = {
    disabled: false,
    radioItemPredicate: item => _.has(item, 'props.value'),
    orientation: 'vertical',
    showRadioSymbol: true
}

module.exports = compose(RadioButtons)
