'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const compose = require('../hoc/compose')
const template = require('./thumbnails.rt')
const displayNames = require('./displayNames')
const thumbnail = require('./thumbnail')
const addThumbnailButton = require('./addThumbnailButton')
const noneThumbnailSymbol = require('./icons/thumb-none.svg.js')

const validateOptions = (props, propName, compName, locationName) => {
    if (!_.isEmpty(props[propName]) && !_.isEmpty(props.children)) {
        return new Error(`Exactly one of ${propName} property and children can be specified in ${compName}.`)
    }

    const optionsType = {
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string.isRequired,
            illustration: PropTypes.object,
            label: PropTypes.string,
            labelType: PropTypes.string,
            shouldTranslate: PropTypes.bool
        }))
    }

    PropTypes.checkPropTypes(optionsType, props, locationName, compName)
}

const createAddThumbnailItem = props =>
    React.createElement(addThumbnailButton, {
        onClick: props.addThumbnailProps.onAddThumbnailClick,
        label: props.addThumbnailProps.label,
        labelType: props.addThumbnailProps.labelType || props.labelsType,
        shouldTranslate: props.addThumbnailProps.shouldTranslate || props.shouldTranslate,
        style: props.addThumbnailProps.height ? {height: props.addThumbnailProps.height + 'px'} : null,
        isFirst: props.addThumbnailProps.isFirst || false
    })

const createThumbnailFromOptions = thumbnailProps => React.createElement(thumbnail,
    _.assign({
        key: thumbnailProps.value,
        labelType: thumbnailProps.labelType,
        shouldTranslate: thumbnailProps.shouldTranslate
    }, thumbnailProps))

const createThumbnailsFromOptions = props => {
    const thumbnails = _(props.options)
        .map(thumbnailProps => _.assign({
            labelType: props.labelsType,
            hasBackground: props.hasBackground,
            shouldTranslate: props.shouldTranslate,
            fixedRatio: props.fixedRatio
        }, thumbnailProps))
        .map(createThumbnailFromOptions)
        .value()

    const addThumbnailProps = props.addThumbnailProps

    if (addThumbnailProps) {
        const addThumbnail = createAddThumbnailItem(props)
        return addThumbnailProps.isFirst ? [addThumbnail].concat(thumbnails) : thumbnails.concat(addThumbnail)
    }

    return thumbnails
}

const createNoneThumbnail = props => createThumbnailFromOptions({
    value: props.noneThumbnailProps.value,
    label: props.noneThumbnailProps.label,
    labelType: props.noneThumbnailProps.labelType || props.labelsType,
    illustration: React.createElement('div',
        {
            className: 'none-thumbnail-symbol-container',
            style: {height: props.noneThumbnailProps.height + 'px'}
        },
        React.createElement(noneThumbnailSymbol)),
    shouldTranslate: props.noneThumbnailProps.shouldTranslate || props.shouldTranslate,
    fixedRatio: props.fixedRatio
})

class Thumbnails extends React.Component {
    constructor(props) {
        super(props)
        this.groupId = _.uniqueId('thumbnails')
        this.generateChildren = () => {
            const noneThumbnails = this.props.noneThumbnailProps ? createNoneThumbnail(this.props) : []
            const children = this.props.children || createThumbnailsFromOptions(this.props)
            const shouldAddTooltipOnEllipsis = props.borderType !== 'thumbnailAndLabel' && this.props.shouldAddTooltipOnEllipsis
            const childrenWithEllipsisProp = _.map(children, child => {
                if (this.isThumbnail(child)) {
                    return React.cloneElement(child, _.defaults(
                        {shouldAddTooltipOnEllipsis},
                        child.props
                    ))
                }
                return child
            })
            return _.concat(noneThumbnails, childrenWithEllipsisProp)
        }

        this.isThumbnail = item =>
            _.has(item, 'type.getDisplayName') && item.type.getDisplayName() === displayNames.THUMBNAIL
        this.getThumbnailWrapperClasses = item => ({
            'thumbnail-wrapper': true,
            selected: item.props.disabled ? false : this.props.value === item.props.value,
            'none-thumbnail': this.props.noneThumbnailProps && item.props.value === this.props.noneThumbnailProps.value
        })
        this.getControlClasses = () => ({
            'with-border': this.props.borderType !== 'none',
            'outer-border': this.props.borderType === 'thumbnailAndLabel',
            'inner-border': this.props.borderType === 'thumbnail',
            'with-label': this.props.children ? _.some(this.props.children, 'props.label') : _.some(this.props.options, 'label')
        })
    }

    render() {
        return template.call(this)
    }
}

Thumbnails.displayName = displayNames.THUMBNAILS
Thumbnails.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: validateOptions,
    maxThumbsPerRow: PropTypes.number,
    labelsType: PropTypes.string,
    fixedRatio: PropTypes.bool,
    shouldAddTooltipOnEllipsis: PropTypes.bool,
    borderType: PropTypes.oneOf(['none', 'thumbnailAndLabel', 'thumbnail']),
    disabled: PropTypes.bool,
    addThumbnailProps: PropTypes.shape({
        onAddThumbnailClick: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
        labelType: PropTypes.string,
        height: PropTypes.number,
        shouldTranslate: PropTypes.bool,
        isFirst: PropTypes.bool
    }),
    hasBackground: PropTypes.bool,
    noneThumbnailProps: PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string,
        labelType: PropTypes.string,
        height: PropTypes.number,
        shouldTranslate: PropTypes.bool
    })
}

Thumbnails.defaultProps = {
    borderType: 'none',
    hasBackground: true,
    shouldAddTooltipOnEllipsis: true
}

module.exports = compose(Thumbnails)
