'use strict';
var React = require('react');
var _ = require('lodash');
var DragHandle = require('../dragHandle.svg.js');
var Symbol = require('../symbol');
var tooltip = require('../tooltip');
module.exports = function (props, context) {
    return React.createElement('div', { 'className': props.getClassName('sbdl-item-row-base') + (props.dragHandle ? ' has-drag-handle' : '') + (props.dragHandleEnabled ? '' : ' disabled-drag-handle') }, props.dragHandleTooltipContent && props.dragHandle ? React.createElement('div', {
        'key': 'itemRowDragHandle',
        'className': 'drag-handle-container'
    }, React.createElement(Symbol, { 'className': 'symbol-dragHandle' }, React.createElement(tooltip, {
        'className': 'dragHandle-tooltip',
        'content': props.dragHandleTooltipContent,
        'shouldTranslate': false,
        'isOpen': props.isDragging
    }, React.createElement(DragHandle, {})))) : null, props.dragHandle && !props.dragHandleTooltipContent ? React.createElement('div', {
        'key': 'itemRowDragHandle',
        'className': 'drag-handle-container'
    }, React.createElement(Symbol, { 'className': 'symbol-dragHandle' }, React.createElement(DragHandle, {}))) : null, React.createElement('div', { 'className': 'content' }, '\n        ', props.children, '\n    '));
};