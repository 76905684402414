'use strict';
var React = require('react');
var _ = require('lodash');
var Toggle = require('./toggle');
var Tooltip = require('./tooltip');
var DropDown = require('./dropDown');
var Symbol = require('./symbol');
var TextFormattingToggleButton = require('./textFormattingToggleButton');
function repeatOpt1(opt, optIndex) {
    return React.createElement(Symbol, {
        'value': opt.value,
        'key': opt.value
    }, opt.content);
}
module.exports = function (props, context) {
    return React.createElement('span', {}, !props.value ? React.createElement(TextFormattingToggleButton, _.assign({}, { 'key': 'toggleOff' }, props)) : null, props.value ? React.createElement(Tooltip, {
        'key': 'toggleOn',
        'disabled': !props.tooltipContent,
        'content': props.tooltipContent,
        'shouldTranslate': props.shouldTranslate
    }, React.createElement.apply(this, [
        DropDown,
        {
            'value': props.value,
            'onChange': props.onChange,
            'callOnChangeAnyway': true,
            'className': 'text-formatting-dropdown ' + (props.hasBackgroundWhenSelected ? 'selected-has-background' : ''),
            'optionsContainerClassName': 'text-formatting-dropdown-options',
            'hasArrowIcon': false
        },
        _.map(props.options, repeatOpt1.bind(this))
    ])) : null);
};