'use strict'
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const template = require('./radioButton.rt')
const displayNames = require('./displayNames')

const RadioButton = template

RadioButton.displayName = displayNames.RADIO_BUTTON
RadioButton.propTypes = {
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    showRadioSymbol: PropTypes.bool
}
RadioButton.defaultProps = {
    selected: false,
    disabled: false,
    showRadioSymbol: true
}

module.exports = compose(RadioButton)
