'use strict'
const React = require('react')
const PropTypes = require('prop-types')
const _ = require('lodash')
const compose = require('../hoc/compose')
const template = require('./corner.rt')
const displayNames = require('./displayNames')

class Corner extends React.Component {
    constructor(props) {
        super(props)
        this.getRadius = () => {
            const borderRadiusPosition = 'border' + _.startCase(this.props.position).replace(' ', '') + 'Radius'
            const value = this.props.value + 'px'
            return {[borderRadiusPosition]: value}
        }
    }

    render() {
        return template.call(this)
    }
}

Corner.displayName = displayNames.CORNER
Corner.propTypes = {
    max: PropTypes.number,
    min: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    position: PropTypes.oneOf(['top-right', 'top-left', 'bottom-right', 'bottom-left']),
    value: PropTypes.number.isRequired

}

Corner.defaultProps = {
    position: 'top-right'
}

module.exports = compose(Corner)
