'use strict'
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const template = require('./thumbnailButtonGroup.rt')
const displayNames = require('./displayNames')

const ThumbnailButtonGroup = template

ThumbnailButtonGroup.displayName = displayNames.THUMBNAIL_BUTTON_GROUP
ThumbnailButtonGroup.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        illustration: PropTypes.object.isRequired,
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool
    })).isRequired,
    maxThumbsPerRow: PropTypes.number.isRequired,
    numOfThumbsThatForceBreak: PropTypes.number
}

module.exports = compose(ThumbnailButtonGroup)
