'use strict';
var React = require('react');
var _ = require('lodash');
var DropDownOption = require('./dropDownOption');
var TooltipOnEllipsis = require('./tooltipOnEllipsis');
module.exports = function (props, context) {
    return React.createElement(DropDownOption, _.assign({}, { 'className': 'dropdown-theme-option' }, _.omit(props, 'children')), props.isSelectedElement ? React.createElement('div', {
        'className': 'dropdown-theme-option-label',
        'key': 'themeLabel'
    }, props.label) : null, !props.isSelectedElement ? React.createElement('div', {
        'className': 'dropdown-theme-option-preview',
        'key': 'themePreview'
    }, props.color ? React.createElement('div', {
        'className': 'dropdown-theme-option-color',
        'key': 'dropdownThemeColor',
        'style': { backgroundColor: props.color }
    }) : null, React.createElement(TooltipOnEllipsis, {
        'marginTop': -10,
        'closeOnMouseClick': true
    }, React.createElement('div', {
        'className': 'dropdown-theme-option-content',
        'style': _.assign({ fontSize: Math.min(props.size, 30) + 'px' }, props.theme)
    }, props.label)), props.htmlTag ? React.createElement('div', {
        'className': 'dropdown-theme-option-html-tag',
        'key': 'dropdownThemeTag'
    }, '<', props.htmlTag, '>') : null) : null, !props.isSelectedElement ? React.createElement('div', {
        'className': 'dropdown-theme-option-size',
        'key': 'themeSize'
    }, props.size + 'px') : null);
};