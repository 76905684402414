
'use strict'
const React = require('react')
const compose = require('../hoc/compose')
const displayNames = require('./displayNames')

const Symbol = props => React.createElement('span', {className: props.getClassName('symbol')}, props.children)

Symbol.displayName = displayNames.Symbol
Symbol.propTypes = {}

module.exports = compose(Symbol)
