'use strict'
const _ = require('lodash')
const PropTypes = require('prop-types')
const React = require('react')
const template = require('./palettePicker.rt')
const compose = require('../../hoc/compose')
const colorUtil = require('../../util/color')
const displayNames = require('../displayNames')
const PALETTE_PRESETS = require('./palettePickerPresets.json')

class PalettePicker extends React.Component {
    constructor(props) {
        super(props)

        this.getPaletteMainColors = palette => palette.map(colorSet => colorSet[2])

        // TODO: consider something cheaper performance-wise
        this.isSelectedPalette = palette => _.isEqual(palette, this.props.value)

        this.isLightColor = color => _(color)
            .thru(colorUtil.hexToHsb)
            .thru(colorUtil.getDistanceToWhite)
            .value() < 3
    }

    render() {
        return template.call(this)
    }
}

PalettePicker.displayName = displayNames.PALETTE_PICKER
PalettePicker.propTypes = {
    palettePresets: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))),
    onChange: PropTypes.func,
    onPaletteEnter: PropTypes.func,
    onPaletteLeave: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
}
PalettePicker.defaultProps = {
    palettePresets: PALETTE_PRESETS,
    onPaletteEnter: _.noop,
    onPaletteLeave: _.noop
}

module.exports = compose(PalettePicker)
