'use strict';
var React = require('react');
var _ = require('lodash');
var textLabel = require('./textLabel');
function repeatItem1(item, itemIndex) {
    return React.createElement('div', {
        'className': 'promotional-list-item',
        'key': item
    }, this.props.symbol, React.createElement(textLabel, {
        'value': item,
        'type': 'T05',
        'shouldTranslate': this.props.shouldTranslate,
        'enableEllipsis': false
    }));
}
module.exports = function () {
    return React.createElement.apply(this, [
        'div',
        {
            'data-aid': this.props.automationId,
            'className': 'control-promotional-list'
        },
        _.map(this.props.items, repeatItem1.bind(this))
    ]);
};