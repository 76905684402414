'use strict'
const React = require('react')
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const displayNames = require('./displayNames')
const template = require('./thumbnail.rt')
const textTypes = require('./textTypes')

class Thumbnail extends React.Component {

    constructor(props) {
        super(props)

        this.getEllipsisProps = () => ({
            customTrigger: this,
            displayCustomTrigger: true
        })
    }

    getIllustrationContainerClasses() {
        return {
            'illustration-container': true,
            'illustration-background': this.props.hasBackground,
            disabled: this.props.disabled
        }
    }

    render() {
        return template.call(this)
    }

}
Thumbnail.displayName = displayNames.THUMBNAIL
Thumbnail.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelType: PropTypes.string,
    hasBackground: PropTypes.bool,
    illustration: PropTypes.element,
    fixedRatio: PropTypes.bool,
    shouldAddTooltipOnEllipsis: PropTypes.bool,
    disabled: PropTypes.bool
}

Thumbnail.defaultProps = {
    labelType: textTypes.THUMBNAIL_LABEL_DEFAULT_TYPE,
    hasBackground: true,
    shouldAddTooltipOnEllipsis: true
}

module.exports = compose(Thumbnail)


