'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const compose = require('../hoc/compose')
const template = require('./verticalTabs.rt')
const displayNames = require('./displayNames')
const Tab = require('./tab')

const validateOptions = (props, propName, compName, locationName) => {
    if (_.isEmpty(props[propName]) === _.isEmpty(props.children)) {
        return new Error(`Exactly one of ${propName} property and children can be specified in ${compName}.`)
    }
    const optionsType = {
        options: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        }))
    }

    PropTypes.checkPropTypes(optionsType, props, locationName, compName)
}

const generateTabs = props =>
    _.map(props.options, (tabProps, index) =>
        React.createElement(Tab, {
            shouldTranslate: props.shouldTranslate,
            automationId: tabProps.automationId,
            label: tabProps.label,
            value: tabProps.value,
            key: `tab${index}`
        }))

class VerticalTabs extends React.Component {
    constructor(props) {
        super(props)
        this.generateTabs = () => props.children || generateTabs(props)
        this.radioItemPredicate = item =>
            _.has(item, 'type.getDisplayName') && item.type.getDisplayName() === displayNames.TAB
    }

    render() {
        return template.call(this)
    }
}

VerticalTabs.displayName = displayNames.VERTICAL_TABS
VerticalTabs.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    arrowedBox: PropTypes.bool,
    options: validateOptions
}

VerticalTabs.defaultProps = {
    arrowedBox: false
}

module.exports = compose(VerticalTabs)
