'use strict';
var React = require('react');
var _ = require('lodash');
var Scroll = require('react-custom-scroll');
var MyColors = require('./myColors');
var Palette = require('./palette');
var Button = require('../button');
var Divider = require('../divider');
var RichText = require('../richText');
var TextLabel = require('../textLabel');
var ArrowLeft = require('../arrowLeftSmall.svg.js');
var PanelHeader = require('../../panels/panelHeader');
var Composites = require('../../composites/composites');
function onColorLeave1() {
    (!this.props.changeMode && this.props.onPreviewChange || _.noop)(null);
}
function onColorLeave2() {
    (this.props.onPreviewChange || _.noop)(null);
}
module.exports = function () {
    return React.createElement('div', { 'className': this.props.getClassName('color-picker-palette-dialog') }    /* HEADER */, React.createElement(PanelHeader, {
        'onClose': this.props.changeMode ? this.onCancel : this.props.onClose,
        'noHelpBtn': true,
        'className': 'dragger light small'
    }, !this.props.changeMode ? React.createElement('span', { 'key': 'colorPickerHeader' }, this.props.textKeys.header && this.props.translateIfNeeded(this.props.textKeys.header) || 'Color Picker') : null, this.props.changeMode ? React.createElement(Button, {
        'className': 'btn-back',
        'onClick': this.onBackToPreset,
        'key': 'colorPickerHeader'
    }, React.createElement('span', { 'className': 'palette-dialog-arrow-left' }, React.createElement(ArrowLeft, {})), React.createElement('span', {}, this.props.textKeys.presetDesigns && this.props.translateIfNeeded(this.props.textKeys.presetDesigns) || 'Preset Designs')) : null)    /* /HEADER */, React.createElement(Divider, { 'long': true })    /* PALETTE */, !this.props.changeMode ? React.createElement(Composites.ActionSetHorizontal, {
        'className': 'small',
        'key': 'changeModeLabel'
    }, React.createElement(RichText, {}, this.props.textKeys.siteColors && this.props.translateIfNeeded(this.props.textKeys.siteColors) || 'Site Colors'), React.createElement(Button, {
        'className': 'btn-text link',
        'onClick': this.props.toggleChangeMode
    }, this.props.textKeys.change && this.props.translateIfNeeded(this.props.textKeys.change) || 'Change')) : null, this.props.changeMode ? React.createElement(TextLabel, {
        'key': 'changeModeLabel',
        'className': 'palette-dialog-change-colors-label',
        'shouldTranslate': this.props.shouldTranslate,
        'value': this.props.textKeys.changeColors || 'Change Your Site Colors'
    }) : null, React.createElement(Palette, {
        'value': this.props.changeMode ? null : this.props.value,
        'onChange': this.props.changeMode ? this.props.chooseColor : this.props.onChange,
        'onMainColorChange': this.props.changeMode ? _.partialRight(this.props.chooseColor, true) : null,
        'onColorEnter': this.props.changeMode ? _.noop : this.props.onPreviewChange,
        'onColorLeave': onColorLeave1.bind(this),
        'mainColors': this.props.changeMode ? this.props.mainColors : null,
        'palette': this.props.palette
    })    /* /PALETTE */
          /* MY COLORS */, !this.props.changeMode ? React.createElement(TextLabel, {
        'className': 'palette-dialog-my-colors-label',
        'key': 'myColorsLabel',
        'shouldTranslate': this.props.shouldTranslate,
        'value': this.props.textKeys.myColors || 'My Colors'
    }) : null, !this.props.changeMode ? React.createElement('div', {
        'className': 'palette-dialog-my-colors',
        'key': 'myColors'
    }, React.createElement(Scroll, {}, React.createElement('div', { 'className': 'palette-dialog-my-colors-content' }, React.createElement(MyColors, {
        'value': this.props.value,
        'colors': this.props.myColors,
        'onChange': this.props.onChange,
        'addColor': this.props.addColor,
        'onColorEnter': this.props.onPreviewChange,
        'onColorLeave': onColorLeave2.bind(this),
        'selectedIndex': this.props.colorSelectedIndex
    })))) : null, this.props.changeMode ? React.createElement(Divider, {
        'long': true,
        'key': 'paletteDivider',
        'className': 'palette-picker-info-divider'
    }) : null, this.props.changeMode ? React.createElement(RichText, {
        'key': 'choosePresetText',
        'className': 'palette-picker-info-text'
    }, '\n        ', this.props.textKeys.choosePresetText && this.props.translateIfNeeded(this.props.textKeys.choosePresetText) || 'Choose one of the preset color palettes, or click the circles to pick your own colors.', '\n        ', React.createElement('br', {}), React.createElement('a', { 'onClick': this.props.onHelp }, this.props.textKeys.learnMore && this.props.translateIfNeeded(this.props.textKeys.learnMore) || 'Learn More')) : null, !this.props.changeMode ? React.createElement('div', {
        'className': 'palette-dialog-footer',
        'key': 'footer'
    }, React.createElement(Button, {
        'className': 'btn-text link',
        'onClick': this.props.addColor
    }, this.props.textKeys.addColor && this.props.translateIfNeeded(this.props.textKeys.addColor) || '+ Add Color'), React.createElement(TextLabel, {
        'value': this.props.value.toUpperCase(),
        'shouldTranslate': false
    })) : null, this.props.changeMode ? React.createElement(Composites.ActionSetHorizontal, {
        'className': 'small flex palette-dialog-footer',
        'key': 'footer'
    }, React.createElement(Button, {
        'className': 'btn-sm btn-confirm-secondary palette-dialog-cancel',
        'onClick': this.onCancel
    }, this.props.textKeys.cancel && this.props.translateIfNeeded(this.props.textKeys.cancel) || 'Cancel'), React.createElement(Button, {
        'className': 'btn-sm btn-confirm-primary',
        'onClick': this.props.onClose
    }, this.props.textKeys.done && this.props.translateIfNeeded(this.props.textKeys.done) || 'Done')) : null);
};