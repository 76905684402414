'use strict';
var React = require('react');
var _ = require('lodash');
var CloseButton = require('../controls/closeButton.js');
var HelpButton = require('../controls/helpButton.js');
module.exports = function () {
    return React.createElement('header', {
        'data-aid': this.props.automationId,
        'onMouseDown': this.props.onMouseDown,
        'className': this.props.getClassName('panel-header'),
        'style': this.props.style
    }, React.createElement('div', { 'className': 'panel-header-title' }, React.createElement('div', { 'className': 'panel-header-title-content' }, this.props.children)), !this.props.noHelpBtn ? React.createElement(HelpButton, {
        'onClick': this.props.onHelp,
        'key': 'helpBtn'
    }) : null, !this.props.noCloseBtn ? React.createElement(CloseButton, {
        'onClick': this.props.onClose,
        'key': 'closeButton',
        'symbol': this.props.closeSymbol
    }) : null);
};