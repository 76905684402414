'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const KEYS = require('../constants/keyCodes')
const compose = require('../hoc/compose')
const displayNames = require('./displayNames')
const contextMenuPositionUtil = require('../util/contextMenuPosition')
const contextMenuButton = require('./contextMenuButton.svg.js')
const template = require('./contextMenu.rt')
const {
    getRectByBoundingRect,
    getRectByReactElement
} = require('../util/rect')


const getDefaultButton = () => React.createElement('div', {
    key: 'contextBtn',
    className: 'context-menu-button',
    onMouseDown: event => event.stopPropagation()
}, React.createElement(contextMenuButton))

class ContextMenu extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpen: false,
            arrowPosition: contextMenuPositionUtil.CONSTS.CHUPCHIK.POSITION.LEFT_TOP,
            optionsStyle: {},
            candidateIndex: null
        }
        this.actions = []

        this.isOpen = () => _.isUndefined(this.props.isOpen) ? this.state.isOpen : this.props.isOpen

        const setCandidate = i/*, action*/ => this.setState({candidateIndex: i})

        this.toggleOptions = isOpen => this.setState({
            isOpen: _.isUndefined(isOpen) ? !this.state.isOpen : isOpen,
            candidateIndex: null
        }, () => {
            this.actions = []
            if (_.isFunction(this.props.onToggle)) {
                this.props.onToggle(this.state.isOpen)
            }
        })

        this.getCustomCloseKeys = () => this.props.closeOnEnterOrSpace ? null : [KEYS.ESC]

        this.getNewIndex = by => {
            const candidateIndex = this.state.candidateIndex || 0
            const next = candidateIndex + by
            if (next < 0 || next > this.actions.length) {
                return candidateIndex
            }
            if (this.actions[next]) {
                return next
            }
            return this.getNewIndex(by < 0 ? by - 1 : by + 1)
        }

        this.onKeyDown = (e, isOpen) => {
            if (isOpen) {
                if (e.keyCode === KEYS.UP || e.keyCode === KEYS.DOWN) {
                    const incrementBy = e.keyCode === KEYS.UP ? -1 : 1
                    this.setState({candidateIndex: this.getNewIndex(incrementBy)})
                } else if (e.keyCode === KEYS.ENTER || e.keyCode === KEYS.SPACE) {
                    const action = this.actions[this.state.candidateIndex] || _.noop
                    if (this.props.closeOnEnterOrSpace) {
                        this.toggleOptions(false)
                    }
                    action(e)
                }
            }
        }

        this.getButton = () => this.props.customButton ? this.props.customButton : getDefaultButton()

        this.onOptionsLayoutChange = optionsBoundingRect => {
            if (optionsBoundingRect) {

                const viewport = contextMenuPositionUtil.getViewportSize()
                const buttonRect = getRectByReactElement(this)
                const optionsRect = getRectByBoundingRect(optionsBoundingRect)
                const params = {
                    alignment: props.alignment,
                    direction: props.direction
                }
                const optionsPosition = contextMenuPositionUtil.getOptionsPosition(viewport, buttonRect, optionsRect, params)
                const arrowPosition = contextMenuPositionUtil.getArrowPosition(buttonRect, optionsPosition)

                this.lastValidOptionsX = _.isNaN(optionsPosition.x) ? this.lastValidOptionsX : optionsPosition.x

                this.setState({
                    arrowPosition,
                    optionsStyle: {
                        top: optionsPosition.y,
                        left: this.lastValidOptionsX,
                        height: optionsPosition.height,
                        width: optionsPosition.width
                    }
                })
            }
        }

        const getWrappedChild = (child, i) => {
            if (child.props.onClick) {
                const active = i === this.state.candidateIndex
                const onClick = e => {
                    if (this.props.closeOnClick) {
                        this.toggleOptions(false)
                    }
                    child.props.onClick(e)
                }
                this.actions[i] = child.props.onClick

                return React.cloneElement(child, {
                    onClick,
                    active,
                    onMouseEnter: () => setCandidate(i),
                    onMouseLeave: () => setCandidate(null)
                })
            }
            delete this.actions[i]
            return child
        }

        this.traceClicksIfNeeded = originalChildren => {
            const isWrapped = _.invoke(originalChildren, 'type.getDisplayName') === displayNames.CONTEXT_MENU_CONTENT
            let childrenToBeWrapped = isWrapped ? originalChildren.props.children : originalChildren

            childrenToBeWrapped = _.isArray(childrenToBeWrapped) ? _.compact(childrenToBeWrapped) : childrenToBeWrapped

            const wrappedChildren = React.Children.map(childrenToBeWrapped, getWrappedChild)
            return isWrapped ? React.cloneElement(originalChildren, originalChildren.props, wrappedChildren) : wrappedChildren
        }

        this.optionsContainerClassName = () => [
            this.props.optionsContainerClassName,
            'context-menu-options',
            `arrow-${this.state.arrowPosition}`
        ].join(' ')
    }

    render() {
        return template.call(this)
    }
}

ContextMenu.displayName = displayNames.CONTEXT_MENU

ContextMenu.propTypes = {
    isOpen: PropTypes.bool,
    disabled: PropTypes.bool,
    customButton: PropTypes.element,
    optionsContainerClassName: PropTypes.string,
    closeOnClick: PropTypes.bool,
    closeOnEnterOrSpace: PropTypes.bool,
    blockClickOutside: PropTypes.bool,
    blockScroll: PropTypes.bool,
    alignment: PropTypes.string,
    direction: PropTypes.string,
    onToggle: PropTypes.func
}

ContextMenu.defaultProps = {
    disabled: false,
    optionsContainerClassName: '',
    closeOnEnterOrSpace: true,
    closeOnClick: true,
    blockScroll: true
}

module.exports = compose(ContextMenu)
