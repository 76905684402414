'use strict'
const React = require('react')
const ReactDOM = require('react-dom')
const _ = require('lodash')
const compose = require('../hoc/compose')
const arrowIcon = require('./arrowDown.svg.js')
const KEYS = require('../constants/keyCodes')
const STOP_PROPAGATION_KEYS = _.pick(KEYS, [
    'BACKSPACE',
    'LEFT',
    'UP',
    'RIGHT',
    'DOWN',
    'ENTER',
    'SPACE',
    'ESC',
    'Z'
])

const getSelectedContentProps = props => ({
    key: 'selected',
    className: `selected-content ${props.hasArrowIcon ? 'has-arrow-icon' : ''} ${props.isPlaceholder ? 'placeholder' : ''}`
})

const cloneChildrenIfNeeded = props => props.isVisible === null || !React.isValidElement(props.children) || _.get(props.children, 'type') === 'div' ?
    props.children : React.cloneElement(props.children, {
        shouldTranslate: props.shouldTranslate,
        isSelectedElement: props.isSelectedElement,
        isVisible: props.isVisible,
        wasVisible: props.wasVisible
    }, props.children.props.children)

const getChildren = props => React.createElement('div', getSelectedContentProps(props),
    _.isString(props.children) ?
        props.translateIfNeeded(props.children) : cloneChildrenIfNeeded(props))

const getArrowIfNeeded = props => props.hasArrowIcon ?
    React.createElement('span', {
        className: 'symbol-arrow-down',
        key: 'arrow'
    }, React.createElement(arrowIcon)) : null


class Option extends React.Component {
    componentDidMount() {
        if (this.props.selected && this.props.onSelectedMount) {
            this.props.onSelectedMount(this)
        }
        if (this.props.active && this.props.onActive) {
            this.props.onActive(this)
        }
        if (this.props.onMount) {
            this.props.onMount(this)
        }
    }
    componentWillUpdate(nextProps) {
        if (nextProps.active && !this.props.active && this.props.onActive) {
            this.props.onActive(this)
        }
    }
    componentWillUnmount() {
        if (this.props.active) {
            this.props.onActive(null)
        }
        if (this.props.selected && this.props.onSelectedMount) {
            this.props.onSelectedMount(null)
        }
    }
    render() {
        const {props} = this
        const {onMouseEnter} = props
        const onClick = () => props.disabled ? _.noop() : props.onClick(props.value)
        const className = [
            'control-dropdown-option',
            props.selected ? 'selected' : '',
            props.disabled ? 'disabled' : '',
            props.active ? 'active' : '',
            props.optionWrapperClassName || ''
        ].join(' ')

        return React.createElement('div', {
            className,
            onClick,
            onMouseEnter,
            'data-aid': props.automationId
        }, getChildren(props))
    }
}

const Selected = props => React.createElement('div', {
    key: props.key,
    className: 'selected-container'
}, [getChildren(_.assign({}, props, {isSelectedElement: true})), getArrowIfNeeded(props)])

const lastSelection = {start: null, end: null}
let pendingBackspace = false

const isUndoRedo = e => (e.ctrlKey || e.metaKey) && e.keyCode === KEYS.Z

const onSearchBoxKeyDown = e => {
    if (e.keyCode === KEYS.BACKSPACE) {
        const {value, selectionStart, selectionEnd} = e.target
        if (value !== '') {
            pendingBackspace = true
        }
        if (_.isNumber(selectionStart) || _.isNumber(selectionEnd)) {
            lastSelection.start = selectionStart
            lastSelection.end = selectionEnd
        }
    } else if (e.keyCode === KEYS.UP || e.keyCode === KEYS.DOWN || isUndoRedo(e)) {
        e.preventDefault()
        e.stopPropagation()
    } else if (_(STOP_PROPAGATION_KEYS).values().includes(e.keyCode)) {
        e.stopPropagation()
    }
}


class SearchBox extends React.Component {

    componentDidMount() {
        this.props.registerInputToAllowEvents(this.input)
        this.props.onChange(this.props.selectedValue, () => {
            const inputElement = ReactDOM.findDOMNode(this.input)
            if (inputElement) {
                inputElement.focus()
                inputElement.select()
                inputElement.addEventListener('keydown', onSearchBoxKeyDown)
            }
        })
    }

    componentWillUnmount() {
        this.props.registerInputToAllowEvents(null)
        const inputElement = ReactDOM.findDOMNode(this.input)
        if (inputElement) {
            inputElement.removeEventListener('keydown', onSearchBoxKeyDown)
        }
    }

    componentDidUpdate() {
        const searchTerm = this.props.searchTerm.toLowerCase()
        const value = this.props.value.toLowerCase()

        if (_.startsWith(value, searchTerm)) {
            const inputElement = ReactDOM.findDOMNode(this.input)

            if (inputElement) {
                const selectionStart = searchTerm.length
                if (selectionStart > 0) {
                    inputElement.selectionStart = selectionStart
                }
            }
        }
    }

    onChange(e) {
        let {value} = e.target
        const {selectionEnd} = e.target
        const lastSelectionLength = lastSelection.end - lastSelection.start
        const shouldSlice = pendingBackspace &&
                            lastSelectionLength > 0 &&
                            selectionEnd === value.length &&
                            selectionEnd !== 0 &&
                            value.length > 0
        if (shouldSlice) {
            value = value.slice(0, -1)
        }
        pendingBackspace = false
        this.props.onChange(value)
    }

    render() {
        return React.createElement('div', {key: this.props.key, className: 'selected-container'}, [
            React.createElement('input', {
                className: 'selected-input' + (this.props.hasArrowIcon ? ' has-arrow-icon' : ''),
                value: this.props.value,
                onChange: e => this.onChange(e),
                spellCheck: false,
                key: 'input',
                ref: input => {this.input = input}
            }),
            getArrowIfNeeded(this.props)
        ])
    }
}



Option.displayName = 'Option'
Selected.displayName = 'Selected'
SearchBox.displayName = 'SearchBox'

module.exports = {
    Option: compose(Option),
    Selected: compose(Selected),
    SearchBox: compose(SearchBox)
}
