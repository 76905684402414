'use strict'
const _ = require('lodash')
const PropTypes = require('prop-types')
const React = require('react')
const template = require('./colorFormat.rt')
const compose = require('../../hoc/compose')
const colorUtil = require('../../util/color')
const displayNames = require('../displayNames')

const isCloseOrEqual = (a, b) => Math.abs(b - a) <= 3

class ColorFormat extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            format: 'HEX',
            hsb: colorUtil.hexToHsb(this.props.value)
        }

        this.formats = [
            {value: 'HEX', label: 'HEX'},
            {value: 'RGB', label: 'RGB'},
            {value: 'HSB', label: 'HSB'}
        ]

        this.hexValidator = hex => /^[0-9a-f]{6}$/i.test(hex)

        this.getRGB = () => colorUtil.hexToRgb(this.props.value)

        this.getHSB = () => colorUtil.hexToHsb(this.props.value)

        this.onRGBChange = (val, key) => _(this.props.value)
            .thru(colorUtil.hexToRgb)
            .assign({[key]: val})
            .thru(colorUtil.rgbToHex)
            .thru(this.props.onChange)
            .value()

        const updateHsb = (hsb, key) => {
            const hex = colorUtil.hsbToHex(hsb)
            this.setState({
                hsb,
                lastChange: key
            }, () => {
                this.props.onChange(hex)
            })
        }

        this.onHSBChange = (val, key) => _(this.state.hsb)
            .assign({[key]: val})
            .thru(hsb => updateHsb(hsb, key))
            .value()
    }

    componentWillReceiveProps(nextProps) {
        const {lastChange} = this.state
        const hsb = colorUtil.hexToHsb(nextProps.value)

        const shouldKeepHue = () => hsb.saturation < 5 ||
                                    hsb.brightness < 5 ||
                                    isCloseOrEqual(hsb.hue, this.state.hsb.hue)

        const stateChange = lastChange ? {lastChange: null} : {
            hsb: _.defaults({hue: shouldKeepHue() ? this.state.hsb.hue : hsb.hue}, hsb)
        }

        this.setState(stateChange)
    }

    render() {
        return template.call(this)
    }
}

ColorFormat.displayName = displayNames.COLOR_FORMAT
ColorFormat.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    invalidHexMessage: PropTypes.string
}
ColorFormat.defaultProps = {
}

module.exports = compose(ColorFormat)
