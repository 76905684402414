'use strict';
var React = require('react');
var _ = require('lodash');
var baseUILibButton = require('./button');
function repeatOption1(section, sectionIndex, option, optionIndex) {
    return React.createElement(baseUILibButton, {
        'key': option + optionIndex,
        'className': 'btn-thumbnail',
        'disabled': option.disabled,
        'onClick': option.onClick
    }, '\n            ', option.illustration, '\n        ');
}
function repeatSection2(section, sectionIndex) {
    return React.createElement.apply(this, [
        'div',
        {
            'className': 'thumbnail-buttons-section',
            'key': 'section' + sectionIndex
        },
        _.map(section, repeatOption1.bind(this, section, sectionIndex))
    ]);
}
module.exports = function (props, context) {
    return React.createElement.apply(this, [
        'div',
        {
            'className': props.getClassName('control-thumbnail-button-group'),
            'data-aid': props.automationId,
            'data-max-items-per-row': props.maxThumbsPerRow
        },
        _.map(props.numOfThumbsThatForceBreak ? _.chunk(props.options, props.numOfThumbsThatForceBreak) : [props.options], repeatSection2.bind(this))
    ]);
};