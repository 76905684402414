'use strict'
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const template = require('./googlePreview.rt')
const displayNames = require('./displayNames')

const GooglePreview = template

GooglePreview.displayName = displayNames.GOOGLE_PREVIEW
GooglePreview.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
}

module.exports = compose(GooglePreview)
