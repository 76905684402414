'use strict';
var React = require('react');
var _ = require('lodash');
var DropDownOption = require('./dropDownOption');
var TextLabel = require('./textLabel');
module.exports = function (props, context) {
    return React.createElement(DropDownOption, {
        'value': props.value,
        'label': props.label,
        'className': 'dropdown-detailed-option'
    }, props.isSelectedElement ? React.createElement('div', {
        'className': 'dropdown-detailed-option-label',
        'key': 'label'
    }, React.createElement(TextLabel, {
        'type': 'T03',
        'shouldTranslate': props.shouldTranslate,
        'value': props.label,
        'ellipsisProps': { closeOnMouseClick: true }
    })) : null, props.icon && !props.isSelectedElement ? React.createElement('div', {
        'className': 'dropdown-detailed-option-icon',
        'key': 'icon'
    }, React.createElement('div', { 'className': 'icon-content' }, props.icon)) : null, !props.isSelectedElement ? React.createElement('div', {
        'className': 'dropdown-detailed-option-content',
        'key': 'details'
    }, React.createElement('div', { 'className': 'dropdown-detailed-option-title' }, React.createElement(TextLabel, {
        'type': 'T11',
        'shouldTranslate': props.shouldTranslate,
        'value': props.label,
        'ellipsisProps': { closeOnMouseClick: true }
    })), React.createElement('div', { 'className': 'dropdown-detailed-option-description' }, React.createElement(TextLabel, {
        'type': 'T12',
        'shouldTranslate': props.shouldTranslate,
        'value': props.description,
        'ellipsisProps': { closeOnMouseClick: true }
    }))) : null);
};