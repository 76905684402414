'use strict';
var React = require('react');
var _ = require('lodash');
var Tag = require('./tag');
function repeatTag1(tag, tagIndex) {
    return React.createElement(Tag, {
        'key': tag.key,
        'value': tag,
        'onRemove': this.onRemoveTag
    });
}
module.exports = function () {
    return React.createElement.apply(this, [
        'div',
        {
            'className': 'tag-list-input',
            'onClick': this.onTagListClicked,
            'style': this.getStyle()
        },
        _.map(this.props.value, repeatTag1.bind(this)),
        React.createElement('input', {
            'type': 'text',
            'className': 'filter-text-field',
            'value': this.props.filterText,
            'onChange': this.onFilterChanged,
            'ref': this.storeFilterTextInput,
            'onFocus': this.onFilterTextFocus,
            'onKeyDown': this.handleKeyDown
        })
    ]);
};