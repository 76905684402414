'use strict';
var React = require('react');
var _ = require('lodash');
var Checkbox = require('../checkbox');
var arrowDown = require('../../../src/controls/arrowDown.svg.js');
function onChange1(ItemCheckbox) {
    this.props.onChange(this.props.item.value, !this.props.item.checked);
}
function onClick2(ItemCheckbox) {
    this.props.expandClicked(this.props.item.value);
}
function repeatItem3(ItemCheckbox, item, itemIndex) {
    return React.createElement(ItemCheckbox, {
        'key': item.value,
        'item': item,
        'onChange': this.props.onChange,
        'filterText': this.props.filterText,
        'isExpanded': this.props.isExpanded,
        'expandClicked': this.props.expandClicked
    });
}
function scopeItemCheckbox4() {
    var ItemCheckbox = this.getItemCheckbox();
    return React.createElement.apply(this, [
        'div',
        {
            'key': 'ItemCheckboxComp',
            'className': this.getCheckboxClasses()
        },
        React.createElement('div', {}, React.createElement(Checkbox, {
            'className': 'checkbox',
            'value': this.props.item.checked,
            'indeterminate': this.props.item.indeterminate,
            'label': this.props.item.label,
            'labelAfterSymbol': true,
            'onChange': onChange1.bind(this, ItemCheckbox)
        }), React.createElement('span', {
            'className': _(this.getArrowClasses()).transform(function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' '),
            'onClick': onClick2.bind(this, ItemCheckbox)
        }, this.props.item.items && this.props.item.items.length > 0 ? React.createElement(arrowDown, { 'key': 'ItemCheckboxArrowDownn' }) : null)),
        this.props.isExpanded(this.props.item.value) ? _.map(this.props.item.items, repeatItem3.bind(this, ItemCheckbox)) : null
    ]);
}
module.exports = function () {
    return !this.props.item.isFiltered ? scopeItemCheckbox4.apply(this, []) : null;
};