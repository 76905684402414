'use strict';
var React = require('react');
var _ = require('lodash');
var Icon = require('./infoIcon.svg.js');
var ErrorIcon = require('./inputValidationError.svg.js');
var ErrorIconSmall = require('./inputValidationErrorSmall.svg.js');
var tooltip = require('./tooltip');
module.exports = function () {
    return React.createElement(tooltip, {
        'automationId': this.props.automationId,
        'className': 'info-icon-tooltip',
        'content': this.getTooltipContent(),
        'interactive': _.isFunction(this.props.onLinkClick),
        'alignment': this.props.alignment,
        'disabled': !(this.props.text || this.props.title),
        'marginLeft': this.props.tooltipMarginLeft,
        'marginRight': this.props.tooltipMarginRight,
        'maxWidth': this.props.tooltipMaxWidth
    }, React.createElement('span', {
        'className': this.props.getClassName('control-info-icon') + (this.props.error ? ' error' : '') + (this.props.small ? ' small' : ''),
        'onClick': this.props.onClick
    }, !this.props.customSymbol && !this.props.error ? React.createElement(Icon, { 'key': 'icon' }) : null, !this.props.small && !this.props.customSymbol && this.props.error ? React.createElement(ErrorIcon, { 'key': 'icon' }) : null, this.props.small && !this.props.customSymbol && this.props.error ? React.createElement(ErrorIconSmall, { 'key': 'icon' }) : null, '\n        ', this.props.customSymbol, '\n    '));
};