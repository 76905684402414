'use strict'
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const template = require('./contextualDivider.rt')
const displayNames = require('./displayNames')

const ContextualDivider = template

ContextualDivider.displayName = displayNames.CONTEXTUAL_DIVIDER
ContextualDivider.propTypes = {
    value: PropTypes.string.isRequired
}

module.exports = compose(ContextualDivider)
