'use strict'
const React = require('react')
const PropTypes = require('prop-types')
const template = require('./itemCheckbox.rt')
const compose = require('../../hoc/compose')

class ItemCheckbox extends React.Component {
    getItemCheckbox() {
        return compose(ItemCheckbox)
    }

    getCheckboxClasses() {
        return [
            this.props.getClassName('multi-level-checkbox-item'),
            this.props.isRoot ? 'multi-level-checkbox-item-root' : ''
        ].join(' ')
    }

    getArrowClasses() {
        return {
            arrowDownWrapper: true,
            up: this.props.isExpanded(this.props.item.value)
        }
    }

    render() {
        return template.call(this)
    }
}

ItemCheckbox.displayName = 'ItemCheckbox'
ItemCheckbox.propTypes = {
    item: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    isRoot: PropTypes.bool,
    filterText: PropTypes.string,
    isExpanded: PropTypes.func.isRequired,
    expandClicked: PropTypes.func.isRequired
}
module.exports = compose(ItemCheckbox)
