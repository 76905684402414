'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const ReactDOM = require('react-dom')
const _ = require('lodash')
const compose = require('../hoc/compose')
const tooltipPositionUtil = require('../util/tooltipPosition')
const withClickOutside = require('../hoc/withClickOutside')
const Bubble = require('./bubble')
const template = require('./tooltip.rt')
const displayNames = require('./displayNames')
const CSSTransitionGroup = require('react-transition-group/CSSTransitionGroup')
const {getRectByReactElement} = require('../util/rect')

const rectToStyle = rect => ({
    top: rect.y,
    left: rect.x,
    height: rect.height,
    width: rect.width,
    zIndex: rect.zIndex
})

const addMargins = (props, trigger) => ({
    x: trigger.x - props.marginLeft,
    y: trigger.y - props.marginTop,
    width: trigger.width + props.marginLeft + props.marginRight,
    height: trigger.height + props.marginTop + props.marginBottom
})

const INTERACTIVE_DELAY = 200
const ANIMATION_DURATION = 200
const withTransition = props => React.createElement(CSSTransitionGroup, {
    transitionName: 'tooltip',
    // transitionAppear: true,
    // transitionAppearTimeout: ANIMATION_DURATION,
    transitionEnter: false,
    transitionLeaveTimeout: (props.animationDuration || ANIMATION_DURATION) - 1
}, props.children)

const shouldBeOpen = (props, state) => !props.disabled && (props.isOpen || state.isOpen)

const CLOSED_STATE = {
    isOpen: false,
    contentStyle: {},
    arrowStyle: {},
    arrowAlignment: ''
}

class Tooltip extends React.Component {
    constructor(props) {
        super(props)

        this.state = CLOSED_STATE
        this.timer = null
        this.clickOutsideHandlerId = null

        this.open = () => this.setState({isOpen: true})
        this.close = () => this.setState(_.isUndefined(this.props.isOpen) ? CLOSED_STATE : {isOpen: false})

        this.isOpen = () => shouldBeOpen(this.props, this.state)

        let mountPoint = null
        this.getMountPoint = () => {
            if (!mountPoint) {
                mountPoint = window.document.createElement('div')
                window.document.body.appendChild(mountPoint)
            }
            return mountPoint
        }

        this.onClose = () => {
            if (this.props.onClose) {
                this.props.onClose()
            }
        }

        this.unregisterOnOuterClick = () => {
            if (this.clickOutsideHandlerId) {
                this.props.clearOnClickOutside(this.clickOutsideHandlerId)
                this.clickOutsideHandlerId = null
            }
        }

        this.removeMountPoint = () => {
            if (mountPoint) {
                ReactDOM.unmountComponentAtNode(mountPoint)
                window.document.body.removeChild(mountPoint)
                this.tooltipPortal = null
                mountPoint = null
                this.onClose()
                this.unregisterOnOuterClick()
            }
        }
        
        this.getTrigger = () => {
            if (this.props.customTrigger && this.props.positionByCustomTrigger) {
                return this.props.customTrigger
            }
            return _.head(ReactDOM.findDOMNode(this).children)
        }

        this.onLayoutChange = contentRef => {
            const viewport = tooltipPositionUtil.getViewportSize()
            const contentRect = getRectByReactElement(contentRef)
            const trigger = _(this.getTrigger())
                .thru(getRectByReactElement)
                .thru(_.partial(addMargins, this.props))
                .value()

            const {CONSTS} = tooltipPositionUtil
            const params = {
                alignment: this.props.alignment || CONSTS.ALIGNMENT.TOP,
                arrowDistance: this.props.arrowDistance
            }

            // TODO: Refactor + naming
            const initialPosition = tooltipPositionUtil.getContentPosition(viewport, trigger, contentRect, params)
            const {content, arrow} = tooltipPositionUtil.getFinalPositions(viewport, trigger, initialPosition, params)
            content.zIndex = this.props.zIndex
            arrow.zIndex = this.props.zIndex

            let arrowAlignment
            if (tooltipPositionUtil.isVerticalAlignment(params)) { //eslint-disable-line wix-editor/prefer-ternary
                arrowAlignment = arrow.y === content.y ? 'top' : 'bottom'
            } else {
                arrowAlignment = arrow.x === content.x ? 'left' : 'right'
            }

            this.setState({
                contentStyle: _(content)
                    .thru(rectToStyle)
                    .omit(['width', 'height'])
                    .assign({maxWidth: this.props.maxWidth})
                    .value(),
                arrowStyle: rectToStyle(arrow),
                arrowAlignment
            })
        }

        this.getTooltipContent = overrides => React.createElement(Bubble, _.assign({ref: ref => {this.bubble = ref}},
            this.props,
            this.state,
            overrides,
            {
                className: 'tooltip',
                onLayoutChange: this.onLayoutChange,
                maxWidth: this.props.maxWidth,
                targetContent: this.props.children
            },
            this.props.interactive ? {
                onMouseEnter: this.onMouseEnter,
                onMouseLeave: this.onMouseLeave
            } : {}
        ), this.props.content)

        this.onOpen = () => {
            if (this.props.onOpen) {
                this.props.onOpen()
            }
        }

        this.registerOnOuterClick = () => {
            if (_.isFunction(this.props.onOuterClick)) {
                this.clickOutsideHandlerId = this.props.onClickOutside(
                    e => this.props.onOuterClick(e),
                    this.getMountPoint()
                )
            }
        }

        this.openPortal = () => {
            window.clearTimeout(this.timer)
            const content = this.getTooltipContent()
            const mount = this.getMountPoint()
            const {animationDuration} = this.props
            const transitionProps = {children: content, animationDuration}
            this.tooltipPortal = ReactDOM.unstable_renderSubtreeIntoContainer(this, withTransition(transitionProps), mount)
            this.onOpen()
            this.registerOnOuterClick()
        }

        this.onScroll = e => {
            if (_.isFunction(this.props.onScroll)) {
                this.props.onScroll(e)
            }
            if (this.isOpen()) {
                this.close()
            }
        }

        this.onClick = () => {
            if (this.props.closeOnMouseClick && !this.props.customTrigger) {
                this.close()
            }
        }

        this.onMouseEnter = () => {
            if (this.props.openOnMouseEnter && !this.props.customTrigger) {
                if (this.props.interactive) {
                    window.clearTimeout(this.closeTimer)
                    this.open()
                } else {
                    this.open()
                }
            }
        }

        this.onMouseLeave = () => {
            if (this.props.closeOnMouseLeave && !this.props.customTrigger) {
                if (this.props.interactive) {
                    this.closeTimer = window.setTimeout(() => this.close(), INTERACTIVE_DELAY)
                } else {
                    this.close()
                }
            }
        }
    }

    static get ANIMATION_DURATION() {
        return ANIMATION_DURATION
    }

    static get INTERACTIVE_DELAY() {
        return INTERACTIVE_DELAY
    }

    componentDidMount() {
        if (this.isOpen()) {
            this.openPortal()
        }
        window.addEventListener('wheel', this.onScroll)
        window.addEventListener('scroll', this.onScroll)
        this.registerCustomTrigger(this.props.customTrigger)
    }

    unregisterCustomTrigger() {
        if (this.props.customTrigger) {
            const customTrigger = ReactDOM.findDOMNode(this.props.customTrigger)
            if (customTrigger && this.props.openOnMouseEnter) {
                customTrigger.removeEventListener('mouseenter', this.open)
            }
            if (customTrigger && this.props.closeOnMouseLeave) {
                customTrigger.removeEventListener('mouseleave', this.close)
            }
        }
    }

    registerCustomTrigger(customTrigger) {
        if (!customTrigger) {
            return
        }

        const customTriggerNode = ReactDOM.findDOMNode(customTrigger)
        if (customTriggerNode && this.props.openOnMouseEnter) {
            customTriggerNode.addEventListener('mouseenter', this.open)
        }
        if (customTriggerNode && this.props.closeOnMouseLeave) {
            customTriggerNode.addEventListener('mouseleave', this.close)
        }
    }

    componentWillReceiveProps(nextProps) {
        const customTriggerHasChanged = this.props.customTrigger && this.props.customTrigger !== nextProps.customTrigger
        if (customTriggerHasChanged) {
            this.unregisterCustomTrigger()
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.isOpen() && !shouldBeOpen(nextProps, nextState)) {
            const mountPoint = this.getMountPoint()
            const {animationDuration} = this.props
            const props = {children: null, animationDuration}
            this.tooltipPortal = ReactDOM.unstable_renderSubtreeIntoContainer(this, withTransition(props), mountPoint)
            this.timer = window.setTimeout(() => this.removeMountPoint(), this.props.animationDuration || ANIMATION_DURATION)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.isOpen()) {
            this.openPortal()
        }
        const customTriggerHadChanged = prevProps.customTrigger !== this.props.customTrigger

        if (customTriggerHadChanged) {
            this.registerCustomTrigger(this.props.customTrigger)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll)
        window.removeEventListener('wheel', this.onScroll)
        window.clearTimeout(this.timer)
        window.clearTimeout(this.closeTimer)
        this.removeMountPoint()
        this.unregisterCustomTrigger()
    }

    render() {
        return template.call(this)
    }
}

Tooltip.displayName = displayNames.TOOLTIP

const nodeType = PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired,
    PropTypes.element.isRequired
])

Tooltip.propTypes = {
    alignment: PropTypes.string,
    animationDuration: PropTypes.number,
    arrowDistance: PropTypes.number,
    closeOnMouseClick: PropTypes.bool,
    closeOnMouseLeave: PropTypes.bool,
    content: nodeType,
    customTrigger: PropTypes.object, // React ref
    direction: PropTypes.string,
    disabled: PropTypes.bool,
    displayCustomTrigger: PropTypes.bool,
    interactive: PropTypes.bool,
    isOpen: PropTypes.bool,
    marginBottom: PropTypes.number,
    marginTop: PropTypes.number,
    marginLeft: PropTypes.number,
    marginRight: PropTypes.number,
    maxWidth: PropTypes.number,
    onClose: PropTypes.func,
    onOuterClick: PropTypes.func,
    onOpen: PropTypes.func,
    onScroll: PropTypes.func,
    openOnMouseEnter: PropTypes.bool,
    positionByCustomTrigger: PropTypes.bool,
    zIndex: PropTypes.number
}

Tooltip.defaultProps = {
    closeOnMouseClick: false,
    closeOnMouseLeave: true,
    disabled: false,
    displayCustomTrigger: false,
    interactive: false,
    marginBottom: 0,
    marginLeft: 0,
    marginTop: 0,
    marginRight: 0,
    maxWidth: 240,
    openOnMouseEnter: true,
    positionByCustomTrigger: true,
    zIndex: 5999
}

module.exports = compose(Tooltip, [withClickOutside])
