'use strict'
const React = require('react')
const PropTypes = require('prop-types')
const template = require('./tagListInput.rt')
const _ = require('lodash')

class TagListInput extends React.Component {
    constructor(props) {
        super(props)

        this.onRemoveTag = key => this.props.onItemCheckedStateChanged(key, false)

        this.onFilterChanged = ev => this.props.onFilterChanged(ev.target.value)

        this.onTagListClicked = () => this.filterTextInput.focus()

        this.onFilterTextFocus = () => this.props.showTreeView(true)

        this.storeFilterTextInput = filterTextInput => {this.filterTextInput = filterTextInput}

        this.handleKeyDown = ev => {
            const backspaceKeyCode = 8
            if (ev.keyCode === backspaceKeyCode && _.isEmpty(this.props.filterText)) {
                this.props.removeLastTag()
            }
        }

    }

    getStyle() {
        return this.props.maxHeight ? {maxHeight: this.props.maxHeight} : {}
    }

    render() {
        return template.call(this)
    }
}

TagListInput.displayName = 'TagListInput'
TagListInput.propTypes = {
    value: PropTypes.array.isRequired,
    onItemCheckedStateChanged: PropTypes.func.isRequired,
    onInputChange: PropTypes.func,
    maxHeight: PropTypes.number,
    showTreeView: PropTypes.func,
    onFilterChanged: PropTypes.func,
    removeLastTag: PropTypes.func,
    filterText: PropTypes.string
}

module.exports = TagListInput
