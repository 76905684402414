'use strict'
const TYPES = ['T01', 'T02', 'T03', 'T04', 'T05', 'T06', 'T07', 'T08', 'T09', 'T10', 'T11', 'T12', 'T13', 'T14', 'T15', 'T16', 'T17']
const DEFAULT_TYPE = 'T02'
const CHECKBOX_LABEL_DEFAULT_TYPE = 'T07'
const THUMBNAIL_LABEL_DEFAULT_TYPE = 'T12'

const PANEL_HEADER_CENTERED_TEXT_TITLE_DEFAULT_TYPE = 'T13'
const PANEL_HEADER_CENTERED_TEXT_TITLE_TYPES = ['T13', 'T16']

module.exports = {
    TYPES,
    DEFAULT_TYPE,
    CHECKBOX_LABEL_DEFAULT_TYPE,
    THUMBNAIL_LABEL_DEFAULT_TYPE,
    PANEL_HEADER_CENTERED_TEXT_TITLE_DEFAULT_TYPE,
    PANEL_HEADER_CENTERED_TEXT_TITLE_TYPES
}
