'use strict'

const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const displayNames = require('./displayNames')
const template = require('./dropDownThemeOption.rt')

const DropDownThemeOption = template

DropDownThemeOption.displayName = displayNames.DROP_DOWN_THEME_OPTION
DropDownThemeOption.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    wasVisible: PropTypes.bool,
    wasVisibleStyle: PropTypes.object,
    size: PropTypes.number.isRequired,
    color: PropTypes.string,
    htmlTag: PropTypes.string,
    theme: PropTypes.object
}

module.exports = compose(DropDownThemeOption)
