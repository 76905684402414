'use strict';
var React = require('react');
var _ = require('lodash');
var TextLabel = require('./textLabel');
module.exports = function (props, context) {
    return React.createElement('div', {
        'data-aid': props.automationId,
        'className': props.getClassName('control-contextual-divider')
    }, React.createElement('hr', { 'className': 'horizontal-line' }), React.createElement(TextLabel, {
        'shouldTranslate': props.shouldTranslate,
        'value': props.value,
        'type': 'T08'
    }), React.createElement('hr', { 'className': 'horizontal-line' }));
};