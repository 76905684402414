'use strict';

var React = require('react');
module.exports = function () {
  return React.createElement(
    'svg',
    { width: '25', height: '25', viewBox: '0 0 25 25', className: 'symbol-input-validation-success' },
    React.createElement('circle', { cx: '13', cy: '12', r: '12' }),
    React.createElement('path', { className: 'c1', fillRule: 'evenodd', d: 'M17.38 10.91l-4.68 5.25h-.01c-.07.17-.17.33-.32.43-.4.25-.91.1-1.14-.35L8.99 13.4c-.23-.45-.1-1.02.3-1.27.4-.26.91-.11 1.14.34l1.5 1.9 4.27-4.78c.33-.37.86-.37 1.18 0 .33.36.33.95 0 1.32z' })
  );
};