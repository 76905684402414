'use strict';
var React = require('react');
var _ = require('lodash');
var toggle = require('./toggle');
var symbol = require('./switch-svg.js');
module.exports = function () {
    return React.createElement(toggle, _.assign({}, { 'data-aid': this.props.automationId }, this.getToggleProps()), this.props.symbol ? React.createElement('span', {
        'key': 'symbol-image',
        'className': 'symbol-image'
    }, '\n        ', this.props.symbol, '\n    ') : null, this.props.label && !this.props.labelAfterSymbol ? React.createElement('span', {
        'className': 'label label-switch ' + this.getLabelType(),
        'key': 'label',
        'onClick': this.onLabelClick
    }, this.props.translateIfNeeded(this.props.label)) : null, React.createElement('span', { 'className': 'symbol symbol-switch' }, React.createElement(symbol, {})), this.props.label && this.props.labelAfterSymbol ? React.createElement('span', {
        'className': 'label label-switch ' + this.getLabelType(),
        'key': 'label',
        'onClick': this.onLabelClick
    }, this.props.translateIfNeeded(this.props.label)) : null, this.props.children);
};