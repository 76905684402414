'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const compose = require('../hoc/compose')
const template = require('./panelHeader.rt')
const displayNames = require('../controls/displayNames')

class PanelHeader extends React.Component {
    render() {
        return template.call(this)
    }
}

PanelHeader.displayName = displayNames.PANEL_HEADER
PanelHeader.propTypes = {
    onMouseDown: PropTypes.func,
    onHelp: PropTypes.func,
    onClose: PropTypes.func,
    noCloseBtn: PropTypes.bool,
    noHelpBtn: PropTypes.bool,
    style: PropTypes.object,
    closeSymbol: PropTypes.element
}

module.exports = compose(PanelHeader)
