'use strict'

const index = {
    Composites: require('./composites/composites'),
    ActionWithSymbol: require('./controls/actionWithSymbol'),
    AddThumbnailButton: require('./controls/addThumbnailButton'),
    AngleInput: require('./controls/angleInput'),
    BackButton: require('./controls/backButton'),
    Bubble: require('./controls/bubble'),
    Button: require('./controls/button'),
    ButtonContent: require('./controls/buttonContent'),
    ButtonsGroup: require('./controls/buttonsGroup'),
    Checkbox: require('./controls/checkbox'),
    CloseButton: require('./controls/closeButton'),
    ColorPickerChooseColorDialog: require('./controls/colorPicker/chooseColorDialog'),
    ColorPickerColorFormat: require('./controls/colorPicker/colorFormat'),
    ColorPickerColorSpace: require('./controls/colorPicker/colorSpace'),
    ColorPickerMyColors: require('./controls/colorPicker/myColors'),
    ColorPickerPalette: require('./controls/colorPicker/palette'),
    ColorPickerPaletteDialog: require('./controls/colorPicker/paletteDialog'),
    ColorPickerPalettePicker: require('./controls/colorPicker/palettePicker'),
    ColorPickerPalettePickerDialog: require('./controls/colorPicker/palettePickerDialog'),
    ColorPickerResetColor: require('./controls/colorPicker/resetColor'),
    ColorPickerInput: require('./controls/colorPicker/colorPickerInput'),
    ContextMenu: require('./controls/contextMenu'),
    ContextMenuAction: require('./controls/contextMenuAction'),
    ContextMenuContent: require('./controls/contextMenuContent'),
    ContextualDivider: require('./controls/contextualDivider'),
    CornerRadiusInput: require('./controls/cornerRadiusInput'),
    CustomScroll: require('react-custom-scroll'),
    DatePicker: require('./controls/datePicker'),
    DatePickerInput: require('./controls/datePickerInput'),
    Divider: require('./controls/divider'),
    Dock: require('./controls/dock/dock'),
    DrillInListItem: require('./controls/drillInListItem'),
    DropDown: require('./controls/dropDown'),
    DropDownBase: require('./controls/dropDownBase'),
    DropDownOption: require('./controls/dropDownOption'),
    DropDownDetailedOption: require('./controls/dropDownDetailedOption'),
    DropDownStickyFooter: require('./controls/dropDownStickyFooter'),
    DropDownThemeOption: require('./controls/dropDownThemeOption'),
    DynamicField: require('./controls/dynamicField'),
    EditableURL: require('./controls/editableURL'),
    FontFamilyPicker: require('./controls/fontFamilyPicker'),
    GooglePreview: require('./controls/googlePreview'),
    HelpButton: require('./controls/helpButton'),
    HorizontalTabs: require('./controls/horizontalTabs'),
    Illustration: require('./controls/illustration'),
    InfoIcon: require('./controls/infoIcon'),
    MultiLevelCheckbox: require('./controls/multiLevelCheckbox/multiLevelCheckbox'),
    NumericInput: require('./controls/numericInput'),
    PanelHeader: require('./panels/panelHeader'),
    PanelHeaderCenteredText: require('./panels/panelHeaderCenteredText'),
    PanelHeaderIllustration: require('./panels/panelHeaderIllustration'),
    Preloader: require('./controls/preloader'),
    ProgressBar: require('./controls/progressBar'),
    PromotionalList: require('./controls/promotionalList'),
    RadioButtons: require('./controls/radioButtons'),
    RadioButtonsWithIllustration: require('./controls/radioButtonsWithIllustration'),
    RichText: require('./controls/richText'),
    SectionDivider: require('./controls/sectionDivider'),
    Tab: require('./controls/tab'),
    TextFormatting: require('./controls/textFormatting'),
    TextInput: require('./controls/textInput'),
    TextInputMultiline: require('./controls/textInputMultiline'),
    TextInputMultilineWithButton: require('./controls/textInputMultilineWithButton'),
    TextLabel: require('./controls/textLabel'),
    Thumbnail: require('./controls/thumbnail'),
    ThumbnailButtonGroup: require('./controls/thumbnailButtonGroup'),
    ThumbnailColorInput: require('./controls/thumbnailColorInput'),
    Thumbnails: require('./controls/thumbnails'),
    Tooltip: require('./controls/tooltip'),
    Toggle: require('./controls/toggle'),
    ToggleSwitch: require('./controls/toggleSwitch'),
    SecondaryMediaImage: require('./controls/secondaryMediaImage'),
    Slider: require('./controls/slider'),
    SortByDragListBase: require('./controls/sortByDragList/sortByDragListBase'),
    SortByDragList: require('./controls/sortByDragList/sortByDragList'),
    SortByDragListItemRowBase: require('./controls/sortByDragList/itemRowBase'),
    SortByDragListCustomItemRow: require('./controls/sortByDragList/customItemRow'),
    SortByDragListTextEdit: require('./controls/sortByDragList/textEdit'),
    SortByDragListItemRow: require('./controls/sortByDragList/itemRow'),
    Symbol: require('./controls/symbol'),
    VerticalTabs: require('./controls/verticalTabs'),
    OpacityPicker: require('./controls/colorPicker/opacityPicker'),
    TooltipOnEllipsis: require('./controls/tooltipOnEllipsis'),
    UploadButton: require('./controls/uploadButton/uploadButton'),
    UploadButtonMessage: require('./controls/uploadButton/uploadStatusMessage')
}

module.exports = index
