'use strict';

var React = require('react');
module.exports = function () {
  return React.createElement(
    'svg',
    { width: '16', height: '16', viewBox: '0 0 16 16', xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement('path', { className: 'bg', d: 'M5 16c-2.335 0-5-2.66-5-5V5c0-2.34 2.665-5 5-5h6c2.335 0 5 2.66 5 5v6c0 2.34-2.665 5-5 5H4.235' }),
    React.createElement('path', { className: 'border', d: 'M11.556.889a3.559 3.559 0 0 1 3.555 3.563v7.096a3.559 3.559 0 0 1-3.555 3.563H4.444A3.559 3.559 0 0 1 .89 11.548V4.452A3.559 3.559 0 0 1 4.444.889h7.112m0-.889H4.444A4.453 4.453 0 0 0 0 4.452v7.096A4.453 4.453 0 0 0 4.444 16h7.112A4.453 4.453 0 0 0 16 11.548V4.452A4.453 4.453 0 0 0 11.556 0' }),
    React.createElement('path', { className: 'check', d: 'M4.008 8.344c.031.205.145.4.334.534l2.781 1.961a.886.886 0 0 0 1.191-.158l3.512-4.384a.778.778 0 0 0-.164-1.132.883.883 0 0 0-1.195.155l-3 3.745-2.103-1.48a.885.885 0 0 0-1.194.162.778.778 0 0 0-.162.597' }),
    React.createElement('path', { className: 'minus', d: 'M12 8c0 .552-.398 1-.889 1H4.89C4.399 9 4 8.552 4 8s.398-1 .889-1h6.222c.491 0 .889.448.889 1' })
  );
};