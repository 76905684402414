'use strict'
/* eslint-disable */
'use strict';

var React = require('react');
module.exports = function () {
    return React.createElement(
        'svg',
        { width: '7', height: '13', viewBox: '0 0 7 13', xmlns: 'http://www.w3.org/2000/svg' },
        React.createElement(
            'defs',
            null,
            React.createElement('path', { id: 'a', d: 'M0 .946h7v8.626H0V.946z' }),
            React.createElement('path', { id: 'c', d: 'M0 13.055h7V1H0z' })
        ),
        React.createElement(
            'g',
            { transform: 'translate(0 -1)', fill: 'none', fillRule: 'evenodd' },
            React.createElement(
                'g',
                { transform: 'translate(0 .054)' },
                React.createElement(
                    'mask',
                    { id: 'b', fill: '#fff' },
                    React.createElement('use', {xlinkHref: '#a'})
                ),
                React.createElement('path', { d: 'M6.008 1.754a3.242 3.242 0 0 0-1.067-.603A4.004 4.004 0 0 0 3.643.945c-.595 0-1.127.094-1.595.281a3.23 3.23 0 0 0-1.175.786C.56 2.348 0 3.15 0 4.848h.931c-.01 0 .04-.835.15-1.199.11-.364.275-.685.495-.955.221-.27.5-.484.835-.638.336-.155.73-.233 1.182-.233.32 0 .623.055.91.165.286.11.537.262.752.454a2.126 2.126 0 0 1 .703 1.612c0 .43-.102.822-.305 1.174a4.12 4.12 0 0 1-.77.96c-.353.32-.655.601-.893.86-.49.531-.89.937-.89 2.524h.923c.011 0 .033-.659.066-.923.033-.265.1-.377.2-.57.098-.193.247-.324.446-.512.198-.187.473-.428.826-.78A6.363 6.363 0 0 0 6.595 5.56c.27-.43.404-.946.404-1.564a3.02 3.02 0 0 0-.263-1.278c-.177-.38-.42-.7-.728-.965', fill: '#000', mask: 'url(#b)' })
            ),
            React.createElement(
                'mask',
                { id: 'd', fill: '#fff' },
                React.createElement('use', {xlinkHref: '#c'})
            ),
            React.createElement('path', { fill: '#000', mask: 'url(#d)', d: 'M3.101 13.055h.923V11.25h-.923z' })
        )
    );
};
