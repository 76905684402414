'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const compose = require('../hoc/compose')
const template = require('./progressBar.rt')
const displayNames = require('./displayNames')

const fakeCompForDocs = () => React.createElement('div') // eslint-disable-line

const ProgressBar = template

ProgressBar.displayName = displayNames.PROGRESS_BAR
const propTypes = {
    progress: PropTypes.number.isRequired,
    progressMax: PropTypes.number
}

ProgressBar.propTypes = propTypes
fakeCompForDocs.propTypes = propTypes

module.exports = compose(ProgressBar)
