'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const compose = require('../hoc/compose')
const template = require('./toggleSwitch.rt')
const displayNames = require('./displayNames')
const textTypes = require('./textTypes')

const SIZES = {
    MEDIUM: 'switch-size-medium'
}

class toggleSwitch extends React.Component {
    constructor(props) {
        super(props)
        const className = this.props.getClassName('control-switch') +
                        (this.props.animated ? ' control-switch-animated' : '') +
                        (this.props.size ? ` ${SIZES[this.props.size]}` : '') +
                        (this.props.widthByTextLength ? ' width-by-text-length' : '') +
                        (this.props.onCheck ? ' has-on-check' : '')
        this.getToggleProps = () => _.assign({}, this.props, {className, name: 'switch'})
        this.getLabelType = () => props.labelType.toLowerCase()
        this.onLabelClick = e => {
            if (this.props.onCheck) {
                e.preventDefault()
                this.props.onCheck()
            }
        }
    }
    render() {
        return template.call(this)
    }
}

toggleSwitch.displayName = displayNames.TOGGLE_SWITCH
toggleSwitch.propTypes = {
    label: PropTypes.string,
    labelAfterSymbol: PropTypes.bool,
    labelType: PropTypes.oneOf(textTypes.TYPES),
    animated: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    onCheck: PropTypes.func,
    size: PropTypes.oneOf(_.keys(SIZES)),
    widthByTextLength: PropTypes.bool,
    symbol: PropTypes.element
}
toggleSwitch.defaultProps = {
    labelAfterSymbol: false,
    animated: false,
    labelType: textTypes.DEFAULT_TYPE,
    widthByTextLength: false
}

module.exports = compose(toggleSwitch)
