'use strict';
var React = require('react');
var _ = require('lodash');
function onMouseEnter1(palette, paletteIndex) {
    this.props.onPaletteEnter(palette, paletteIndex);
}
function onMouseLeave2(palette, paletteIndex) {
    this.props.onPaletteLeave(palette, paletteIndex);
}
function onClick3(palette, paletteIndex) {
    this.props.onChange(palette);
}
function repeatColor4(palette, paletteIndex, color, colorIndex) {
    return React.createElement('div', {
        'key': colorIndex,
        'style': { backgroundColor: color },
        'className': 'color-picker-palette-picker-color ' + (this.isLightColor(color) ? 'light' : '')
    });
}
function repeatPalette5(palette, paletteIndex) {
    return React.createElement.apply(this, [
        'div',
        {
            'key': paletteIndex,
            'onMouseEnter': onMouseEnter1.bind(this, palette, paletteIndex),
            'onMouseLeave': onMouseLeave2.bind(this, palette, paletteIndex),
            'onClick': onClick3.bind(this, palette, paletteIndex),
            'className': 'color-picker-palette-picker-palette ' + (this.isSelectedPalette(palette) ? 'selected-palette' : '')
        },
        _.map(this.getPaletteMainColors(palette), repeatColor4.bind(this, palette, paletteIndex))
    ]);
}
module.exports = function () {
    return React.createElement.apply(this, [
        'div',
        { 'className': 'color-picker-palette-picker' },
        _.map(this.props.palettePresets, repeatPalette5.bind(this))
    ]);
};