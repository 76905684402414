'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const compose = require('../hoc/compose')
const template = require('./infoIcon.rt')
const displayNames = require('./displayNames')
const {CONSTS} = require('../util/tooltipPosition')

const getTooltipChild = (str, props, tr) => str ?
    React.createElement('div', _.defaults({key: props.className}, props), tr(str)) : null

const TooltipContent = props => React.createElement('span', {className: 'info-icon-content'}, [
    getTooltipChild(props.title, {className: 'info-icon-title'}, props.translateIfNeeded),
    getTooltipChild(props.text, {className: 'info-icon-text'}, props.translateIfNeeded),
    getTooltipChild(props.linkText, {className: 'info-icon-link', onClick: props.onLinkClick}, props.translateIfNeeded)
])

class infoIcon extends React.Component {
    constructor(props) {
        super(props)

        this.getTooltipContent = () => React.createElement(TooltipContent, this.props)
    }

    render() {
        return template.call(this)
    }
}

infoIcon.displayName = displayNames.INFO_ICON

infoIcon.propTypes = {
    alignment: PropTypes.oneOf(_.values(CONSTS.ALIGNMENT)),
    tooltipMaxWidth: PropTypes.number,
    customSymbol: PropTypes.element,
    onClick: PropTypes.func,
    error: PropTypes.bool,
    small: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.string,
    linkText: PropTypes.string,
    onLinkClick: PropTypes.func,
    tooltipMarginLeft: PropTypes.number,
    tooltipMarginRight: PropTypes.number
}

infoIcon.defaultProps = {
    alignment: CONSTS.ALIGNMENT.TOP,
    tooltipMaxWidth: 240,
    small: false
}

module.exports = compose(infoIcon)
