'use strict';
var React = require('react');
var _ = require('lodash');
var TextLabel = require('./textLabel');
module.exports = function () {
    return React.createElement('div', {
        'className': this.props.getClassName('control-thumbnail' + (this.props.fixedRatio ? ' fixed-ratio' : '') + (this.props.disabled ? ' disabled' : '')),
        'data-aid': this.props.automationId
    }, this.props.illustration ? React.createElement('div', {
        'key': 'illustration-container',
        'className': _(this.getIllustrationContainerClasses()).transform(function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ')
    }, React.createElement('div', { 'className': 'illustration-inner-container' }, this.props.illustration)) : null, this.props.label && this.props.shouldAddTooltipOnEllipsis ? React.createElement(TextLabel, {
        'className': 'label',
        'value': this.props.label,
        'type': this.props.labelType,
        'shouldTranslate': this.props.shouldTranslate,
        'disabled': this.props.disabled,
        'ellipsisProps': this.getEllipsisProps(),
        'key': 'label'
    }) : null, this.props.label && !this.props.shouldAddTooltipOnEllipsis ? React.createElement(TextLabel, {
        'className': 'label',
        'key': 'noEllipsisLabel',
        'value': this.props.label,
        'type': this.props.labelType,
        'shouldTranslate': this.props.shouldTranslate,
        'disabled': this.props.disabled,
        'enableEllipsis': false
    }) : null, '\n  ', this.props.children, '\n');
};