'use strict';
var React = require('react');
var _ = require('lodash');
module.exports = function () {
    return React.createElement('div', {
        'className': this.props.getClassName('context-menu-action ') + (this.props.active ? ' active ' : '') + (this.props.disabled ? ' disabled' : ''),
        'data-aid': this.props.automationId,
        'onClick': this.props.disabled ? _.noop : this.props.onClick,
        'onMouseEnter': this.props.disabled ? _.noop : this.props.onMouseEnter,
        'onMouseLeave': this.props.disabled ? _.noop : this.props.onMouseLeave
    }, this.props.children);
};