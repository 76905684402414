'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const compose = require('../hoc/compose')
const template = require('./actionWithSymbol.rt')
const symbolLinkBtnThin = require('./linkBtnThin.svg.js')
const displayNames = require('./displayNames')

class ActionWithSymbol extends React.Component {
    constructor(props) {
        super(props)

        this.getRootCssClasses = () => [
            'control-action-with-symbol',
            this.props.disabled ? 'disabled' : '',
            this.props.initialized ? '' : 'not-initialized',
            this.props.active ? '' : 'inactive'
        ].join(' ')
    }

    render() {
        return template.call(this)
    }
}

ActionWithSymbol.displayName = displayNames.ACTION_WITH_SYMBOL
ActionWithSymbol.propTypes = {
    action: PropTypes.func,
    symbol: PropTypes.element,
    active: PropTypes.bool,
    disabled: PropTypes.bool
}
ActionWithSymbol.defaultProps = {
    action: _.noop,
    symbol: React.createElement(symbolLinkBtnThin),
    initialized: true,
    active: true,
    disabled: false
}

module.exports = compose(ActionWithSymbol)
