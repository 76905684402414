'use strict';
var React = require('react');
var _ = require('lodash');
var NumericInput = require('./numericInput');
function onDragStart1(e) {
    e.preventDefault();
}
module.exports = function () {
    return React.createElement('div', {
        'data-aid': this.props.automationId,
        'className': this.props.getClassName('control-angle-input') + (this.props.disabled ? 'disabled' : '')
    }, React.createElement('div', {
        'className': 'circle',
        'style': this.getCircleStyle(),
        'ref': this.circleRefCallback,
        'onDragStart': onDragStart1.bind(this),
        'onClick': this.props.disabled ? null : this.onClick
    }, React.createElement('div', { 'className': 'circle-center' }), React.createElement('div', {
        'className': 'knob',
        'style': this.getKnobStyle(),
        'onMouseDown': this.props.disabled ? null : this.onKnobMouseDown
    })), React.createElement(NumericInput, {
        'disabled': this.props.disabled,
        'value': this.props.value,
        'onChange': this.props.onChange,
        'unit': 'angle',
        'min': 0,
        'max': 360
    }));
};