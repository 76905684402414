'use strict';
var React = require('react');
var _ = require('lodash');
var NumericInput = require('./numericInput');
module.exports = function () {
    return React.createElement('div', { 'className': this.props.getClassName('control-corner ' + this.props.position.replace('-', ' ')) }, React.createElement(NumericInput, {
        'unit': 'px',
        'className': 'numeric-corner',
        'onBlur': this.props.onBlur,
        'onChange': this.props.onChange,
        'onFocus': this.props.onFocus,
        'min': this.props.min,
        'max': this.props.max,
        'value': this.props.value
    }), React.createElement('div', {
        'className': 'corner-border',
        'style': this.getRadius()
    }));
};