'use strict'
const ReactDOM = require('react-dom')
const _ = require('lodash')

const getBoundingRect = node => ReactDOM.findDOMNode(node).getBoundingClientRect()
const getRectByBoundingRect = boundingRect => ({
    x: boundingRect.left,
    y: boundingRect.top,
    width: boundingRect.width,
    height: boundingRect.height
})

const getStyleByRect = rect => ({
    left: rect.x,
    top: rect.y,
    width: rect.width,
    height: rect.height
})

const getRectByReactElement = elem => _(elem)
    .thru(getBoundingRect)
    .thru(getRectByBoundingRect)
    .value()

module.exports = {
    getBoundingRect,
    getRectByBoundingRect,
    getRectByReactElement,
    getStyleByRect
}
