'use strict'

const isValid = date => {
    if (Object.prototype.toString.call(date) === '[object Date]') {
        return !isNaN(date.getTime())
    }

    return false
}

module.exports = {
    isValid
}
