'use strict'
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const template = require('./backButton.rt')
const displayNames = require('./displayNames')

const BackButton = template

BackButton.displayName = displayNames.BACK_BUTTON
BackButton.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func
}

module.exports = compose(BackButton)
