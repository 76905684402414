'use strict'
const React = require('react')
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const template = require('./secondaryMediaImage.rt')
const displayNames = require('./displayNames')

class SecondaryMediaImage extends React.Component {
    constructor(props) {
        super(props)
        this.getClassName = () => `${this.props.getClassName('control-secondary-media-image')} ${this.props.src || this.props.children ? '' : 'no-img'}`
        this.getStyle = () => ({
            backgroundImage: `url(${this.props.src})`,
            backgroundRepeat: this.props.repeat ? 'repeat' : 'no-repeat'
        })
    }


    render() {
        return template.call(this)
    }
}

SecondaryMediaImage.displayName = displayNames.SECONDARY_MEDIA_IMAGE
SecondaryMediaImage.propTypes = {
    src: PropTypes.string,
    onClick: PropTypes.func,
    repeat: PropTypes.bool
}

SecondaryMediaImage.defaultProps = {
    repeat: false
}
module.exports = compose(SecondaryMediaImage)
