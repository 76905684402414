
'use strict'

const _ = require('lodash')

const realMod = (value, mod) => value % mod + (value >= 0 ? 0 : mod)

const HEX_RGX = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
const SHORT_HEX_RGX = /^#?([a-f\d])([a-f\d])([a-f\d])$/i

const fixShortHexIfNeeded = hex => hex.replace(SHORT_HEX_RGX, '#$1$1$2$2$3$3')

const hexToInt = hex => parseInt(hex, 16)
const intToHex = n => (n < 16 ? '0' : '') + n.toString(16).toUpperCase()

const hexToRgb = hex => _(['red', 'green', 'blue'])
    .zipObject(HEX_RGX.exec(fixShortHexIfNeeded(hex)).slice(1))
    .mapValues(hexToInt)
    .value()

const getHue = ({r, g, b, delta, max}) => {
    if (delta === 0) {
        return 0
    }
    switch (max) {
        case r: return 60 * realMod((g - b) / delta, 6)
        case g: return 60 * ((b - r) / delta + 2)
        case b: return 60 * ((r - g) / delta + 4)
    }
}

const rgbToHsb = rgb => {
    const {red: r, green: g, blue: b} = _.mapValues(rgb, v => v / 255)
    const max = Math.max(r, g, b)
    const delta = max - Math.min(r, g, b)

    const hue = getHue({r, g, b, delta, max})
    const saturation = 100 * (max && delta / max)
    const brightness = 100 * max

    return _.mapValues({hue, saturation, brightness}, Math.round)
}

const hsbToRgb = hsb => {
    const {hue, saturation, brightness} = hsb

    const h = realMod(hue, 360) / 360 * 6
    const s = saturation / 100
    const b = brightness / 100

    const i = Math.floor(h)
    const f = h - i
    const p = b * (1 - s)
    const q = b * (1 - f * s)
    const t = b * (1 - (1 - f) * s)
    const mod = i % 6

    const red = [b, q, p, p, t, b][mod]
    const green = [t, b, b, q, p, p][mod]
    const blue = [p, p, t, b, b, q][mod]

    return _.mapValues({red, green, blue}, v => Math.round(255 * v))
}

const rgbToHex = rgb => '#' + [rgb.red, rgb.green, rgb.blue].map(intToHex).join('')

const hexToHsb = _.flow(hexToRgb, rgbToHsb)
const hsbToHex = _.flow(hsbToRgb, rgbToHex)

const getDistanceToWhite = ({saturation, brightness}) =>
    Math.sqrt(Math.pow(100 - brightness, 2) + Math.pow(saturation, 2))


module.exports = {
    rgbToHsb,
    hexToRgb,
    hsbToRgb,
    rgbToHex,
    hexToHsb,
    hsbToHex,
    getDistanceToWhite
}
