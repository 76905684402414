'use strict';

var React = require('react');
module.exports = function () {
  return React.createElement(
    'svg',
    { width: '20', height: '20', viewBox: '0 0 20 20', xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement(
      'title',
      null,
      'Set Selected'
    ),
    React.createElement(
      'g',
      { fill: '#2b5672', fillRule: 'evenodd' },
      React.createElement('path', { d: 'M10 6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4m0 1a3 3 0 1 1 0 6 3 3 0 0 1 0-6' }),
      React.createElement('path', { d: 'M10 3a7 7 0 1 0 0 14 7 7 0 0 0 0-14m0 1c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6' })
    )
  );
};