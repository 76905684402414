'use strict'
const PropTypes = require('prop-types')
const compose = require('../../hoc/compose')
const template = require('./palettePickerDialog.rt')
const displayNames = require('../displayNames')

const PalettePickerDialog = props => template(props)

PalettePickerDialog.displayName = displayNames.PALETTE_PICKER_DIALOG
PalettePickerDialog.propTypes = {
    textKeys: PropTypes.objectOf(PropTypes.string),
    value: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    palettePresets: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))),
    onChange: PropTypes.func
}
PalettePickerDialog.defaultProps = {
    textKeys: {}
}

module.exports = compose(PalettePickerDialog)
