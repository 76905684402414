'use strict'
const React = require('react')
const compose = require('../hoc/compose')
const displayNames = require('./displayNames')

const illustration = props => {
    const newProps = {
        className: props.getClassName('control-illustration'),
        'data-aid': props.automationId
    }
    return React.createElement('div', newProps, props.children)
}

illustration.displayName = displayNames.ILLUSTRATION

module.exports = compose(illustration)
