'use strict'
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const template = require('./drillInListItem.rt')
const displayNames = require('./displayNames')
const DrillInListItem = template

DrillInListItem.displayName = displayNames.DRILL_IN_LIST_ITEM
DrillInListItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    contentAfter: PropTypes.element,
    disabled: PropTypes.bool
}

DrillInListItem.defaultProps = {
    disabled: false
}

module.exports = compose(DrillInListItem)
