'use strict'

const _ = require('lodash')
const React = require('react')
const displayNames = require('./displayNames')
const TextFormattingToggleButton = require('./textFormattingToggleButton')
const TextFormattingButtonWithDropDown = require('./textFormattingButtonWithDropDown')

const symbols = {
    bold: require('./icons/textBold.svg.js'),
    italic: require('./icons/textItalic.svg.js'),
    underline: require('./icons/textUnderline.svg.js'),
    link: require('./icons/textLink.svg.js'),
    unlink: require('./icons/textUnlink.svg.js'),
    color: require('./textFormattingColor.rt')
}

const buildTextFormattingComp = (name, symbol, defaultProps = {}, CompClass = TextFormattingToggleButton) => {
    const Comp = props => React.createElement(
        CompClass,
        _.defaults({name}, props),
        React.createElement(symbol, props))
    Comp.displayName = name
    Comp.defaultProps = defaultProps
    return Comp
}

module.exports = {
    bold: buildTextFormattingComp(displayNames.TEXT_BOLD_BUTTON, symbols.bold),
    italic: buildTextFormattingComp(displayNames.TEXT_ITALIC_BUTTON, symbols.italic),
    underline: buildTextFormattingComp(displayNames.TEXT_UNDERLINE_BUTTON, symbols.underline),
    link: buildTextFormattingComp(displayNames.TEXT_LINK_BUTTON, symbols.link, {
        hasBackgroundWhenSelected: true,
        options: [
            {value: false, content: React.createElement(symbols.unlink), key: 'false'},
            {value: true, content: React.createElement(symbols.link), key: 'true'}
        ]
    }, TextFormattingButtonWithDropDown),
    color: buildTextFormattingComp(displayNames.TEXT_COLOR_BUTTON, symbols.color, {value: false, onChange: _.noop})
}

