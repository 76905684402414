'use strict'
const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const {buildValidationError} = require('./compositeUtils')
const {validationType} = require('./childValidation')
const displayNames = require('../controls/displayNames')

const getChildDisplayName = child => {
    const getDisplayName = _.get(child, 'type.getDisplayName')
    return getDisplayName ? getDisplayName() : null
}

const getChildrenDisplayNames = children => React.Children.map(children, child => {
    const displayName = getChildDisplayName(child)
    return displayName === displayNames.TOOLTIP ? getChildDisplayName(child.props.children) : displayName
})

const getExtraStyleProps = (props, extraStyleProps) =>
    extraStyleProps ? {style: _.pick(props, extraStyleProps)} : {}

module.exports = compositeDef => {
    const Composite = props => React.createElement('div', _.assign({
        className: [compositeDef.className, props.className].join(' '),
        'data-aid': props.automationId
    }, getExtraStyleProps(props, _.keys(compositeDef.extraStyleProps))), props.children)

    Composite.displayName = compositeDef.displayName
    Composite.getDisplayName = () => compositeDef.displayName
    Composite.propTypes = _.assign({
        className: PropTypes.string,
        automationId: PropTypes.string,
        children(props, propName, componentName) {
            const expectedTypes = compositeDef.childrenTypes
            const actualNames = getChildrenDisplayNames(props.children)

            if (expectedTypes.validation === validationType.ANY) {
                return
            }

            let actualIndex = 0
            for (let i = 0; i < expectedTypes.length; i++) {
                const expectedType = expectedTypes[i].validation
                const expectedNames = expectedTypes[i].names || null
                const actualName = actualNames[actualIndex]

                switch (expectedType) {
                    case validationType.ONCE: {
                        if (!_.includes(expectedNames, actualName)) {
                            return buildValidationError(componentName, expectedTypes)
                        }
                        actualIndex++
                        break
                    }
                    case validationType.OPTIONAL: {
                        if (_.includes(expectedNames, actualName)) {
                            actualIndex++
                        }
                        break
                    }
                    case validationType.MULTIPLE: {
                        if (!_.includes(expectedNames, actualName)) {
                            return buildValidationError(componentName, expectedTypes)
                        }

                        while (_.includes(expectedNames, actualNames[++actualIndex])) {
                            /* empty */
                        }
                        break
                    }
                }
            }

            if (actualNames[actualIndex]) {
                return buildValidationError(componentName, expectedTypes)
            }
        }
    }, compositeDef.extraStyleProps)

    return Composite
}
