'use strict'
const _ = require('lodash')
const positionUtil = require('./position')
const {
    getViewportSize,
    getRectMiddle,
    isCloseOrEqual,
    getMaxAllowedBottom,
    getMaxAllowedRight
} = positionUtil

const CONSTS = {
    INDENT_FROM_EDGE: 12,
    MIN_LIST_HEIGHT: 180,
    SCROLL_KEYS: [37, 38, 39, 40],
    ALIGNMENT: {
        TOP: 'TOP',
        LEFT: 'LEFT',
        BOTTOM: 'BOTTOM',
        RIGHT: 'RIGHT'
    },
    DIRECTION: {
        TOP: 'TOP',
        LEFT: 'LEFT',
        BOTTOM: 'BOTTOM',
        RIGHT: 'RIGHT',
        MIDDLE: 'MIDDLE'
    },
    CHUPCHIK: {
        PADDING: 10,
        POSITION: {
            TOP_LEFT: 'top-left',
            TOP_RIGHT: 'top-right',
            TOP_MIDDLE: 'top-middle',
            BOTTOM_LEFT: 'bottom-left',
            BOTTOM_RIGHT: 'bottom-right',
            BOTTOM_MIDDLE: 'bottom-middle',
            LEFT_TOP: 'left-top',
            LEFT_BOTTOM: 'left-bottom',
            LEFT_MIDDLE: 'left-middle',
            RIGHT_TOP: 'right-top',
            RIGHT_BOTTOM: 'right-bottom',
            RIGHT_MIDDLE: 'right-middle'
        }
    }
}

const getVFit = (bottom, top, viewport) => ({
    bottom: bottom < getMaxAllowedBottom(viewport),
    top: top > CONSTS.INDENT_FROM_EDGE
})

const getHFit = (right, left, viewport) => ({
    right: right < getMaxAllowedRight(viewport),
    left: left > CONSTS.INDENT_FROM_EDGE
})


const pickVRange = (preference, fit, top, bottom) => {
    if (preference === bottom) {
        return !fit.bottom && fit.top ? top : bottom
    }
    return !fit.top && fit.bottom ? bottom : top
}

const pickHRange = (preference, fit, left, right) => {
    if (preference === right) {
        return !fit.right && fit.left ? left : right
    }
    return !fit.left && fit.right ? right : left
}

const pickRange = ({min, max, low, high, alignment, direction, axis, viewport}) => {
    const {ALIGNMENT, DIRECTION} = CONSTS
    const getFit = axis === 'X' ? getHFit : getVFit
    const pick = axis === 'X' ? pickHRange : pickVRange
    let forward

    if (direction) { //eslint-disable-line wix-editor/prefer-ternary
        forward = axis === 'X' ? DIRECTION.RIGHT : DIRECTION.BOTTOM
    } else {
        forward = axis === 'X' ? ALIGNMENT.RIGHT : ALIGNMENT.BOTTOM
    }

    const fit = getFit(max, min, viewport)
    const preference = (direction || alignment) === forward ? high : low

    return pick(preference, fit, low, high)
}

const getHRange = (viewport, buttonRect, optionsRect, params = {}) => {
    const {ALIGNMENT, DIRECTION} = CONSTS
    const alignment = params.alignment || ALIGNMENT.RIGHT
    const direction = params.direction || DIRECTION.BOTTOM

    const isHorizontalAlignment = alignment === ALIGNMENT.RIGHT || alignment === ALIGNMENT.LEFT
    const isMiddleDirection = direction !== DIRECTION.LEFT && direction !== DIRECTION.RIGHT
    const buttonRight = buttonRect.x + buttonRect.width
    const buttonMiddle = getRectMiddle(buttonRect).x

    const alignmentLimit = {
        min: buttonRect.x - optionsRect.width,
        max: buttonRight + optionsRect.width
    }
    const directionLimit = {
        min: buttonRight - optionsRect.width + CONSTS.CHUPCHIK.PADDING,
        max: buttonRect.x + optionsRect.width - CONSTS.CHUPCHIK.PADDING
    }

    const hRangeForAlignmentRight = {
        max: alignmentLimit.max,
        min: buttonRight
    }
    const hRangeForAlignmentLeft = {
        max: buttonRect.x,
        min: alignmentLimit.min
    }
    const hRangeForDirectionRight = {
        min: buttonRect.x - CONSTS.CHUPCHIK.PADDING,
        max: directionLimit.max
    }
    const hRangeForDirectionLeft = {
        min: directionLimit.min,
        max: buttonRight + CONSTS.CHUPCHIK.PADDING
    }
    const hRangeForDirectionMiddle = {
        min: buttonMiddle - optionsRect.width / 2,
        max: buttonMiddle + optionsRect.width / 2
    }

    const rangeByAlignment = {
        min: alignmentLimit.min,
        max: alignmentLimit.max,
        low: hRangeForAlignmentLeft,
        high: hRangeForAlignmentRight,
        axis: 'X',
        alignment,
        viewport
    }
    const rangeByDirection = {
        min: directionLimit.min,
        max: directionLimit.max,
        low: hRangeForDirectionLeft,
        high: hRangeForDirectionRight,
        axis: 'X',
        direction,
        viewport
    }

    if (!isHorizontalAlignment && isMiddleDirection) {
        return hRangeForDirectionMiddle
    }

    return pickRange(isHorizontalAlignment ? rangeByAlignment : rangeByDirection)
}

function getVRange(viewport, buttonRect, optionsRect, params = {}) {
    const {ALIGNMENT, DIRECTION} = CONSTS
    const alignment = params.alignment || ALIGNMENT.RIGHT
    const direction = params.direction || DIRECTION.BOTTOM
    const isHorizontalAlignment = alignment === ALIGNMENT.RIGHT || alignment === ALIGNMENT.LEFT
    const buttonBottom = buttonRect.y + buttonRect.height

    const directionLimit = {
        min: buttonBottom - optionsRect.height + CONSTS.CHUPCHIK.PADDING,
        max: buttonRect.y + optionsRect.height - CONSTS.CHUPCHIK.PADDING
    }
    const alignmentLimit = {
        min: buttonRect.y - optionsRect.height,
        max: buttonBottom + optionsRect.height
    }

    const vRangeForDirectionBottom = {
        min: buttonRect.y - CONSTS.CHUPCHIK.PADDING,
        max: directionLimit.max
    }
    const vRangeForDirectionTop = {
        min: directionLimit.min,
        max: buttonBottom + CONSTS.CHUPCHIK.PADDING
    }
    const vRangeForAlignmentBottom = {
        min: buttonBottom,
        max: alignmentLimit.max
    }
    const vRangeForAlignmentTop = {
        min: alignmentLimit.min,
        max: buttonRect.y
    }

    const rangeByDirection = {
        min: directionLimit.min,
        max: directionLimit.max,
        low: vRangeForDirectionTop,
        high: vRangeForDirectionBottom,
        axis: 'Y',
        direction,
        viewport
    }
    const rangeByAlignment = {
        min: alignmentLimit.min,
        max: alignmentLimit.max,
        low: vRangeForAlignmentTop,
        high: vRangeForAlignmentBottom,
        axis: 'Y',
        alignment,
        viewport
    }

    return pickRange(isHorizontalAlignment ? rangeByDirection : rangeByAlignment)
}

function getOptionsPosition(viewport, buttonRect, optionsRect, params = {}) { //eslint-disable-line no-unused-vars
    const hRange = getHRange(...arguments)
    const vRange = getVRange(...arguments)

    const optionsPosition = {
        x: hRange.min,
        width: hRange.max - hRange.min,
        y: vRange.min,
        height: vRange.max - vRange.min
    }

    return _.mapValues(optionsPosition, Math.ceil)
}

const getArrowPosition = (buttonRect, optionsPosition) => {
    const {POSITION} = CONSTS.CHUPCHIK
    const buttonMiddle = getRectMiddle(buttonRect)
    const optionsMiddle = getRectMiddle(optionsPosition)

    const alignedRight = isCloseOrEqual(buttonRect.x + buttonRect.width, optionsPosition.x)
    const alignedLeft = isCloseOrEqual(buttonRect.x, optionsPosition.x + optionsPosition.width)
    const alignedTop = !(alignedLeft || alignedRight) && buttonRect.y > optionsPosition.y

    const directedBottom = isCloseOrEqual(buttonRect.y - CONSTS.CHUPCHIK.PADDING, optionsPosition.y)
    const directedLeft = isCloseOrEqual(buttonRect.x + buttonRect.width, optionsPosition.x + optionsPosition.width - CONSTS.CHUPCHIK.PADDING)
    const directedMiddleHorizontally = isCloseOrEqual(buttonMiddle.y, optionsMiddle.y)
    const directedMiddleVertically = isCloseOrEqual(buttonMiddle.x, optionsMiddle.x)

    if (alignedRight) {
        if (directedMiddleHorizontally) {
            return POSITION.LEFT_MIDDLE
        }
        return directedBottom ? POSITION.LEFT_TOP : POSITION.LEFT_BOTTOM

    } else if (alignedLeft) {
        if (directedMiddleHorizontally) {
            return POSITION.RIGHT_MIDDLE
        }
        return directedBottom ? POSITION.RIGHT_TOP : POSITION.RIGHT_BOTTOM

    } else if (alignedTop) {
        if (directedMiddleVertically) {
            return POSITION.BOTTOM_MIDDLE
        }
        return directedLeft ? POSITION.BOTTOM_RIGHT : POSITION.BOTTOM_LEFT
    }
    if (directedMiddleVertically) {
        return POSITION.TOP_MIDDLE
    }
    return directedLeft ? POSITION.TOP_RIGHT : POSITION.TOP_LEFT


}

module.exports = {
    CONSTS,
    getViewportSize,
    getOptionsPosition,
    getArrowPosition
}
