'use strict';
var React = require('react');
var _ = require('lodash');
var ItemRowBase = require('./itemRowBase');
var TextEdit = require('./textEdit');
var ContextMenu = require('../contextMenu');
var ContextMenuAction = require('../contextMenuAction');
var Symbol = require('../symbol');
var TooltipOnEllipsis = require('../tooltipOnEllipsis');
module.exports = function () {
    return React.createElement('div', {
        'className': this.getRowClasses(),
        'data-aid': this.props.automationId,
        'onMouseEnter': this.onHover
    }, React.createElement(ItemRowBase, {
        'dragHandle': this.shouldShowDragHandle(),
        'dragHandleEnabled': this.props.dragHandleEnabled,
        'isDragging': this.props.isDragging,
        'dragHandleTooltipContent': this.props.dragHandleTooltipContent
    }, !this.isEditMode() ? React.createElement('div', {
        'className': 'view-mode-content',
        'key': 'labelContainer',
        'onMouseUp': this.select
    }, React.createElement('div', {
        'className': 'text-container',
        'onDoubleClick': this.onDoubleClick
    }, this.props.contentBefore ? React.createElement('div', {
        'className': 'content-before',
        'key': 'contentBefore'
    }, this.props.contentBefore) : null, React.createElement('div', {
        'className': 'text-inner-content',
        'style': { width: this.getTextWidth() }
    }, React.createElement(TooltipOnEllipsis, {}, React.createElement('div', {
        'className': _(this.getLabelClasses()).transform(function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ')
    }, this.getLabelToDisplay())), this.hasValue() && this.isMultiline() ? React.createElement('div', {
        'className': _(this.getValueClasses()).transform(function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' '),
        'key': 'value'
    }, this.getValueToDisplay()) : null, this.hasValue() && !this.isMultiline() ? React.createElement(TooltipOnEllipsis, { 'key': 'valueWithEllipsis' }, React.createElement('div', {
        'className': _(this.getValueClasses()).transform(function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ')
    }, this.getValueToDisplay())) : null), this.props.contentAfter ? React.createElement('div', {
        'className': 'content-after',
        'key': 'contentAfter'
    }, this.props.contentAfter) : null), React.createElement('div', { 'className': 'context-menu-container' }, this.hasContextMenu() ? React.createElement(ContextMenu, {
        'className': 'flat',
        'isOpen': this.props.isContextMenuOpen,
        'onToggle': this.props.onContextMenuToggle,
        'direction': this.props.contextMenuDirection,
        'alignment': this.props.contextMenuAlignment,
        'key': 'sbdlContextMenu'
    }, this.getAllContextMenuActions()) : null)) : null, this.editLabelMode() ? React.createElement(TextEdit, {
        'className': 'text-edit-container label-text-edit',
        'key': 'sbdlLabelTextEdit',
        'keyboardShortcuts': true,
        'autoFocus': true,
        'value': this.props.value.label,
        'placeholder': this.getLabelPlaceHolder(),
        'maxLength': this.getLabelMaxLength(),
        'validators': this.props.labelValidators,
        'onChange': this.updateLabelAndCloseEditMode,
        'cancel': this.closeEditLabelMode
    }) : null, this.editValueMode() ? React.createElement(TextEdit, {
        'className': 'text-edit-container value-text-edit',
        'key': 'sbdlValueTextEdit',
        'keyboardShortcuts': true,
        'autoFocus': true,
        'value': this.props.value.value,
        'placeholder': this.getValuePlaceHolder(),
        'maxLength': this.getValueMaxLength(),
        'validators': this.props.valueValidators,
        'onChange': this.updateValueAndCloseEditMode,
        'cancel': this.closeEditValueMode
    }) : null));
};