'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const compose = require('../hoc/compose')
const template = require('./toggle.rt')
const displayNames = require('./displayNames')

class Toggle extends React.Component {
    constructor(props) {
        super(props)
        this.handleChange = () => {
            if (!this.props.value && this.props.onCheck) {
                this.props.onCheck()
            }
            this.props.onChange(!this.props.value)
        }
    }
    render() {
        return template.call(this)
    }
}

Toggle.displayName = displayNames.TOGGLE
Toggle.propTypes = {
    name: PropTypes.string,
    disabled: PropTypes.bool,
    indeterminate: PropTypes.bool,
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    onCheck: PropTypes.func
}
Toggle.defaultProps = {
    disabled: false,
    indeterminate: false
}

module.exports = compose(Toggle)
