'use strict';
var React = require('react');
var _ = require('lodash');
module.exports = function () {
    return React.createElement('button', {
        'className': this.props.getClassName('control-button'),
        'data-aid': this.props.automationId,
        'disabled': this.props.disabled ? 'disabled' : '',
        'onClick': this.props.onClick
    }, React.createElement('div', { 'className': 'button-content' }, this.props.children));
};