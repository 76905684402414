'use strict';
var React = require('react');
var _ = require('lodash');
module.exports = function () {
    return React.createElement('span', {
        'className': this.props.getClassName('has-tooltip'),
        'data-aid': this.props.automationId,
        'onClick': this.onClick,
        'onMouseEnter': this.onMouseEnter,
        'onMouseLeave': this.onMouseLeave
    }, this.props.customTrigger && !this.props.displayCustomTrigger ? null : this.props.children);
};