'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const displayNames = require('./displayNames')
const compose = require('../hoc/compose')
const template = require('./fontFamilyPicker.rt')

const DEFAULT_EXAMPLES = {
    latin: '',
    'latin-ext': 'Gałąź',
    cyrillic: 'Пример',
    japanese: 'あア愛Aa',
    korean: '미리보기',
    arabic: 'مثال',
    hebrew: 'דוגמא'
}

const DEFAULT_FONT_GROUPS = {
    'my-uploads': 'My Fonts',
    latin: 'All Fonts',
    'latin-ext': 'Eastern European',
    cyrillic: 'Cyrillic',
    japanese: 'Japanese',
    korean: 'Korean',
    arabic: 'Arabic',
    hebrew: 'Hebrew'
}

class FontFamilyPicker extends React.Component {
    constructor(props) {
        super(props)

        this.fontExamplesMap = _.defaults(props.fontExamplesMap, DEFAULT_EXAMPLES)
        this.fontGroupsNamesMap = _.defaults(props.fontGroupsNamesMap, DEFAULT_FONT_GROUPS)

        this.state = {
            fontsItems: this.getFontsItems(this.props.fonts)
        }
    }

    getMissingFontName() {
        if (this.isMissingFont()) {
            return this.props.getMissingFontName(this.props.value)
        }
    }

    getFontsItems(fontsArr) {
        const fontItems = []

        _.forEach(fontsArr, lang => {
            fontItems.push({
                groupName: this.fontGroupsNamesMap[lang.lang]
            })

            _.forEach(lang.fonts, font => {
                if (font.permissions !== 'legacy') {

                    const fontItem = {
                        label: font.displayName,
                        value: font.fontFamily,
                        example: this.fontExamplesMap[lang.lang],
                        cssFontFamily: font.cssFontFamily
                    }

                    fontItems.push(fontItem)
                }
            })
        })

        return fontItems
    }

    isMissingFont() {
        return !_.find(this.state.fontsItems, {value: this.props.value})
    }

    componentWillReceiveProps(nextProps) {
        this.fontExamplesMap = _.defaults(nextProps.fontExamplesMap, DEFAULT_EXAMPLES)
        this.fontGroupsNamesMap = _.defaults(nextProps.fontGroupsNamesMap, DEFAULT_FONT_GROUPS)

        if (!_.isEqual(this.props.fonts, nextProps.fonts)) {
            this.setState({fontsItems: this.getFontsItems(nextProps.fonts)})
        }
    }

    render() {
        return template.call(this)
    }
}

FontFamilyPicker.displayName = displayNames.FONT_FAMILY_PICKER

FontFamilyPicker.defaultProps = {
    toolbarMode: false
}

FontFamilyPicker.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onToggle: PropTypes.func,
    footerLinks: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        symbol: PropTypes.element
    })),
    fonts: PropTypes.arrayOf(PropTypes.shape({
        fonts: PropTypes.arrayOf(PropTypes.shape({
            cssFontFamily: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            fontFamily: PropTypes.string.isRequired,
            permissions: PropTypes.string
        }).isRequired),
        lang: PropTypes.string
    }).isRequired),
    getMissingFontName: PropTypes.func.isRequired,
    toolbarMode: PropTypes.bool,
    shouldTranslate: PropTypes.bool,
    fontGroupsNamesMap: PropTypes.object,
    fontExamplesMap: PropTypes.object,
    disabled: PropTypes.bool
}

module.exports = compose(FontFamilyPicker)
