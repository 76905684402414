'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const compose = require('../../hoc/compose')
const displayNames = require('../displayNames')

const getClassNameByProps = props => _.compact([
    props.getClassName('color-picker-input-ctrl'),
    props.disabled ? 'disabled' : null,
    props.selected ? 'selected' : null
]).join(' ')

const ColorPickerInput = props => React.createElement('div', {
    onClick: props.disabled ? _.noop : props.onClick,
    className: getClassNameByProps(props),
    style: {
        backgroundColor: props.value
    }
})

ColorPickerInput.displayName = displayNames.COLOR_PICKER_INPUT
ColorPickerInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func
}
ColorPickerInput.defaultProps = {
    value: 'white',
    onClick: _.noop
}

module.exports = compose(ColorPickerInput)
