'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const template = require('./sortByDragListBase.rt')
const compose = require('../../hoc/compose')

const LEFT_BUTTON_QUERY = 0

const shouldIgnoreMouseEvent = e => e.button !== LEFT_BUTTON_QUERY

class SortByDragListBase extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            placeHolderIndex: -1,
            items: null
        }

        this.getTransitionClass = () => this.props.childrenTransition || React.DOM.div

        this.getItems = () => {
            if (this.state.items) {
                const placeholder = React.createElement('div', {
                    className: this.props.dragPlaceholderCssClass || 'drag-placeholder',
                    key: 'placeholder',
                    ref: 'placeholder',
                    style: {
                        width: this.clientRect.width + 'px',
                        height: this.clientRect.height + 'px'

                    }
                })
                const items = this.state.items.slice()
                items[this.fromIndex] = placeholder
                if (this.fromIndex !== this.state.placeHolderIndex) {
                    const itemToMove = items.splice(this.fromIndex, 1)[0]
                    items.splice(this.state.placeHolderIndex, 0, itemToMove)
                }

                return items
            }
            return this.props.children
        }

        this.initDragProcess = (itemIndex, event) => {
            window.document.addEventListener('mousemove', this.onMouseMove)
            window.document.addEventListener('mouseup', this.onMouseUp, true)

            this._dragProcessStarted = true
            this._realDragStarted = false
            this.fromIndex = itemIndex
            this.toIndex = this.fromIndex
            this.dragged = event.currentTarget //check target vs currentTarget
            this.parentNode = this.dragged.parentNode
            this.clientRect = this.dragged.getBoundingClientRect()
            this.originalTop = this.dragged.offsetTop
            this.initParentTop = this.parentNode.getBoundingClientRect().top
            this.currentY = this.clientRect.top
            this.currentBottomY = this.clientRect.bottom
            this.clientYOnStart = event.clientY
            this.deltaY = this.clientYOnStart - this.clientRect.top
            this.maxTop = this.parentNode.clientHeight - this.clientRect.height
        }

        this.endDragProcess = event => {
            if (!this._dragProcessStarted) {
                return
            }

            window.document.removeEventListener('mousemove', this.onMouseMove)
            window.document.removeEventListener('mouseup', this.onMouseUp, true)

            if (this._realDragStarted) {
                this.endRealDrag()
                event.stopPropagation()
            }
        }

        this.startRealDrag = function () {
            this._realDragStarted = true

            if (_.isFunction(this.props.beforeDragStart)) {
                this.props.beforeDragStart(this.fromIndex)
            }

            let items = this.props.children.slice()
            const dragged = items.splice(this.fromIndex, 1, {thisitem: 'is a placeholder for the placeholder'})
            items = items.concat(dragged)

            this.setState({
                placeHolderIndex: this.fromIndex,
                items
            }, () => {
                this.dragged.style.position = 'absolute'
                this.dragged.style.width = '100%'
                this.dragged.style.top = this.originalTop + 'px'
            })
        }

        this.endRealDrag = function () {
            this.dragged.style.position = 'relative'
            this.dragged.style.top = ''

            const data = this.props.children.slice()
            const itemToMove = data.splice(this.fromIndex, 1)[0]
            data.splice(this.toIndex, 0, itemToMove)

            this.setState({
                placeHolderIndex: -1,
                items: null
            }, function () {
                if (_.isFunction(this.props.onItemMoved) && this.fromIndex !== this.toIndex) {
                    this.props.onItemMoved(this.fromIndex, this.toIndex)
                }

                if (_.isFunction(this.props.afterDragEnd)) {
                    this.props.afterDragEnd(this.fromIndex, this.toIndex)
                }
            }.bind(this))
        }

        this.onMouseDown = (itemIndex, event) => {
            if (!this.props.draggable) {
                return
            }

            if (shouldIgnoreMouseEvent(event)) {
                return this.endDragProcess(event)
            }

            return this.initDragProcess(itemIndex, event)
        }

        this.onMouseMove = event => {
            if (!this._realDragStarted) {
                this.startRealDrag()
            }

            const currentParentTop = this.parentNode.getBoundingClientRect().top
            const scrollOffset = this.initParentTop - currentParentTop
            const mouseDeltaY = event.clientY - this.clientYOnStart
            this.dragged.style.top = Math.min(Math.max(mouseDeltaY + this.originalTop + scrollOffset, 0), this.maxTop) + 'px'

            const children = this.parentNode.children
            const nextRealSiblingInList = this.toIndex + 1 !== this.state.items.length && children[this.toIndex + 1] !== this.dragged && children[this.toIndex + 1]
            const prevRealSiblingInList = this.toIndex > 0 && children[this.toIndex - 1] !== this.dragged && children[this.toIndex - 1]
            const shouldSwitchWithNext = Boolean(event.clientY > this.currentBottomY && nextRealSiblingInList)
            const shouldSwitchWithPrev = Boolean(event.clientY < this.currentY && prevRealSiblingInList)


            if (shouldSwitchWithNext) {
                this.currentBottomY = nextRealSiblingInList.getBoundingClientRect().bottom
                this.currentY = this.currentBottomY - this.clientRect.height
                this.toIndex += 1
            } else if (shouldSwitchWithPrev) {
                this.currentY = prevRealSiblingInList.getBoundingClientRect().top
                this.currentBottomY = this.currentY + this.clientRect.height
                this.toIndex -= 1

            }

            if (shouldSwitchWithNext || shouldSwitchWithPrev) {
                const placeHolderIndex = this.toIndex
                this.setState({
                    placeHolderIndex
                })
            }

            event.stopPropagation()

        }

        this.onMouseUp = event => this.endDragProcess(event)

    }

    render() {
        return template.call(this)
    }

}

SortByDragListBase.displayName = 'SortByDragListBase'

SortByDragListBase.propTypes = {
    childrenTransition: PropTypes.func, // React.addons.TransitionGroup class
    transitionProps: PropTypes.object, // The props for the TransitionGroup component,
    draggable: PropTypes.bool,
    onItemMoved: PropTypes.func.isRequired,
    beforeDragStart: PropTypes.func,
    afterDragEnd: PropTypes.func,
    dragPlaceholderCssClass: PropTypes.string
}

SortByDragListBase.defaultProps = {
    draggable: true
}

module.exports = compose(SortByDragListBase)
