'use strict'

const CONSTS = {
    INDENT_FROM_EDGE: 12
}

const getViewportSize = () => ({
    height: window.document.documentElement.clientHeight,
    width: window.document.documentElement.clientWidth
}) // TODO: keep window out of this file

const getRectMiddle = rect => ({
    y: Math.floor(rect.y + rect.height / 2),
    x: Math.floor(rect.x + rect.width / 2)
})

const isCloseOrEqual = (a, b) => Math.abs(a - b) < 2

const getMaxAllowedBottom = viewport => viewport.height - CONSTS.INDENT_FROM_EDGE
const getMaxAllowedRight = viewport => viewport.width - CONSTS.INDENT_FROM_EDGE

module.exports = {
    getViewportSize,
    getRectMiddle,
    isCloseOrEqual,
    getMaxAllowedBottom,
    getMaxAllowedRight
}
