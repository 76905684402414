'use strict';
var React = require('react');
var _ = require('lodash');
var baseUILibButton = require('../controls/button.js');
var CloseButton = require('../controls/closeButton.js');
var HelpButton = require('../controls/helpButton.js');
module.exports = function (props, context) {
    return React.createElement('header', {
        'className': props.getClassName('panel-header-centered-text'),
        'data-aid': props.automationId
    }, React.createElement('div', { 'className': 'title ' + props.titleType.toLowerCase() }, props.translateIfNeeded(props.title)), props.subtitle ? React.createElement('div', {
        'className': 'subtitle',
        'key': 'subtitle'
    }, props.translateIfNeeded(props.subtitle)) : null, props.onHelp ? React.createElement(HelpButton, {
        'onClick': props.onHelp,
        'key': 'help'
    }) : null, props.onClose ? React.createElement(CloseButton, {
        'onClick': props.onClose,
        'key': 'close'
    }) : null);
};