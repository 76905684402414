'use strict'
const _ = require('lodash')
const React = require('react')
const withClickOutside = require('./withClickOutside')
const commonPropTypes = require('../common/propTypes')

const toValidatorsArray = v => {
    if (_.isArray(v)) {
        return v
    } else if (v) {
        return [v]
    }
    return []
}

const withValidation = Component => {

    class Validated extends React.Component {
        constructor(props) {
            super(props)

            this.getPropsValue = otherProps =>
                _.get(otherProps || this.props, 'valueLink.value',
                    otherProps ? otherProps.value : this.props.value)

            this.state = {
                isValid: null,
                valueCandidate: this.getPropsValue(),
                successValue: this.getPropsValue(),
                wasSuccess: false,
                tooltipIsOpen: this.props.isValid === false,
                isSuccess: false
            }

            this.closeTooltip = () => {
                if (this.state.tooltipIsOpen) {
                    this.setState({tooltipIsOpen: false})
                }
            }

            this.openTooltipIfNeeded = (currentProps = this.props) => {
                this.setState({tooltipIsOpen: !this.isValid(currentProps)})
            }

            this.props.onClickOutside(this.closeTooltip)

            this.validateSync = value => {
                const validators = toValidatorsArray(this.props.validator)

                if (_.isEmpty(validators)) {
                    return true
                }

                return _.every(validators, validator => validator(value))
            }

            this.validateAsync = (value, onSuccess, onFailure) => {
                const asyncValidators = toValidatorsArray(this.props.asyncValidator)

                if (_.isEmpty(asyncValidators)) {
                    onSuccess()
                } else {
                    const successResults = {}
                    const validatorsNumber = _.size(asyncValidators)
                    let successCounter = 0
                    let hasFailedValidation = false

                    _.forEach(asyncValidators, (validator, validatorName) => {
                        const onValidatorSuccess = result => {
                            if (result) {
                                successResults[validatorName] = result
                            }

                            successCounter++
                            const isDone = successCounter === validatorsNumber

                            if (isDone) {
                                onSuccess(successResults)
                            }
                        }

                        const onValidatorError = errorMessage => {
                            if (!hasFailedValidation) {
                                hasFailedValidation = true
                                onFailure(errorMessage)
                            }
                        }

                        validator(value, onValidatorSuccess, onValidatorError)
                    })
                }
            }

            // TODO: handle messages array or functions resolve
            this.getMessage = () => this.syncPassed && !this.shouldEnforceValidationStatus() ? this.state.invalidMessage : this.props.invalidMessage

            this.validate = value => {
                const onSuccess = _.partial(this.onValidationSuccess, value)
                const onFailure = _.partial(this.onValidationFailed, value)

                if (this.validateSync(value)) {
                    this.syncPassed = true
                    if (!this.props.asyncValidator) {
                        onSuccess()
                        return
                    }
                    this.validateAsync(value, onSuccess, onFailure)
                } else {
                    this.syncPassed = false
                    onFailure(this.getMessage())
                }
            }

            const delay = this.props.asyncValidator ? this.props.asyncDelay : this.props.syncDelay
            if (delay) {
                this.validate = _.debounce(this.validate, delay)
            }

            this.showSuccess = () => {
                if (this.isValid() && (!this.state.wasSuccess || this.state.successValue !== this.state.valueCandidate)) {
                    this.setState({
                        wasSuccess: true,
                        isSuccess: true,
                        successValue: this.state.valueCandidate
                    })
                    this.successTimer = window.setTimeout(() => this.setState({isSuccess: false}), 1000)
                }
            }

            this.onFocus = e => {
                if (_.isFunction(this.props.onFocus)) {
                    this.props.onFocus(e)
                }
                if (!(this.props.validateInitialValue && !this.valueChanged) || this.shouldEnforceValidationStatus()) {
                    this.openTooltipIfNeeded()
                }
            }

            this.onBlur = (e, isCanceled) => {
                this.closeTooltip()
                if (_.isFunction(this.props.onBlur)) {
                    this.props.onBlur(e, isCanceled)
                }
                if (!isCanceled) {
                    this.showSuccess()
                }
            }

            const onChange = _.get(this, 'props.valueLink.requestChange', this.props.onChange)

            this.onValidationFailed = (val, errorMessage) => {
                this.setState({
                    isValid: false,
                    wasSuccess: false,
                    tooltipIsOpen: !this.isInitialValidation || this.isInitialValidation && this.props.tooltipOnInitialValidation,
                    invalidMessage: errorMessage || this.props.invalidMessage
                })
                if (this.props.allowInvalidChange && _.isFunction(onChange)) {
                    onChange(val, false)
                }
                if (_.isFunction(this.props.onValidationStatus)) {
                    this.props.onValidationStatus(false)
                }
                this.isInitialValidation = false
            }

            this.onValidationSuccess = val => {
                this.setState({
                    isValid: true,
                    tooltipIsOpen: false,
                    invalidMessage: null
                })
                this.showSuccess()
                if (_.isFunction(onChange)) {
                    onChange(val, true)
                }
                if (_.isFunction(this.props.onValidationStatus)) {
                    this.props.onValidationStatus(true)
                }
                this.isInitialValidation = false
            }

            this.onValueStateChange = value => {
                this.valueChanged = true
                this.setState({valueCandidate: value}, () => {
                    if (this.shouldValidate()) {
                        this.validate(value)
                    } else {
                        onChange(value)
                    }
                })
            }

            this.shouldEnforceValidationStatus = (currentProps = this.props) => _.isBoolean(currentProps.isValid)

            this.shouldValidate = (currentProps = this.props) => !this.shouldEnforceValidationStatus(currentProps) && currentProps.validator || currentProps.asyncValidator

            this.isValid = (currentProps = this.props, currentState = this.state) => this.shouldEnforceValidationStatus(currentProps) ? currentProps.isValid : currentState.isValid

            this.getValidationProps = () => {
                const shouldValidate = this.shouldValidate()
                const shouldEnforceValidationStatus = this.shouldEnforceValidationStatus()

                if (!(shouldEnforceValidationStatus || shouldValidate)) {
                    return this.props
                }

                return _.assign({}, this.props, {
                    invalidMessage: this.isValid() === false ? this.getMessage() : '',
                    tooltipIsOpen: this.state.tooltipIsOpen,
                    onFocus: this.onFocus,
                    onChange: this.onValueStateChange,
                    value: this.state.valueCandidate,
                    success: this.state.isSuccess,
                    successValue: this.state.successValue,
                    onBlur: this.onBlur
                })
            }
        }

        componentWillMount() {
            const isValid = this.isValid()
            if (isValid !== null && _.isFunction(this.props.onValidationStatus)) {
                this.props.onValidationStatus(isValid)
            }
        }

        componentDidMount() {
            window.addEventListener('wheel', this.closeTooltip)
            window.addEventListener('scroll', this.closeTooltip)
            if (this.props.validateInitialValue && this.shouldValidate()) {
                this.isInitialValidation = true
                const value = this.state.valueCandidate
                this.validate(value)
            } else if (this.shouldEnforceValidationStatus()) {
                this.tooltipTimer = window.setTimeout(() => this.openTooltipIfNeeded(), 0)
            }
        }

        componentWillUnmount() {
            window.removeEventListener('wheel', this.closeTooltip)
            window.removeEventListener('scroll', this.closeTooltip)
            window.clearTimeout(this.successTimer)
            window.clearTimeout(this.tooltipTimer)
            if (this.props.cancelValidationOnUnmount && _.isFunction(this.validate.cancel)) {
                this.validate.cancel()
            }
        }

        componentWillReceiveProps(nextProps) {
            const nextValue = this.getPropsValue(nextProps)
            const currentValue = this.getPropsValue()
            if (nextValue !== this.state.valueCandidate && nextValue !== currentValue) {
                this.setState({valueCandidate: nextValue}, () => {
                    if (this.shouldValidate() && this.props.validateOnValuePropChange) {
                        this.validate(this.state.valueCandidate)
                    } else if (this.shouldEnforceValidationStatus() && this.props.validateOnValuePropChange) {
                        this.openTooltipIfNeeded(nextProps)
                    }
                })
            } else if (nextProps.isValid !== this.props.isValid) {
                this.openTooltipIfNeeded(nextProps)
            }

            if (this.isValid(nextProps) !== this.isValid(this.props)) {
                if (_.isFunction(this.props.onValidationStatus)) {
                    this.props.onValidationStatus(this.isValid(nextProps))
                }
                this.setState({wasSuccess: this.isValid(this.props, this.state)})
            }
        }

        render() {
            const props = this.getValidationProps()

            return React.createElement(Component, props)
        }
    }

    Validated.propTypes = commonPropTypes.VALIDATED_PROP_TYPES

    Validated.defaultProps = {
        allowInvalidChange: false,
        validateInitialValue: true,
        tooltipOnInitialValidation: false,
        validateOnValuePropChange: true,
        syncDelay: 50,
        asyncDelay: 300,
        cancelValidationOnUnmount: true
    }

    return withClickOutside(Validated)

}

module.exports = withValidation
