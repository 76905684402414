'use strict'
const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const template = require('./thumbnailColorInput.rt')
const displayNames = require('./displayNames')

const ColoredDiv = props => React.createElement('div', {
    className: 'control-thumbnail-color-input-item ' + (props.isSelected ? 'selected-item' : ''),
    style: {
        backgroundColor: props.color
    }
})

class ThumbnailColorInput extends React.Component {
    constructor(props) {
        super(props)

        this.getOptions = () => _.map(this.props.options, (opt, i) => _.defaults({
            illustration: React.createElement(ColoredDiv, {
                color: opt.color,
                isSelected: this.props.selectedIndex === i
            }),
            onClick: () => opt.onClick(opt, i)
        }, opt))
    }

    render() {
        return template.call(this)
    }
}

ThumbnailColorInput.displayName = displayNames.THUMBNAIL_COLOR_INPUT
ThumbnailColorInput.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        color: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool
    })).isRequired,
    selectedIndex: PropTypes.number,
    maxThumbsPerRow: PropTypes.number.isRequired
}

module.exports = compose(ThumbnailColorInput)
