'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const compose = require('../hoc/compose')
const template = require('./promotionalList.rt')
const checkSymbol = require('./check.svg.js')
const displayNames = require('./displayNames')

class PromotionalList extends React.Component {
    render() {
        return template.call(this)
    }
}

PromotionalList.displayName = displayNames.PROMOTIONAL_LIST
PromotionalList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.string),
    symbol: PropTypes.element
}
PromotionalList.defaultProps = {
    symbol: React.createElement(checkSymbol)
}

module.exports = compose(PromotionalList)
