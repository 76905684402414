'use strict';

var React = require('react');
module.exports = function () {
  return React.createElement(
    'svg',
    { width: '25', height: '25', viewBox: '0 0 25 25', className: 'symbol-input-validation-error' },
    React.createElement('circle', { cx: '13', cy: '12', r: '12' }),
    React.createElement('circle', { className: 'c2', cx: '13', cy: '17', r: '1' }),
    React.createElement('path', { className: 'c1', fillRule: 'evenodd', d: 'M13 7c.55 0 1 .45 1 1v5c0 .55-.45 1-1 1s-1-.45-1-1V8c0-.55.45-1 1-1z' })
  );
};