'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const compose = require('../hoc/compose')
const displayNames = require('./displayNames')
const textTypes = require('./textTypes')

const RichText = props =>
    React.createElement('div', {
        className: props.getClassName(`control-rich-text ${props.type.toLowerCase()}`),
        'data-aid': props.automationId
    }, props.children)

RichText.displayName = displayNames.RICH_TEXT
RichText.propTypes = {
    children: PropTypes.any.isRequired,
    type: PropTypes.oneOf(textTypes.TYPES)
}

RichText.defaultProps = {
    type: textTypes.DEFAULT_TYPE
}

module.exports = compose(RichText)
