'use strict';
var React = require('react');
var _ = require('lodash');
var tooltip = require('./tooltip');
var inputValidationErrorSymbol = require('./inputValidationError.svg.js');
var inputValidationSuccessSymbol = require('./inputValidationSuccess.svg.js');
var TextLabel = require('./textLabel');
module.exports = function () {
    return React.createElement('div', {
        'className': this.props.getClassName(this.getRootCssClasses()),
        'data-aid': this.props.automationId,
        'onClick': this.props.onClick
    }, this.props.prefix ? React.createElement('label', {
        'htmlFor': this.uniqueId,
        'key': 'inputPrefix',
        'className': 'input-prefix' + (this.props.focusOnPrefixClick ? ' clickable' : '')
    }, React.createElement(TextLabel, {
        'value': this.props.prefix,
        'shouldTranslate': this.props.shouldTranslate
    })) : null, this.refs.input && this.props.showInfoTooltip ? React.createElement(tooltip, _.assign({}, {
        'key': 'input-info',
        'className': 'input-info',
        'customTrigger': this.refs.input
    }, this.props.infoTooltipProps)) : null, React.createElement('input', this.getInputProps()), this.props.inputTextSuffix ? React.createElement('span', {
        'className': 'input-text-suffix',
        'key': 'inputTextSuffix'
    }, this.props.inputTextSuffix) : null, this.props.invalidMessage ? React.createElement(tooltip, {
        'isOpen': this.props.tooltipIsOpen,
        'content': this.props.invalidMessage,
        'shouldTranslate': this.props.shouldTranslate,
        'key': 'validationErrorSymbol',
        'className': 'input-validation-icon'
    }, React.createElement('span', { 'className': 'input-validation-icon-inner' }, React.createElement(inputValidationErrorSymbol, {}))) : null, this.props.success ? React.createElement('span', {
        'className': 'input-validation-icon',
        'key': 'validationSuccessSymbol'
    }, React.createElement('span', { 'className': 'input-validation-icon-inner' }, React.createElement(inputValidationSuccessSymbol, {}))) : null);
};