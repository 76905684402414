'use strict';
var React = require('react');
var _ = require('lodash');
var tooltip = require('./tooltip');
var dropDown = require('./dropDown');
var footer = require('./dropDownStickyFooter');
var dropDownOption = require('./dropDownOption');
var sectionDivider = require('./sectionDivider');
function repeatFontItem1(fontItem, fontItemIndex) {
    return [
        fontItem.groupName ? React.createElement(sectionDivider, { 'key': fontItem.groupName }, '\n            ', this.props.translateIfNeeded(fontItem.groupName), '\n        ') : null,
        !fontItem.groupName ? React.createElement(dropDownOption, {
            'key': fontItem.label + '_' + fontItem.cssFontFamily,
            'value': fontItem.value,
            'label': fontItem.label,
            'wasVisibleStyle': { fontFamily: fontItem.cssFontFamily }
        }, React.createElement('div', { 'className': 'font-option-container' }, React.createElement('span', { 'className': 'font-label' }, fontItem.label), React.createElement('span', { 'className': 'font-example' }, this.props.translateIfNeeded(fontItem.example)))) : null
    ];
}
function repeatFooterLink2(footerLink, footerLinkIndex) {
    return React.createElement('div', {
        'key': footerLinkIndex,
        'className': 'font-family-footer-link ' + (footerLink.className ? footerLink.className : ''),
        'onClick': footerLink.onClick
    }, '\n                ', footerLink.symbol, '\n                ', React.createElement('span', {}, this.props.translateIfNeeded(footerLink.label)));
}
module.exports = function () {
    return React.createElement.apply(this, [
        dropDown,
        {
            'value': this.props.value,
            'className': this.props.getClassName('control-font-family-picker ' + (this.props.toolbarMode ? 'toolbar-mode' : '') + (this.isMissingFont() ? 'missing-font' : '')),
            'onChange': this.props.onChange,
            'onToggle': this.props.onToggle,
            'placeholder': this.getMissingFontName(),
            'searchBox': true,
            'groupedOptions': true,
            'optionsContainerClassName': 'control-font-family-picker-options ' + (this.props.optionsContainerClassName || ''),
            'shouldTranslate': false,
            'shouldPassIsVisibleToOptions': true,
            'automationId': this.props.automationId,
            'disabled': this.props.disabled
        },
        _.map(this.state.fontsItems, repeatFontItem1.bind(this)),
        this.props.footerLinks ? React.createElement('footer', {
            'key': 'font_family_footer',
            'className': 'control-dropdown-sticky-footer font-family-footer'
        }, React.createElement.apply(this, [
            'div',
            { 'className': 'footer-links-container fonts-upload' },
            _.map(this.props.footerLinks, repeatFooterLink2.bind(this))
        ])) : null
    ]);
};