'use strict';
var React = require('react');
var _ = require('lodash');
var TooltipOnEllipsis = require('./tooltipOnEllipsis');
module.exports = function () {
    return React.createElement('div', {
        'data-aid': this.props.automationId,
        'className': this.props.getClassName(this.getRootCssClasses()),
        'onClick': this.props.disabled ? _.noop : this.props.action
    }, this.props.children ? React.createElement('div', {
        'key': 'content',
        'className': 'content-container'
    }, React.createElement('div', { 'className': 'content' }, this.props.children)) : null, this.props.symbol ? React.createElement('div', {
        'key': 'symbol',
        'className': 'symbol-container'
    }, this.props.symbol, '\n    ') : null);
};