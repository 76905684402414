'use strict';
var React = require('react');
var _ = require('lodash');
var baseUILibButton = require('./button.js');
var trashCan = require('./icons/trashCan.svg.js');
var greaterThan = require('./icons/greaterThan-svg.js');
function onClick1() {
    this.updateMonth(-1);
}
function onClick2() {
    this.updateMonth(1);
}
function repeatDay3(dates, currentMonth, day, dayIndex) {
    return React.createElement('td', { 'key': day + dayIndex }, React.createElement('div', { 'className': 'dp-day-name' }, day));
}
function onClick4(dates, currentMonth, week, weekIndex, weekId, day, dayIndex) {
    this.props.onChange(dates[7 * week + day]);
}
function repeatDay5(dates, currentMonth, week, weekIndex, weekId, day, dayIndex) {
    return React.createElement('td', {
        'onClick': onClick4.bind(this, dates, currentMonth, week, weekIndex, weekId, day, dayIndex),
        'key': weekId + '_' + dayIndex
    }, React.createElement('div', { 'className': this.getElementClasses(dates[7 * week + day]) }, '\n                        ', dates[7 * week + day] ? dates[7 * week + day].getDate() : '', '\n                    '));
}
function scopeWeekId6(dates, currentMonth, week, weekIndex) {
    var weekId = dates[7 * week].getFullYear() + '_' + dates[7 * week].getMonth() + '_' + week;
    return React.createElement.apply(this, [
        'tr',
        {
            'className': 'dp-week',
            'key': weekId
        },
        _.map(_.range(7), repeatDay5.bind(this, dates, currentMonth, week, weekIndex, weekId))
    ]);
}
function repeatWeek7(dates, currentMonth, week, weekIndex, weekId) {
    return scopeWeekId6.apply(this, [
        dates,
        currentMonth,
        week,
        weekIndex
    ]);
}
function scopeDatesCurrentMonth8() {
    var dates = this.getDatesInMonth();
    var currentMonth = this.state.value.getMonth();
    return React.createElement('table', {}, React.createElement('thead', {}, React.createElement.apply(this, [
        'tr',
        {},
        _.map(this.getDays(), repeatDay3.bind(this, dates, currentMonth))
    ])), React.createElement.apply(this, [
        'tbody',
        {},
        _.map(_.range(dates.length / 7), repeatWeek7.bind(this, dates, currentMonth))
    ]));
}
function onClick9() {
    this.props.onChange(null);
}
function onClick10() {
    this.setState({ value: new Date() });
}
function onClick11() {
    this.props.value && this.setState({ value: this.props.value });
}
module.exports = function () {
    return React.createElement('div', {
        'className': this.props.getClassName('control-date-picker'),
        'data-aid': this.props.automationId
    }, React.createElement('div', { 'className': 'dp-header' }, React.createElement('div', {
        'className': 'dp-navigate dp-previous',
        'onClick': onClick1.bind(this)
    }, React.createElement(greaterThan, {})), React.createElement('div', { 'className': 'dp-title' }, this.getMonthTitle()), React.createElement('div', {
        'className': 'dp-navigate dp-next',
        'onClick': onClick2.bind(this)
    }, React.createElement(greaterThan, {}))), React.createElement('div', { 'className': 'dp-body' }, scopeDatesCurrentMonth8.apply(this, [])), React.createElement('div', { 'className': 'dp-footer' + (this.props.showDelete ? ' with-delete-btn' : '') }, this.props.showDelete ? React.createElement(baseUILibButton, {
        'className': 'delete-btn',
        'key': 'deleteButton',
        'onClick': onClick9.bind(this)
    }, React.createElement(trashCan, {})) : null, React.createElement(baseUILibButton, {
        'className': 'goto-today',
        'onClick': onClick10.bind(this)
    }, React.createElement('span', {}, this.props.todayLabel)), React.createElement(baseUILibButton, {
        'className': 'goto-selection',
        'disabled': !this.props.value,
        'onClick': onClick11.bind(this)
    }, React.createElement('span', {}, this.props.goToSelectedLabel))));
};