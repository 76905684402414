'use strict'
const mathUtil = require('../util/math')

const getValidNumericValue = (newValue, prevValue, min, max, shouldRoundByStep = true, step = 1, fractionDigits = 2) => {
    const returnValue = isNaN(newValue) ? prevValue : newValue

    const valueRoundedByStep = shouldRoundByStep ?
        mathUtil.roundByStep(returnValue, step) : returnValue
    const valueDecimalPointFixed = mathUtil.fixDecimalPoint(valueRoundedByStep, fractionDigits)
    const valueInRange = mathUtil.getValueInRange(valueDecimalPointFixed, min, max)

    return String(valueInRange)
}


module.exports = {
    getValidNumericValue
}
