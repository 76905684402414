'use strict';
var React = require('react');
var _ = require('lodash');
function onChange1(items, item, itemIndex, isThumbnail) {
    this.props.onChange(item.props.value);
}
function scopeIsThumbnail2(items, item, itemIndex) {
    var isThumbnail = this.isThumbnail(item);
    return React.createElement('div', {
        'key': item + itemIndex,
        'className': 'item-wrapper' + ' ' + _(isThumbnail ? this.getThumbnailWrapperClasses(item) : {}).transform(function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ')
    }, isThumbnail ? React.createElement('label', {
        'key': 'label',
        'className': 'label'
    }, React.createElement('input', {
        'type': 'radio',
        'className': 'input',
        'disabled': item.props.disabled,
        'name': this.groupId,
        'value': item.props.value,
        'readOnly': 'true',
        'checked': this.props.value === item.props.value,
        'onChange': onChange1.bind(this, items, item, itemIndex, isThumbnail)
    }), item) : null, isThumbnail ? null : item);
}
function repeatItem3(items, item, itemIndex, isThumbnail) {
    return scopeIsThumbnail2.apply(this, [
        items,
        item,
        itemIndex
    ]);
}
function scopeItems4() {
    var items = this.generateChildren();
    return React.createElement.apply(this, [
        'div',
        {
            'className': this.props.getClassName('control-thumbnails-list') + ' ' + _(this.getControlClasses()).transform(function (res, value, key) {
                if (value) {
                    res.push(key);
                }
            }, []).join(' '),
            'data-aid': this.props.automationId,
            'data-max-items-per-row': this.props.maxThumbsPerRow
        },
        _.map(items, repeatItem3.bind(this, items))
    ]);
}
module.exports = function () {
    return scopeItems4.apply(this, []);
};