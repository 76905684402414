'use strict';
var React = require('react');
var _ = require('lodash');
var baseUILibButton = require('./button.js');
var HelpIcon = require('../panels/headerHelp-svg.js');
module.exports = function (props, context) {
    return React.createElement(baseUILibButton, {
        'automationId': props.automationId,
        'className': 'btn-help btn-header help',
        'onClick': props.onClick
    }, React.createElement(HelpIcon, {}));
};