'use strict'

const PropTypes = require('prop-types')
const compose = require('../hoc/compose')
const displayNames = require('./displayNames')
const template = require('./dropDownDetailedOption.rt')

const DropDownDetailedOption = template

DropDownDetailedOption.displayName = displayNames.DROP_DOWN_DETAILED_OPTION
DropDownDetailedOption.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    wasVisible: PropTypes.bool,
    wasVisibleStyle: PropTypes.object,
    icon: PropTypes.any,
    description: PropTypes.string
}

module.exports = compose(DropDownDetailedOption)
