'use strict';
var React = require('react');
var _ = require('lodash');
var NumericInput = require('./numericInput');
var Toggle = require('./toggle');
var Corner = require('./corner');
var lockedSymbol = require('./icons/link_light.svg.js');
var unlockedSymbol = require('./icons/break_link_light.svg.js');
function onBlur1(e) {
    this.handleCornerChange('topLeft', e);
}
function onBlur2(e) {
    this.handleCornerChange('topRight', e);
}
function onBlur3(e) {
    this.handleCornerChange('bottomLeft', e);
}
function onBlur4(e) {
    this.handleCornerChange('bottomRight', e);
}
module.exports = function () {
    return React.createElement('div', {
        'data-aid': this.props.automationId,
        'className': this.getClassName()
    }, React.createElement('div', { 'className': 'top' }, React.createElement(Corner, {
        'onBlur': onBlur1.bind(this),
        'position': 'top-left',
        'min': this.props.min,
        'max': this.props.max,
        'value': this.getCornerValue('topLeft')
    }), React.createElement(Corner, {
        'onBlur': onBlur2.bind(this),
        'position': 'top-right',
        'min': this.props.min,
        'max': this.props.max,
        'value': this.getCornerValue('topRight')
    })), React.createElement(Toggle, {
        'className': 'link',
        'value': this.state.locked,
        'onChange': this.handleLinkClick
    }, this.state.locked ? React.createElement('span', {
        'key': 'lockedSymbol',
        'className': 'symbol lockedSymbol'
    }, React.createElement(lockedSymbol, {})) : null, !this.state.locked ? React.createElement('span', {
        'key': 'unlockedSymbol',
        'className': 'symbol unlockedSymbol'
    }, React.createElement(unlockedSymbol, {})) : null), React.createElement('div', { 'className': 'bottom' }, React.createElement(Corner, {
        'onBlur': onBlur3.bind(this),
        'position': 'bottom-left',
        'min': this.props.min,
        'max': this.props.max,
        'value': this.getCornerValue('bottomLeft')
    }), React.createElement(Corner, {
        'onBlur': onBlur4.bind(this),
        'position': 'bottom-right',
        'min': this.props.min,
        'max': this.props.max,
        'value': this.getCornerValue('bottomRight')
    })));
};