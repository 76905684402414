'use strict'

const getAngleFromOrigin = (o, p) => {
    const DEGREES_PER_RADIAN = 180 / Math.PI
    const dx = p.x - o.x
    const dy = p.y - o.y
    const theta = Math.atan2(dx, -dy)

    return Math.floor((theta * DEGREES_PER_RADIAN + 360) % 360)
}

const roundByStep = (n, step) => Math.round(n / step) * step

const fixDecimalPoint = (n, digits) => Number(parseFloat(n).toFixed(digits))

const getValueInRange = (value, min, max) => Math.min(Math.max(value, min), max)

module.exports = {
    getAngleFromOrigin,
    getValueInRange,
    roundByStep,
    fixDecimalPoint
}
