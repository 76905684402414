'use strict';
var React = require('react');
var _ = require('lodash');
var TextInput = require('./textInput');
var DropDown = require('./dropDown');
module.exports = function () {
    return React.createElement('div', {
        'className': this.props.getClassName(this.getRootCssClasses()),
        'data-aid': this.props.automationId
    }, React.createElement('div', { 'className': 'field-container' }, this.props.prefix ? React.createElement('span', {
        'key': 'inputPrefix',
        'className': 'input-prefix'
    }, this.props.translateIfNeeded(this.props.prefix)) : null, React.createElement('div', { 'className': 'text-container' }, React.createElement(TextInput, {
        'className': _({
            'dynamic-field-input': true,
            'hidden-text': this.state.showOverlay
        }).transform(function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' '),
        'value': this.state.valueCandidate,
        'validator': this.props.validator,
        'asyncValidator': this.props.asyncValidator,
        'invalidMessage': this.props.invalidMessage,
        'allowInvalidChange': true,
        'placeholder': this.props.placeholder,
        'shouldTranslate': this.props.shouldTranslate,
        'disabled': this.props.disabled,
        'onChange': this.onChange,
        'isValid': this.props.isValid,
        'onValidationStatus': this.onValidationStatus,
        'onBlur': this.onInputBlur,
        'onFocus': this.onInputFocus
    }), React.createElement('span', { 'className': 'overlay-container' }, this.getOverlay(this.state.valueCandidate)))), !this.props.disabled ? React.createElement(DropDown, {
        'className': 'dynamic-field-drop-down',
        'key': 'dynamicOptions',
        'onToggle': this.props.onToggle,
        'placeholder': this.props.addDynamicPartButtonLabel,
        'shouldTranslate': false,
        'optionsContainerClassName': 'dynamic-field-options',
        'options': this.props.options,
        'onChange': this.props.onFieldAdd,
        'hasArrowIcon': false,
        'openOnSelected': false
    }) : null);
};