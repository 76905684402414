'use strict'
const inputClassFactory = require('./inputClassFactory')
const compose = require('../hoc/compose')
const withValidation = require('../hoc/withValidation')
const withBlockOuterScroll = require('../hoc/withBlockOuterScroll')
const template = require('./textInputMultiline.rt')
const displayNames = require('./displayNames')

const inputComp = inputClassFactory(displayNames.TEXT_INPUT_MULTI_LINE, template, {
    blurOnEnterKey: false,
    shouldBlockOuterScroll: true
})
const HOC = [withValidation, withBlockOuterScroll]

module.exports = compose(inputComp, HOC)
