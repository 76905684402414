'use strict';
var React = require('react');
var _ = require('lodash');
module.exports = function (props, context) {
    return React.createElement('svg', {
        'xmlns': 'http://www.w3.org/2000/svg',
        'className': 'symbol-textForeColor',
        'xmlnsXlink': 'http://www.w3.org/1999/xlink',
        'width': '22px',
        'height': '18px'
    }, React.createElement('path', {
        'fill': props.fill ? props.fill : 'none',
        'fillRule': 'evenodd',
        'd': 'M 17 3C 17 3 27.03 17 17 17 6.9 17 17 3 17 3Z'
    }), React.createElement('path', {
        'fillRule': 'evenodd',
        'd': 'M 16.96 18C 13.35 18 12 14.95 12 13.03 12 9.97 15.69 4.09 16.12 3.43 16.12 3.43 16.95 2.12 16.95 2.12 16.95 2.12 17.8 3.42 17.8 3.42 18.23 4.08 22 9.97 22 13.03 22 15.09 20.44 18 16.96 18ZM 16.96 3.97C 16.96 3.97 13 10.18 13 13.03 13 14.52 14.02 16.99 16.96 16.99 19.89 16.99 21 14.52 21 13.03 21 10.18 16.96 3.97 16.96 3.97ZM 3.6 9.02C 3.6 9.02 2 13.03 2 13.03 2 13.03-0 13.03-0 13.03-0 13.03 5 1 5 1 5 1 6 0 6 0 6 0 7 0 7 0 7 0 8 1 8 1 8 1 11 7.01 11 7.01 11 7.01 11 11.02 11 11.02 11 11.02 9.4 9.02 9.4 9.02 9.4 9.02 3.6 9.02 3.6 9.02ZM 7 3.01C 7 3.01 6 3.01 6 3.01 6 3.01 4.4 7.01 4.4 7.01 4.4 7.01 8.6 7.01 8.6 7.01 8.6 7.01 7 3.01 7 3.01Z'
    }));
};