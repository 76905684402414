'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const _ = require('lodash')
const compose = require('../../hoc/compose')
const colorUtil = require('../../util/color')
const {getRectByReactElement} = require('../../util/rect')
const template = require('./colorSpace.rt')
const displayNames = require('../displayNames')

const hsbToStyle = hsb => ({
    left: `${hsb.saturation}%`,
    bottom: `calc(${hsb.brightness}% - 10px)`,
    borderColor: hsb.brightness > 60 ? '#000' : '#FFF'
})

const isCloseOrEqual = (a, b) => Math.abs(b - a) <= 5

class ColorSpace extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            hsb: colorUtil.hexToHsb(this.props.value)
        }

        this.getMarkerPosition = () => hsbToStyle(this.state.hsb)

        const ensureWithinLimits = n => Math.min(Math.max(0, n), 100)

        const getHsbColorByMouseEvent = e => {
            const x = e.clientX - this.gradientRect.x
            const y = e.clientY - this.gradientRect.y

            const saturation = ensureWithinLimits(100 * x / this.gradientRect.width)
            const brightness = ensureWithinLimits(100 - 100 * y / this.gradientRect.height)
            const {hue} = this.state.hsb

            return {brightness, saturation, hue}
        }

        this.setNewColorByMouseEvent = e => {
            const hsb = getHsbColorByMouseEvent(e)
            const newColor = colorUtil.hsbToHex(hsb)

            if (hsb.brightness < 5) {
                this.setState({hsb}, () => {
                    this.props.onChange(newColor)
                })
            } else {
                this.props.onChange(newColor)
            }

        }

        this.onMarkerDragStart = e => {
            window.addEventListener('mousemove', this.onMarkerDrag)
            window.addEventListener('mouseup', this.onMarkerDragEnd)
            this.gradientRect = getRectByReactElement(this.refs.gradient)
            this.setNewColorByMouseEvent(e)
        }

        this.onMarkerDrag = e => {
            this.setNewColorByMouseEvent(e)
        }

        this.onMarkerDragEnd = () => {
            window.removeEventListener('mousemove', this.onMarkerDrag)
            window.removeEventListener('mouseup', this.onMarkerDragEnd)
        }

        this.setNewColorByHue = hue => _({hue})
            .defaults(this.state.hsb)
            .thru(colorUtil.hsbToHex)
            .thru(hex => {
                this.setState({
                    hsb: _.defaults({hue}, this.state.hsb)
                }, () => {
                    this.props.onChange(hex)
                })
            })
            .value()


        this.getBrightnessOptions = () => _(5)
            .times(() => this.state.hsb)
            .map((color, i) => colorUtil.hsbToHex({
                hue: this.state.hsb.hue,
                saturation: color.saturation,
                brightness: i * 20 + 10
            }))
            .reverse()

        this.setNewColorByHex = hex => this.props.onChange(hex)
    }

    componentWillReceiveProps(nextProps) {
        const hsb = colorUtil.hexToHsb(nextProps.value)

        const shouldKeepHue = hsb.saturation < 5 ||
                              hsb.brightness < 5 ||
                              isCloseOrEqual(hsb.hue % 358, this.state.hsb.hue % 358)

        const shouldKeepSaturation = hsb.brightness < 5

        this.setState({
            hsb: _.defaults({
                hue: shouldKeepHue ? this.state.hsb.hue : hsb.hue,
                saturation: shouldKeepSaturation ? this.state.hsb.saturation : hsb.saturation
            }, hsb)
        })
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.onMarkerDrag)
        window.removeEventListener('mouseup', this.onMarkerDragEnd)
    }

    render() {
        return template.call(this)
    }
}

ColorSpace.displayName = displayNames.COLOR_SPACE
ColorSpace.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
}
ColorSpace.defaultProps = {}

module.exports = compose(ColorSpace)
