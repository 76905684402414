'use strict'
const PropTypes = require('prop-types')
const React = require('react')
const EDIT_MODES = require('./editModes')
const _ = require('lodash')
const template = require('./itemRow.rt')
const displayNames = require('../displayNames')

const deleteIcon = require('../icons/sbdl_delete.svg.js')
const duplicateIcon = require('../icons/sbdl_duplicate.svg.js')
const editIcon = require('../icons/sbdl_edit.svg.js')
const renameIcon = require('../icons/sbdl_rename.svg.js')
const setAsDefaultIcon = require('../icons/sbdl_setAsDefault.svg.js')
const compose = require('../../hoc/compose')
const ContextMenuAction = require('../contextMenuAction')
const Symbol = require('../symbol')

const SYMBOLS_MAP = {
    toggleDefault: setAsDefaultIcon,
    duplicate: duplicateIcon,
    toggleEditLabel: renameIcon,
    toggleEditValue: editIcon,
    delete: deleteIcon
}

const DEFAULT_LABEL_PLACEHOLDER = 'Add label here'
const DEFAULT_VALUE_PLACEHOLDER = 'Add value here'

class ItemRow extends React.Component {
    constructor(props) {
        super(props)

        this.shouldShowDragHandle = () => this.props.withDragHandle && !this.isEditMode()

        this.isEditMode = () => this.props.editMode !== EDIT_MODES.NONE

        this.editLabelMode = () => this.props.editMode === EDIT_MODES.EDIT_LABEL
        this.editValueMode = () => this.props.editMode === EDIT_MODES.EDIT_VALUE


        this.hasValue = () => _.has(this.props.value, 'value') && this.props.value.value !== undefined

        this.getRowClasses = () => {
            const classes = _({
                selectable: this.selectable(),
                selected: this.props.isSelected,
                disabled: this.props.disabled,
                'has-value': this.hasValue(),
                draggable: this.props.draggable,
                'edit-mode': this.editLabelMode() || this.editValueMode(),
                withDragHandle: this.shouldShowDragHandle(),
                bold: this.props.isDefault,
                dragging: this.props.isDragging,
                'custom-row': this.props.contentBefore || this.props.contentAfter
            }).pickBy().keys().value()

            return this.props.getClassName('sbdl-item-row') + ' ' + classes.join(' ')
        }

        this.getLabelClasses = () => ({
            label: true,
            placeholder: _.isEmpty(this.props.value.label)
        })

        this.getValueClasses = () => ({
            value: true,
            placeholder: this.shouldShowValuePlaceholder()
        })

        this.selectable = () => _.isFunction(this.props.select) && !this.props.isDragging && !this.props.isSelected

        this.select = () => {
            if (!this.props.disabled && this.selectable()) {
                this.props.select()
            }
        }

        this.closeEditLabelMode = () => this.props.menuActions.toggleEditLabel.action()
        this.closeEditValueMode = () => this.props.menuActions.toggleEditValue.action()
        this.getLabelMaxLength = () => this.props.menuActions.toggleEditLabel.labelMaxLength
        this.getValueMaxLength = () => this.props.menuActions.toggleEditValue.valueMaxLength


        this.getLabelPlaceHolder = () => _.isUndefined(this.props.labelPlaceholder) ?
            DEFAULT_LABEL_PLACEHOLDER :
            this.props.translateIfNeeded(this.props.labelPlaceholder)

        this.getLabelToDisplay = () => _.isEmpty(this.props.value.label) ?
            this.getLabelPlaceHolder() :
            this.props.value.label

        this.getValuePlaceHolder = () => _.isUndefined(this.props.valuePlaceholder) ?
            DEFAULT_VALUE_PLACEHOLDER :
            this.props.translateIfNeeded(this.props.valuePlaceholder)

        this.shouldShowValuePlaceholder = () => !_.has(this.props, 'valuePrefix') && _.isEmpty(this.props.value.value)

        this.getValueToDisplay = () => {
            const prefix = this.props.valuePrefix ? this.props.valuePrefix + ': ' : ''
            const valueToDisplay = this.shouldShowValuePlaceholder() ?
                this.getValuePlaceHolder() :
                this.props.value.value

            return prefix + valueToDisplay
        }

        this.updateLabelAndCloseEditMode = newLabel => {
            const newVal = _.defaults({label: newLabel}, this.props.value)
            this.props.onChange(newVal)
            this.closeEditLabelMode()
        }

        this.updateValueAndCloseEditMode = newValue => {
            const newVal = _.defaults({value: newValue}, this.props.value)
            this.props.onChange(newVal)
            this.closeEditValueMode()
        }

        this.onDoubleClick = () => {
            if (_.has(this.props.menuActions, 'toggleEditLabel')) {
                this.props.menuActions.toggleEditLabel.action()
            }
        }

        this.hasContextMenu = () => !_.isEmpty(this.props.menuActions) || !_.isEmpty(this.props.children)

        this.getDefaultContextMenuActions = () => {
            const isRemovable = () => _.isUndefined(this.props.value.removable) || this.props.value.removable
            const shouldShowAction = menuAction => menuAction.key !== 'delete' || isRemovable()

            return _(this.props.menuActions)
                .map((menuAction, menuActionKey) => ({
                    label: _.isFunction(menuAction.label) ? menuAction.label(this.props.value) : menuAction.label,
                    symbol: SYMBOLS_MAP[menuActionKey],
                    key: menuActionKey,
                    onClick: menuAction.action,
                    automationId: menuAction.automationId,
                    priority: menuAction.priority
                }))
                .filter(shouldShowAction)
                .map(actionProps =>
                    React.createElement(ContextMenuAction, actionProps,
                        [
                            React.createElement(Symbol, {key: 'symbol'}, React.createElement(actionProps.symbol)),
                            actionProps.label
                        ])
                )
                .value()
        }

        this.getAllContextMenuActions = () => {
            const childrenArray = _([]).concat(this.props.children).compact().value()
            const childrenWithPriority = _.reject(childrenArray, c => _.isUndefined(c.props.priority))
            const childrenWithoutPriority = _.filter(childrenArray, c => _.isUndefined(c.props.priority))

            return _(this.getDefaultContextMenuActions())
                .concat(childrenWithPriority)
                .sortBy('props.priority')
                .reverse()
                .concat(childrenWithoutPriority)
                .value()
        }

        this.getTextWidth = () => `calc(100% - ${!_.isEmpty(this.props.contentBefore) * 56 + !_.isEmpty(this.props.contentAfter) * 24}px)`

        this.onHover = () => {
            if (_.isFunction(this.props.onHover)) {
                this.props.onHover(this.props.value)
            }
        }

        this.isMultiline = () => this.props.className ? this.props.className.includes('multiline') : false
    }

    render() {
        return template.call(this)
    }
}

ItemRow.displayName = displayNames.SORT_BY_DRAG_LIST_ITEM_ROW
ItemRow.propTypes = {
    value: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    onHover: PropTypes.func,
    editMode: PropTypes.oneOf([EDIT_MODES.NONE, EDIT_MODES.EDIT_LABEL, EDIT_MODES.EDIT_VALUE]),
    select: PropTypes.func,
    disabled: PropTypes.bool,
    draggable: PropTypes.bool,
    withDragHandle: PropTypes.bool,
    dragHandleEnabled: PropTypes.bool,
    dragHandleTooltipContent: PropTypes.string,
    isDefault: PropTypes.bool,
    isSelected: PropTypes.bool,
    isDragging: PropTypes.bool,
    labelPlaceholder: PropTypes.string,
    valuePlaceholder: PropTypes.string,
    labelValidators: PropTypes.arrayOf(PropTypes.shape({
        validator: PropTypes.func.isRequired,
        invalidMessage: PropTypes.string.isRequired
    })),
    valueValidators: PropTypes.arrayOf(PropTypes.shape({
        validator: PropTypes.func.isRequired,
        invalidMessage: PropTypes.string.isRequired
    })),
    valuePrefix: PropTypes.string,
    menuActions: PropTypes.shape({
        toggleDefault: PropTypes.shape({
            label: PropTypes.func.isRequired,
            action: PropTypes.func.isRequired,
            priority: PropTypes.number.isRequired,
            automationId: PropTypes.string
        }),
        duplicate: PropTypes.shape({
            label: PropTypes.string.isRequired,
            action: PropTypes.func.isRequired,
            priority: PropTypes.number.isRequired,
            automationId: PropTypes.string
        }),
        toggleEditLabel: PropTypes.shape({
            label: PropTypes.string.isRequired,
            action: PropTypes.func.isRequired,
            priority: PropTypes.number.isRequired,
            labelMaxLength: PropTypes.number,
            automationId: PropTypes.string
        }),
        toggleEditValue: PropTypes.shape({
            label: PropTypes.string.isRequired,
            action: PropTypes.func.isRequired,
            priority: PropTypes.number.isRequired,
            valueMaxLength: PropTypes.number,
            automationId: PropTypes.string
        }),
        delete: PropTypes.shape({
            label: PropTypes.string.isRequired,
            action: PropTypes.func.isRequired,
            priority: PropTypes.number.isRequired,
            automationId: PropTypes.string
        })
    }),
    contentBefore: PropTypes.element,
    contentAfter: PropTypes.element,
    isContextMenuOpen: PropTypes.bool,
    onContextMenuToggle: PropTypes.func,
    contextMenuAlignment: PropTypes.string,
    contextMenuDirection: PropTypes.string
}

ItemRow.defaultProps = {
    editMode: EDIT_MODES.NONE,
    disabled: false,
    draggable: true,
    withDragHandle: true,
    dragHandleEnabled: true,
    isDefault: false,
    isSelected: false,
    isDragging: false,
    menuActions: {}
}

module.exports = compose(ItemRow)
