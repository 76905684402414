'use strict';
var React = require('react');
var _ = require('lodash');
module.exports = function () {
    return React.createElement('div', { 'className': this.props.getClassName('color-picker-reset-color') }, React.createElement('div', {
        'className': 'color-picker-reset-color-option color-picker-reset-color-button',
        'onClick': this.resetColor,
        'style': { backgroundColor: this.initialColor }
    }), React.createElement('div', {
        'className': 'color-picker-reset-color-option',
        'style': { backgroundColor: this.props.value }
    }));
};