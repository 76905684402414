'use strict';
var React = require('react');
var _ = require('lodash');
var RadioButton = require('./radioButton');
function onChange1(generatedChildren, item, itemIndex, isRadioButtonItem, value) {
    this.props.onChange(value);
}
function scopeValue2(generatedChildren, item, itemIndex, isRadioButtonItem) {
    var value = item.props.value;
    return React.createElement(RadioButton, {
        'key': 'radioButton',
        'automationId': item.props.automationId,
        'value': value,
        'name': this.groupId,
        'className': item.props.className ? item.props.className : null,
        'onChange': onChange1.bind(this, generatedChildren, item, itemIndex, isRadioButtonItem, value),
        'selected': this.props.value === value,
        'disabled': this.props.disabled || item.props.disabled,
        'showRadioSymbol': this.props.showRadioSymbol
    }, item);
}
function scopeIsRadioButtonItem3(generatedChildren, item, itemIndex) {
    var isRadioButtonItem = this.props.radioItemPredicate(item);
    return React.createElement('li', {
        'key': 'radio' + itemIndex,
        'className': _({
            'radio-button-item': isRadioButtonItem,
            'selected': item.props && this.props.value === item.props.value
        }).transform(function (res, value, key) {
            if (value) {
                res.push(key);
            }
        }, []).join(' ')
    }, isRadioButtonItem ? scopeValue2.apply(this, [
        generatedChildren,
        item,
        itemIndex,
        isRadioButtonItem
    ]) : null, !isRadioButtonItem ? item : null);
}
function repeatItem4(generatedChildren, item, itemIndex, isRadioButtonItem) {
    return scopeIsRadioButtonItem3.apply(this, [
        generatedChildren,
        item,
        itemIndex
    ]);
}
function scopeGeneratedChildren5() {
    var generatedChildren = this.generateChildren();
    return React.createElement.apply(this, [
        'ul',
        {
            'className': this.props.getClassName('control-radio-buttons-list ' + this.props.orientation),
            'data-aid': this.props.automationId,
            'data-max-items-per-row': this.props.maxItemsPerRow
        },
        _.map(generatedChildren, repeatItem4.bind(this, generatedChildren))
    ]);
}
module.exports = function () {
    return scopeGeneratedChildren5.apply(this, []);
};