'use strict'
const React = require('react')
const PropTypes = require('prop-types')

const INPUT_PROP_TYPES = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    defaultText: PropTypes.string,
    maxLength: PropTypes.number,
    prefix: PropTypes.string,
    blurOnEnterKey: PropTypes.bool,
    blurOnEscKey: PropTypes.bool,
    dir: PropTypes.string,
    defaultInputClass: PropTypes.string,
    innerInputClass: PropTypes.string,
    initialSelectionStart: PropTypes.number,
    initialSelectionEnd: PropTypes.number,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    shouldBlockOuterScroll: PropTypes.bool,
    focus: PropTypes.bool,
    onClick: PropTypes.func,
    focusOnPrefixClick: PropTypes.bool,
    showInfoTooltip: PropTypes.bool,
    infoTooltipProps: PropTypes.object,
    isReadOnly: PropTypes.bool,
    autoSelect: PropTypes.bool,
    selectOnFocus: PropTypes.bool,
    revertValueOnCancel: PropTypes.bool,
    successValue: PropTypes.string,
    preventBlur: PropTypes.bool,
    inputTextSuffix: PropTypes.string,
    selectionActionCounter: PropTypes.number
}

const validatorType = PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.func),
    PropTypes.func
])

const VALIDATED_PROP_TYPES = {
    isValid: PropTypes.bool,
    validateInitialValue: PropTypes.bool,
    tooltipOnInitialValidation: PropTypes.bool,
    validateOnValuePropChange: PropTypes.bool,
    allowInvalidChange: PropTypes.bool,
    onValidationStatus: PropTypes.func,
    validator: validatorType,
    asyncValidator: validatorType,
    invalidMessage: PropTypes.string,
    syncDelay: PropTypes.number,
    asyncDelay: PropTypes.number,
    cancelValidationOnUnmount: PropTypes.bool
}

// eslint-disable-next-line
const inputElement = () => React.createElement('div')
inputElement.propTypes = INPUT_PROP_TYPES
// eslint-disable-next-line
const validatedElement = () => React.createElement('div')
validatedElement.propTypes = VALIDATED_PROP_TYPES

module.exports = {
    INPUT_PROP_TYPES,
    VALIDATED_PROP_TYPES
}
