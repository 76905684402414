'use strict';
var React = require('react');
var _ = require('lodash');
var changeIcon = require('./change.svg.js');
function onClick1(color, colorIndex) {
    {
        this.onChange(color, colorIndex);
    }
}
function onMouseEnter2(color, colorIndex) {
    {
        this.props.onColorEnter(color, colorIndex);
    }
}
function onMouseLeave3(color, colorIndex) {
    {
        this.props.onColorLeave(color, colorIndex);
    }
}
function repeatColor4(color, colorIndex) {
    return React.createElement('span', {
        'onClick': onClick1.bind(this, color, colorIndex),
        'onMouseEnter': onMouseEnter2.bind(this, color, colorIndex),
        'onMouseLeave': onMouseLeave3.bind(this, color, colorIndex),
        'className': 'palette-single-color-container ' + (this.props.value && this.state.colorIndex === colorIndex ? 'selected-color' : ''),
        'key': colorIndex
    }, React.createElement('span', {
        'className': 'palette-single-color ' + (this.getDistanceToWhite(color) < 3 ? 'light' : ''),
        'style': { backgroundColor: color }
    }));
}
function onClick5(mainColor, mainColorIndex) {
    {
        this.props.onMainColorChange(mainColor, mainColorIndex);
    }
}
function onMouseEnter6(mainColor, mainColorIndex) {
    {
        this.props.onColorEnter(mainColor, mainColorIndex);
    }
}
function onMouseLeave7(mainColor, mainColorIndex) {
    {
        this.props.onColorLeave(mainColor, mainColorIndex);
    }
}
function repeatMainColor8(mainColor, mainColorIndex) {
    return React.createElement('span', {
        'className': 'palette-main-color-container',
        'key': mainColorIndex
    }, React.createElement('span', {
        'className': 'palette-main-color',
        'onClick': onClick5.bind(this, mainColor, mainColorIndex),
        'onMouseEnter': onMouseEnter6.bind(this, mainColor, mainColorIndex),
        'onMouseLeave': onMouseLeave7.bind(this, mainColor, mainColorIndex),
        'style': { backgroundColor: mainColor }
    }, React.createElement('span', {
        'className': 'change-main-color-arrow',
        'style': { borderBottomColor: mainColor }
    }), React.createElement('span', { 'className': 'change-main-color-icon' }, React.createElement(changeIcon, {}))));
}
module.exports = function () {
    return React.createElement.apply(this, [
        'div',
        { 'className': this.props.getClassName('color-picker-palette') },
        _.map(this.getColorsByPalette(this.props.palette), repeatColor4.bind(this)),
        _.map(this.props.mainColors, repeatMainColor8.bind(this))
    ]);
};