'use strict';
var React = require('react');
var _ = require('lodash');
var RadioButtons = require('../radioButtons');
module.exports = function () {
    return React.createElement('div', {
        'data-aid': this.props.automationId,
        'className': this.props.getClassName('control-dock' + (this.props.disabled ? ' disabled' : ''))
    }, React.createElement(RadioButtons, {
        'value': this.props.value,
        'showRadioSymbol': false,
        'orientation': 'horizontal',
        'onChange': this.props.onChange
    }, this.generateDockDirections()), React.createElement('div', { 'className': 'grid horizontal' }), React.createElement('div', { 'className': 'grid vertical' }));
};