'use strict';
var React = require('react');
var _ = require('lodash');
var RadioButtons = require('./radioButtons');
module.exports = function () {
    return React.createElement('div', { 'className': this.props.getClassName(this.getRootCssClasses()) }, React.createElement(RadioButtons, {
        'value': this.props.value,
        'orientation': 'horizontal',
        'showRadioSymbol': false,
        'onChange': this.props.onChange,
        'options': this.props.options,
        'shouldTranslate': this.props.shouldTranslate
    }));
};