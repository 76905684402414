'use strict';
var React = require('react');
var _ = require('lodash');
var TextLabelBase = require('./textLabelBase');
var TooltipOnEllipsis = require('./tooltipOnEllipsis');
module.exports = function () {
    return React.createElement('span', {
        'className': this.props.getClassName('control-label' + (this.props.disabled ? ' disabled' : '') + (this.props.enableEllipsis ? ' with-ellipsis' : '')),
        'data-aid': this.props.automationId
    }, this.props.enableEllipsis ? React.createElement(TooltipOnEllipsis, _.assign({}, { 'key': 'tooltipOnEllipsisOnTextLabel' }, this.getTooltipOnEllipsisProps()), React.createElement(TextLabelBase, this.getTextLabelBaseProps())) : null, !this.props.enableEllipsis ? React.createElement(TextLabelBase, _.assign({}, { 'key': 'tooltipOnEllipsisOnTextLabel' }, this.getTextLabelBaseProps())) : null);
};